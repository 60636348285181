<template>
  <img
    src="@/assets/questionnaire/edit_icon.svg"
    alt="edit"
    class="editIcon cursor-pointer"
    @click="this.showEditDialog = true"
  />
  <el-dialog
    v-model="showEditDialog"
    center
    title="Edit Hormones / Medications"
    modal-class="hormoneDialog"
  >
    <div class="flex flex-col">
      <div class="medicationFormLine">
        <div class="medicationLabel">Hormone Type<span>*</span></div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.hormoneType"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Brand</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.brand"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Delivery</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.delivery"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Dosage</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.dosage"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Last Used</div>
        <el-input
          class="medicationInput"
          placeholder="YYYY-MM-DD"
          v-model="this.medicationRecords.lastUsed"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Times Per Day</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.timesPerDay"
        >
          <template #suffix>
            <span>Times</span>
          </template>
        </el-input>
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">How Long Used</div>
        <div class="howLongUsedContainer">
          <el-input
            class="howLongUsedInput pl-[10px]"
            v-model="this.medicationRecords.usedYears"
          >
            <template #suffix>
              <span>Years</span>
            </template>
          </el-input>
          <el-input
            class="howLongUsedInput"
            v-model="this.medicationRecords.usedMonths"
          >
            <template #suffix>
              <span>Months</span>
            </template>
          </el-input>
          <el-input
            class="howLongUsedInput"
            v-model="this.medicationRecords.usedDays"
          >
            <template #suffix>
              <span>Days</span>
            </template>
          </el-input>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="cancelBtn" @click="this.closeHormoneDialog"
          >Cancel</el-button
        >
        <el-button
          class="submitBtn"
          @click="this.confirmUpdateMedicationRecords"
          :disabled="!this.medicationRecords.hormoneType"
        >
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddHormoneDialog',
  props: {
    editIndex: Number,
    hormoneRecord: Object,
  },
  data() {
    return {
      showEditDialog: false,
      medicationRecords: {
        hormoneType: '',
        brand: '',
        delivery: '',
        dosage: '',
        lastUsed: '',
        timesPerDay: '',
        usedYears: '',
        usedMonths: '',
        usedDays: '',
      },
    };
  },
  created() {
    this.medicationRecords.hormoneType = this.hormoneRecord.hormone_type;
    this.medicationRecords.brand = this.hormoneRecord.brand;
    this.medicationRecords.delivery = this.hormoneRecord.delivery;
    this.medicationRecords.dosage = this.hormoneRecord.dosage;
    this.medicationRecords.lastUsed = this.hormoneRecord.last_used;
    this.medicationRecords.timesPerDay = this.hormoneRecord.times_per_day;
    this.parseTimeString(this.hormoneRecord.how_long_used);
    if (this.hormoneRecord.homrone_medical_idx) {
      this.medicationRecords.homrone_medical_idx =
        this.hormoneRecord.homrone_medical_idx;
    }
  },
  emits: ['editMedicationHistory'],
  watch: {
    hormoneRecord: {
      immediate: true,
      handler(newVal) {
        this.medicationRecords.hormoneType = newVal.hormone_type;
        this.medicationRecords.brand = newVal.brand;
        this.medicationRecords.delivery = newVal.delivery;
        this.medicationRecords.dosage = newVal.dosage;
        this.medicationRecords.lastUsed = newVal.last_used;
        this.medicationRecords.timesPerDay = newVal.times_per_day;
        this.parseTimeString(newVal.how_long_used);
      },
    },
    'medicationRecords.lastUsed': function (newVal, oldVal) {
      if (newVal.length === 0) return;
      if (newVal.length > 10) {
        this.medicationRecords.lastUsed = oldVal;
        return;
      }
      let curInput = newVal[newVal.length - 1];
      const regex = /[0-9]/;
      // delete user current input if it is not a number (need deal with '/')
      if (!curInput.match(regex)) {
        if (curInput === '-') {
          if (newVal.length !== 5 && newVal.length !== 8) {
            this.medicationRecords.lastUsed =
              this.medicationRecords.lastUsed.slice(0, -1);
          }
        } else {
          this.medicationRecords.lastUsed =
            this.medicationRecords.lastUsed.slice(0, -1);
        }
      } else {
        // autofill the "/" between month&year
        if (oldVal.length === 3 && newVal.length === 4)
          this.medicationRecords.lastUsed += '-';
        if (oldVal.length === 6 && newVal.length === 7)
          this.medicationRecords.lastUsed += '-';
      }
    },
  },
  methods: {
    confirmUpdateMedicationRecords() {
      this.$emit(
        'editMedicationHistory',
        this.medicationRecords,
        this.editIndex,
      );
      this.closeHormoneDialog();
    },
    parseTimeString(inputStr) {
      if (inputStr == '' || !inputStr) return;
      let usedYears = '';
      let usedMonths = '';
      let usedDays = '';
      let yearMatch = inputStr.match(/(\d+)\s*Years?/);
      let monthMatch = inputStr.match(/(\d+)\s*Months?/);
      let dayMatch = inputStr.match(/(\d+)\s*Days?/);
      if (yearMatch) {
        usedYears = parseInt(yearMatch[1]);
      }
      if (monthMatch) {
        usedMonths = parseInt(monthMatch[1]);
      }
      if (dayMatch) {
        usedDays = parseInt(dayMatch[1]);
      }
      this.medicationRecords.usedYears = usedYears;
      this.medicationRecords.usedMonths = usedMonths;
      this.medicationRecords.usedDays = usedDays;
    },
    closeHormoneDialog() {
      this.showEditDialog = false;
    },
  },
};
</script>

<style scoped>
.editIcon {
  display: inline;
  filter: invert(69%) sepia(20%) saturate(17%) hue-rotate(354deg)
    brightness(87%) contrast(75%);
}
.editIcon:hover {
  filter: invert(52%) sepia(85%) saturate(438%) hue-rotate(142deg)
    brightness(90%) contrast(87%);
}

.medicationFormLine {
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: space-between;
  width: 600px;
}

.medicationLabel {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.17px;
  text-align: left;
}

.medicationLabel span {
  color: #ff1d00;
  margin-left: 4px;
}

.medicationInput {
  width: 400px;
  height: 42px;
  margin-left: 20px;
}

:deep(.el-dialog__title) {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.175px;
  text-align: center;
  color: #0e4672;
}

:deep(.el-input__suffix) {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #0e4672 !important;
  margin-right: 20px;
  display: flex;
  align-items: center;
  filter: none !important;
}

.howLongUsedContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  width: 400px;
  height: 42px;
  margin-left: 20px;
}


.howLongUsedInput {
  height: 30px;
}

:deep(.medicationInput .el-input__inner) {
  color: #000000 !important;
  caret-color: #000000;
  margin-left: 0px;
  padding-left: 20px;
  height: 42px;
}

:deep(.howLongUsedInput .el-input__inner) {
  border: none !important;
}

.el-button.submitBtn.is-disabled {
  background: #f3f3f3 !important;
  border: 1px solid #f3f3f3 !important;
  color: #bbbbbb !important;
}

.el-button.submitBtn {
  background: #5cbec6 !important;
  border: 1px solid #5cbec6 !important;
  color: #ffffff !important;
}

.el-button.submitBtn:hover {
  background: #87dfe6 !important;
  border: 1px solid #87dfe6 !important;
}

.el-button.cancelBtn {
  color: #5cbec6 !important;
  border: 2px solid #5cbec6 !important;
  background: #ffffff !important;
}

.el-button.cancelBtn:hover {
  color: #87dfe6 !important;
  border: 2px solid #5cbec6 !important;
  outline: 1px solid #ffffff;
  outline-offset: -2px;
}

@media only screen and (max-width: 1024px) {
  .addBtn {
    font-size: 14px;
  }
}

@media only screen and (max-width: 808px) {
  .medicationFormLine {
    flex-direction: column;
    width: 90vw;
  }

  .medicationInput {
    width: 80vw;
    margin-left: 0px;
  }

  :deep(.el-input__suffix) {
    font-size: 12px;
  }

  .howLongUsedContainer {
    width: 80vw;
    margin-left: 0px;
  }

  .medicationLabel {
    font-size: 12px !important;
    width: 90% !important;
  }

  .el-input {
    font-size: 12px;
  }

  .cancelBtn,
  .submitBtn {
    width: 30vw !important;
    font-size: 12px !important;
    max-width: 166px;
  }
}
</style>
