<template>
  <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Card Information</div>
    <StripeCardInfo :triggerLoading="triggerBtnLoading" :isHSACard="this.isHSACard"/>
  </div>
  <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Billing Information</div>
    <BillingInfo :showAllBillingInfo="false" />
  </div>
  <!-- <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Contact Information</div>
    <ContactInfo />
  </div> -->
  <div class="subpartContainer">
    <el-button
      class="submitButton"
      id="submitStripePaymentButton"
      v-loading="loadingPayment"
    >
      Complete Payment
      {{ formatPrice(amountToPay) }}</el-button
    >
  </div>
  <div class="consentMessageContainer">
    <div class="consentMessage">
      By clicking Complete Payment above, you agree for your card provided here
      to be charged the complete amount listed to purchase testing according to
      <a
        href="https://www.vibrant-america.com/website-terms-of-use/"
        target="_blank"
        >Terms of Service</a
      >
      and
      <a
        href="https://www.vibrant-america.com/testing-policies/"
        target="_blank"
        >Testing Policies</a
      >
      from Vibrant America and
      <a href="https://www.vibrant-wellness.com/TermsOfUse" target="_blank"
        >Terms of Service</a
      >
      from Vibrant Wellness.
    </div>
  </div>
</template>

<script>
import BillingInfo from "@/components/payment/BillingInfo.vue";
// import ContactInfo from "@/components/payment/ContactInfo.vue";
import StripeCardInfo from "@/components/payment/StripeCardInfo.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState, mapActions } from "pinia";
export default {
  name: "HsaCardPayContainer",
  props: {
    isHSACard: {
      type: Boolean,
    },
  },
  components: {
    BillingInfo,
    StripeCardInfo,
  },
  data() {
    return {
      paymentMethod: "",
      loadingPayment: false,
      // amountToPay: null,
    };
  },
  created() {
    this.setCheckEmptyFields(false);
    // this.setAmountToPay();
  },
  computed: {
    ...mapState(usePaymentStore, [
      "billingInfo",
      "has_concierge_service",
      "backend_has_concierge_service",
    ]),
    formatPrice() {
      return (price) => {
        return Number(price).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      };
    },
    amountToPay(){
      return localStorage.getItem("amount_to_pay")
        ? parseFloat(
            (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
          )
        : 
        (
          this.billingInfo
          ? 
          (
            this.need_to_manually_add_concierge_service_fee
            ?
            Number(this.billingInfo.total) + 99
            :
            (
              this.need_to_manually_deduct_concierge_service_fee
              ?
              Number(this.billingInfo.total) - 99
              :
              Number(this.billingInfo.total)
            )
          )
          : null
        );
    },
    need_to_manually_add_concierge_service_fee(){
      // console.log('need_to_manually_add_concierge_service_fee', this.has_concierge_service,this.backend_has_concierge_service);
      return this.has_concierge_service && !this.backend_has_concierge_service;
    },
    need_to_manually_deduct_concierge_service_fee(){
      // console.log('need_to_manually_deduct_concierge_service_fee', this.has_concierge_service,this.backend_has_concierge_service);
      return !this.has_concierge_service && this.backend_has_concierge_service;
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "payByCard",
      "setCheckEmptyFields",
      "saveBillingInfo",
    ]),
    // setAmountToPay() {
    //   this.amountToPay = localStorage.getItem("amount_to_pay")
    //     ? parseFloat(
    //         (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
    //       )
    //     : this.billingInfo
    //     ? Number(this.billingInfo.total)
    //     : null;
    // },
    triggerBtnLoading(val) {
      this.loadingPayment = val;
    },
  },
};
</script>

<style scoped>
.consentMessageContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

a {
  color: #20a8c3;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.consentMessage {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.45px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .consentMessage {
    font-size: 12px;
  }
}
</style>
