<template>
  <div class="paymentInputContainer">
    <div class="inputTitle">
      {{ content }}
      <span v-if="isRequired" class="required">*</span>
    </div>
    <el-select
      :class="isEmpty ? 'redBorder' : ''"
      v-model="value"
      @change="onChange"
      placeholder=" "
      popper-class="paymentSelect"
    >
      <el-option
        v-for="item in options"
        :key="item.abbreviation"
        :label="item.name"
        :value="item.abbreviation"
      />
    </el-select>
    <div v-if="isEmpty" class="warningMessage">
      {{ warningMessage }}
    </div>
  </div>
</template>
<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapActions, mapState } from "pinia";

export default {
  name: "PaymentSelect",
  props: {
    content: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: "",
      inputWidth: this.width === "half" ? "27vw" : "56vw",
      minInputWidth: this.width === "half" ? "284px" : "600px",
      isEmpty: false,
    };
  },
  computed: {
    ...mapState(usePaymentStore, ["checkEmptyFields"]),
    warningMessage() {
      return "Please enter your " + this.content.toLowerCase() + " here";
    },
  },
  watch: {
    checkEmptyFields(newVal) {
      if (newVal) this.checkEmpty();
    },
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "setCheckEmptyFields",
      "updateHasEmptyFields",
    ]),
    onChange(value) {
      this.isEmpty = false;
      this.$emit("onChange", value);
      this.setCheckEmptyFields(false);
    },
    checkEmpty() {
      if (this.isRequired && this.value.length === 0) {
        this.isEmpty = true;
        this.updateHasEmptyFields(true);
      }
    },
  },
};
</script>

<style scoped>
.paymentInputContainer {
  width: v-bind(inputWidth);
  height: 84px;
}
:deep(.el-input__inner) {
  color: #000000 !important;
}
:deep(.el-input) {
  height: 42px;
  width: 27vw;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
}

:deep(.el-input__wrapper) {
  box-shadow: none !important;
  border: 1px solid #bcccdc;
  padding-left: 21px;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
  box-shadow: none !important;
}

:deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: none !important;
}

.required {
  color: #ff1d00;
}

.warningMessage {
  color: #de583f;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.175px;
  text-align: left;
}

:deep(.redBorder .el-input__wrapper) {
  border: 1px solid #df2b25 !important;
}

@media only screen and (max-width: 767px) {
  .paymentInputContainer {
    width: 100%;
    height: 68px;
  }

  .el-select {
    width: 100%;
  }

  :deep(.el-input) {
    width: 100%;
    font-size: 14px;
    height: 36px;
  }

  .inputTitle {
    font-size: 12px;
  }

  .warningMessage {
    font-size: 12px;
  }

  :deep(.el-input__wrapper) {
    padding-left: 15px;
  }
}
</style>
