<template>
  <div class="paymentInputContainer">
    <div class="inputTitle">
      {{ content }}
      <span v-if="isRequired" class="required">*</span>
    </div>
    <el-input
      :class="isEmpty || invalidDate || invalidName ? 'redBorder' : ''"
      v-model="value"
      @change="onChange(value)"
      :placeholder="content=='Expiration' ? 'MM / YY' : ''"
    />
    <div v-if="isEmpty || invalidDate || invalidName" class="warningMessage">
      {{ warningMessage }}
    </div>
  </div>
</template>
<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapActions, mapState } from "pinia";
export default {
  name: "PaymentInput",
  props: {
    content: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: (this.content == "Email" && this.billing_email) ? this.billing_email : "",
      inputWidth: this.width === "half" ? "27vw" : "56vw",
      isEmpty: false,
      invalidDate: false,
      invalidName: false,
    };
  },
  computed: {
    ...mapState(usePaymentStore, ["checkEmptyFields", "billing_email"]),
    warningMessage() {
      if (this.isEmpty) {
        return "Please enter your " + this.content.toLowerCase() + " here";
      } else if (this.invalidDate) {
        return "Invalid expiration date";
      } else if (this.invalidName) {
        return "Invalid name, last name can't be blank";
      } else {
        return "";
      }
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      if (this.content == "Expiration") {
        if (newVal.length === 0) return;
        let curInput = newVal[newVal.length - 1];
        const regex = /[0-9]/;
        // delete user current input if it is not a number (need deal with '/')
        if (!curInput.match(regex)) {
          if (curInput === "/") {
            if (newVal.length !== 3) this.value = this.value.slice(0, -1);
          } else this.value = this.value.slice(0, -1);
        } else {
          // autofill the "/" between month&year
          if (oldVal.length === 1 && newVal.length === 2) this.value += "/";
        }
      }
    },
    checkEmptyFields(newVal) {
      if (newVal) this.checkEmpty();
    },
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "setCheckEmptyFields",
      "updateHasEmptyFields",
    ]),
    onChange(value) {
      this.isEmpty = false;
      this.invalidDate = false;
      this.invalidName = false;
      this.$emit("onChange", value);
      this.setCheckEmptyFields(false);
    },
    checkEmpty() {
      if (this.isRequired && this.value.length === 0) {
        this.isEmpty = true;
        this.updateHasEmptyFields(true);
        return;
      } else if (this.isRequired && this.value.length !== 0) {
        this.isEmpty = false;
        this.updateHasEmptyFields(false);
      }
      if (this.content == "Expiration") {
        if (this.checkValidDate(this.value)) {
          this.invalidDate = false;
          this.updateHasEmptyFields(false);
        } else {
          this.invalidDate = true;
          this.updateHasEmptyFields(true);
        }
      }
      if (this.content == "Name on card") {
        let [firstName, lastName] = this.value.split(" ");
        console.log(firstName, lastName);
        if (!lastName) {
          this.invalidName = true;
          this.updateHasEmptyFields(true);
        } else {
          this.invalidName = false;
          this.updateHasEmptyFields(false);
        }
      }
    },
    checkValidDate(date) {
      let regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
      if (regex.test(date)) {
        let parts = date.split("/");
        let month = parseInt(parts[0], 10);
        let year = parseInt(parts[1], 10);

        let currentDate = new Date();
        let currentYear = currentDate.getFullYear() % 100;
        let currentMonth = currentDate.getMonth() + 1;

        if (
          (year > currentYear && year <= currentYear + 10) ||
          (year === currentYear && month >= currentMonth)
        ) {
          if (month >= 1 && month <= 12) {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.paymentInputContainer {
  width: v-bind(inputWidth);
  height: 84px;
}
:deep(.el-input__inner) {
  color: #000000 !important;
}
.el-input {
  height: 42px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
}
:deep(.el-input__wrapper) {
  box-shadow: none;
  border: 1px solid #bcccdc;
  padding-left: 21px;
}
:deep(.el-input__inner::placeholder) {
  color:  #bcccdc;
}

.required {
  color: #ff1d00;
}

.warningMessage {
  color: #de583f;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.175px;
  text-align: left;
}

:deep(.redBorder .el-input__wrapper) {
  border: 1px solid #df2b25 !important;
}

@media only screen and (max-width: 767px) {
  .paymentInputContainer {
    width: 100%;
    height: 68px;
  }

  .el-input {
    font-size: 14px;
    height: 36px;
  }

  .inputTitle {
    font-size: 12px;
  }

  .warningMessage {
    font-size: 12px;
  }

  :deep(.el-input__wrapper) {
    padding-left: 15px;
  }
}
</style>
