<template>
  <div id="page-container"></div>
</template>

<script>
import { useCommonStore } from "@/stores/CommonStore.ts";
import { mapActions } from "pinia";
export default {
  name: "NYFormPage",
  props: {
    sample_id: String,
    accession_id: String,
    jwtToken: String,
  },
  created() {
    this.setSampleId(this.sample_id);
    this.setAccessionId(this.accession_id);
    this.setJwtToken(this.jwtToken);
    this.$router.push("/ny_form");
    // this.getQuestionnaireData().then((res) => {
    //   if (res.data.hormone_questionaire && res.data.hormone_questionaire.completed_at) {
    //     this.$router.push("/questionnaire_completed");
    //   } else {
    //     this.$router.push("/questionnaire_form");
    //   }
    // }).catch((err) => {
    //   console.log(err);
    // });
  },
  methods: {
    ...mapActions(useCommonStore, ["setSampleId", "setAccessionId", "setJwtToken"]),
  },
};
</script>
