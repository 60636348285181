const relationshipOptions = [
    {
        value: 'Mother',
        label: 'Mother'
    },
    {
        value: 'Father',
        label: 'Father'
    },
    {
        value: 'Spouse',
        label: 'Spouse'
    },
    {   
        value: 'Child',
        label: 'Child'
    },
    {
        value: 'Sibiling',
        label: 'Sibiling'
    },
    {
        value: 'GrandParent',
        label: 'Grandparent'
    },
    {
        value: 'Aunt',
        label: 'Aunt'
    },
    {
        value: 'Uncle',
        label: 'Uncle'
    },
    {
        value: 'Stepparent',
        label: 'Stepparent'
    },
    {
        value: 'Other',
        label: 'Other'
    }
  ]
  
  export default relationshipOptions;