<template>
  <div v-loading="loadingPaymentStatus">
    <!-- <div v-if="paymentStatus == 3">
      <PaymentSucceed />
    </div>
    <div v-if="paymentStatus == 2">
      <PaymentFailed />
    </div>
    <div v-if="paymentStatus == 1">
      <PaymentPending />
    </div> -->
  </div>
</template>

<script>
// import PaymentSucceed from "@/components/payment/PaymentSucceed.vue";
// import PaymentFailed from "@/components/payment/PaymentFailed.vue";
// import PaymentPending from "@/components/payment/PaymentPending.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapActions } from "pinia";
export default {
  components: {
    // PaymentSucceed,
    // PaymentFailed,
    // PaymentPending,
  },
  name: "PaymentStatus",
  data() {
    return {
      paymentStatus: "",
      loadingPaymentStatus: true,
    };
  },
  props: {
    bnpl: {
      required: false,
      default: false,
    },
  },
  created() {
    // const isMobileDevice =
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   );
    // if (!isMobileDevice) {
    //   if (
    //     window.location.href.includes("redirect") ||
    //     window.location.href.includes("payment_intent")
    //   ) {
    //     window.close();
    //   }
    // }
    // wait for payment to be processed
    this.loadingPaymentStatus = true;
    setTimeout(
      () => {
        this.getPaymentStatus()
          .then((status) => {
            this.paymentStatus = status;
            this.loadingPaymentStatus = false;
            if (status == 4) {
              this.$router.push("/order_cancelled");
            }
            if (status == 3) {
              this.$router.push("/payment_succeed");
            }
            if (status == 2) {
              this.$router.push("/payment_failed");
            }
            if (status == 1) {
              this.$router.push("/payment_pending");
            }
            if (status == 0) {
              this.$router.push("/payment_form");
            }
          })
          .catch((err) => {
            this.loadingPaymentStatus = false;
            console.log(err);
            this.$router.push("/payment_failed");
          });
      },
      this.bnpl == 'true' ? 1000 : 0
    );
  },
  methods: {
    ...mapActions(usePaymentStore, ["getPaymentStatus"]),
  },
};
</script>

<style></style>
