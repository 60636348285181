<template>
  <div class="inputContainer">
    <div class="inputTitle">
      {{ content }}
      <span v-if="isRequired" class="required">*</span>
    </div>
    <el-input
      v-model="this.inputValue"
      maxlength="200"
      placeholder="Enter your comments"
      type="textarea"
      @change="onChange"
    />
    <div class="characterLeft">{{ characterLeft }} characters left</div>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireRadio",
  props: {
    isRequired: {
      type: Boolean,
    },
    content: {
      type: String,
      required: true,
    },
  },
  emits: ["onChange"],
  data() {
    return {
      inputValue: '',
      characterLeft: 200,
    };
  },
  watch: {
    inputValue(newValue) {
      this.characterLeft = 200 - newValue.length;
    },
  },
  methods: {
    onChange() {
      this.$emit("onChange", this.inputValue);
    },
  },
};
</script>

<style scoped>
.inputContainer {
  width: 100%;
}

.inputTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  margin-bottom: 8px;
}

.required {
  color: #ff1d00;
}

:deep(.el-textarea__inner) {
  color: #000000 !important;
  box-shadow: none;
  border: 1px solid #bcccdc;
  padding-left: 21px;
  height: 84px;
  resize: none;
}
.el-textarea {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
}

.characterLeft {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.175px;
  margin-top: 5px;
  color: #c9cacd;
  text-align: right;
}

@media only screen and (max-width: 808px) {
  .inputTitle {
    font-size: 14px;
    margin-bottom: 4px;
  }
}
</style>
