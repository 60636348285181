import { defineStore } from "pinia";
import { questionnaireService } from "@/utils/questionnaireRequest";
import router from "../routes";

export const useQuestionnaireStore = defineStore("QuestionnaireStore", {
  state: () => {
    return {
      sample_id: localStorage.getItem("sample_id")
        ? localStorage.getItem("sample_id")
        : "",
      accession_id: localStorage.getItem("accession_id")
        ? localStorage.getItem("accession_id")
        : "",
      gender: "",
      height: "",
      weight: "",
      hormoneQuestionnaire: {},
      hormoneMedication: [],
      isCompleted: false,
      loadingQuestionnaire: false,
      loadingSubmit: false,
    };
  },
  actions: {
    async getQuestionnaireData() {
      const params = {
        accession_id: this.accession_id,
      };
      this.loadingQuestionnaire = true;
      try {
        const res = await questionnaireService.get("/orders/samples/patients", {
          params: params,
        });
        if(res.data.data.hormone_questionaire && res.data.data.hormone_questionaire.completed_at) {
          router.push("/questionnaire_completed")
        }
        this.gender = res.data.data.patient_gender;
        this.height = res.data.data.patient_height;
        this.weight = res.data.data.patient_weight;
        this.hormoneQuestionnaire = res.data.data.hormone_questionaire || {};
        this.hormoneMedication = res.data.data.hormone_medication;
        this.loadingQuestionnaire = false;
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to retrieve questionnaire", err);
        router.push("/questionnaire_failed");
        this.loadingQuestionnaire = false;
        return Promise.reject(err);
      }
    },
    setGender(val) {
      this.gender = val;
    },
    setHormoneQuestionnaire(val) {
      this.hormoneQuestionnaire = val;
    },
    setIsCompleted(val) {
      this.isCompleted = val;
    },
    setUsedHormoneMedication(val) {
      this.hormoneQuestionnaire.hormone_medication_used = val;
    },
    setPregnancy(val) {
      this.hormoneQuestionnaire.is_currently_pregnant = val;
    },
    setPostmenopausal(val) {
      this.hormoneQuestionnaire.is_postmenopausal = val;
    },
    setHysterectomy(value) {
      this.hormoneQuestionnaire.hysterectomy = value;
    },
    setOvariesRemoved(val) {
      this.hormoneQuestionnaire.is_ovaries_removed = val;
    },
    setMenstrualPhase(val) {
      this.hormoneQuestionnaire.current_menstrual_phase = val;
    },
    setHormoneMedication(val) {
      this.hormoneMedication = val;
    },
    setHeight(val) {
      this.height = val;
    },
    setWeight(val) {
      this.weight = val;
    },
    submitQuestionnaire(val) {
      this.loadingSubmit = true;
      const params = {
        accession_id: this.accession_id,
        sample_id: this.sample_id,
        patient_height: Number(this.height),
        patient_weight: Number(this.weight),
        hormone_medication_used:
          this.hormoneQuestionnaire.hormone_medication_used,
        hormone_medication: this.hormoneQuestionnaire.hormone_medication_used ? this.hormoneMedication : [],
        is_currently_pregnant: this.hormoneQuestionnaire.is_currently_pregnant,
        is_postmenopausal: this.hormoneQuestionnaire.is_postmenopausal,
        hysterectomy: this.hormoneQuestionnaire.hysterectomy,
        is_ovaries_removed: this.hormoneQuestionnaire.is_ovaries_removed,
        current_menstrual_phase:
          this.hormoneQuestionnaire.current_menstrual_phase,
        save_for_now: val,
        // clear_questionaire: true,
        // clear_hormone_records: true,
      };
      questionnaireService
        .post("/orders/samples/questionaires", params)
        .then((res) => {
          this.loadingSubmit = false;
          if (!val) {
            router.push("/questionnaire_completed");
          }
        })
        .catch((err) => {
          this.loadingSubmit = false;
          router.push("/questionnaire_failed");
          console.log("submitQuestionnaire", err);
        });
    },
  },
});
