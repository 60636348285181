<template>
  <div id="page-container">
    <div class="pageBodyContainer">
      <img
        class="paymentStatusIcon"
        src="@/assets/payment/failure.svg"
        alt="payment failed"
      />
      <div class="paymentStatusTitle">Order Cancelled</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCancelled",
  mounted() {
      window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  position: relative;
}

.paymentStatusTitle {
  font-size: 30px;
  margin-top: 30px;
}
</style>
