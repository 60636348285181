<template>
    <div class = "switch-tabs">
        <div class = "tabs-wrapper">
            <div v-for = "tab in tabs" :key = "`${tab.replaceAll(' ','_')}-tab`"
            class = "tab-unit" @click = "handleClickTab" :data-tab = "tab">
                <div :class = "['tab-text',{'inactive':tab!=modelValue},{'active':tab==modelValue}]" :data-tab = "tab">
                    {{ tab }}
                </div>
                <div :class = "['tab-bottom-highlight',{'active':tab==modelValue},{'inactive':tab!=modelValue}]" :data-tab = "tab"></div>
                <div v-if = "tab == 'At-Home Blood Draw' && modelValue =='At-Home Blood Draw'" class = "tab-notice">
                    The professional phlebotomist will collect blood samples at your specified address (can be at home or workplace).
                </div>
            </div>
        </div>
        <div class = "switch-tabs-bottom-line"></div>
    </div>
    
</template>

<script>
export default{
    props:{
        modelValue:{
            type:String,
            default:'',
        },
        tabs:{
            type:Array,
            default:()=>[],
        }
    },
    data(){
        return{
            // active_tab:this.tabs[0],
        }
    },
    emits:['update:modelValue'],
    methods:{
        handleClickTab(e){
            // console.log('handleClickTab',e.target.getAttribute('data-tab'));
            // this.active_tab = e.target.getAttribute('data-tab');
            this.$emit('update:modelValue',e.target.getAttribute('data-tab'));
        }
    }
}
</script>

<style scoped>
.switch-tabs{
    width:100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.tabs-wrapper{
    display: flex;
    align-items: stretch;
    gap: 48px;
    height: fit-content;
    position: relative;
    z-index: 1;
}
.tab-unit{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.tab-text{
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 21px;
    padding-left:26px;
    padding-right:26px;
    padding-bottom:12px;
}
.tab-text.inactive{
    color: #979797;
    font-weight: 400;
}
.tab-text.active{
    color: #0E4672;
    font-weight: 500;
}
.tab-bottom-highlight{
    width: 100%;
    height: 5px;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
}
.tab-bottom-highlight.active{
    background-color: #0E4672;
}
.tab-bottom-highlight.inactive{
    background-color: transparent;
}
.tab-notice{
    white-space: nowrap;
    color: #979797;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.175px;
    position: absolute;
    bottom: -24px;
}
.switch-tabs-bottom-line{
    height: 1px;
    width:100%;
    border-bottom:1px solid #E6E6E6;

    position: absolute;
    bottom: 2.5px;

}
/* if screen is smaller than 1260px */
@media only screen and (max-width: 1260px){
    .tab-notice{
        white-space: pre-wrap;
        color: #979797;
        text-align: center;
        font-family: 'Roboto';
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.175px;
        position: absolute;
        bottom: -41px;
    }
}
@media only screen and (max-width: 767px){
    .tab-notice{
        display: none;
    }
    .tabs-wrapper{
        display: flex;
        align-items: stretch;
        gap: 0px;
        height: fit-content;
        position: relative;
        z-index: 1;
    }
    .tab-text{
        font-family: 'Roboto';
        font-size: 16px;
        line-height: 19px;
        padding-left:14px;
        padding-right:14px;
        padding-bottom:14px;
    }
    .tab-text.inactive{
        color: #979797;
        font-weight: 400;
    }
    .tab-text.active{
        color: #0E4672;
        font-weight: 500;
    }
}

</style>