<template>
    <div id = "cancel-appointment-view">
        <!-- appointment is canceled, clicked Yes button (canceled right now) -->
        <div class = "status-after-cancel-operation-view">
            <img src = "@/assets/ChangeShippingAddress/success_icon.svg" class = "view-status-icon"/>
            <div class = "bold-content">
                Cancellation Success
            </div>
            <div class = "regular-content">
                If you wish to reschedule your appointment, please contact your provider.
            </div>
        </div>
    </div>
</template>

<script>
export default{
}
</script>

<style scoped>
#cancel-appointment-view{
    margin-top: 80px;
    padding:0 316px;
}
.cancel-operation-view,
.status-after-cancel-operation-view{
    display:flex;
    flex-direction: column;
    align-items:center;
}
.bold-content{
    margin-top: 62px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.next-step-notice{
    padding: 24px 56px;
    border-radius: 4px;

    text-align: center;  
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}

.status-after-cancel-operation-view .regular-content{
    margin-top: 36px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}

@media only screen and (max-width: 1260px) {
    #cancel-appointment-view{
        margin-top: 80px;
        padding:0 100px;
    }
}
@media only screen and (max-width: 767px) {
    #cancel-appointment-view{
        margin-top: 49px;
        padding-left:28px;
        padding-right:27px;
    }

    .bold-content{
        margin-top: 36px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
    }

    .next-step-notice{
        padding: 12px 13px;
        border-radius: 4px;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }
    .view-status-icon{
        height:119px;
        width:119px;
    }
    .status-after-cancel-operation-view .regular-content{
        margin-top: 24px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}
</style>