<template>
  <div class="subpartTitle">Current Menstrual Status</div>
  <div class="mb-[2px]">
    <QuestionnaireRadio
      content="Currently Pregnant:"
      height="short"
      width="wide"
      :isRequired="true"
      :options="yesNoOptions"
      :value="
        hormoneQuestionnaire &&
        hormoneQuestionnaire.is_currently_pregnant !== undefined
          ? hormoneQuestionnaire.is_currently_pregnant
          : ''
      "
      @onChange="handlePrengancyChange"
    />
  </div>
  <div class="mb-[2px]">
    <QuestionnaireRadio
      content="Postmenopausal:"
      height="short"
      width="wide"
      :isRequired="true"
      :options="yesNoOptions"
      :value="
        hormoneQuestionnaire &&
        hormoneQuestionnaire.is_postmenopausal !== undefined
          ? hormoneQuestionnaire.is_postmenopausal
          : ''
      "
      @onChange="handlePostmenopausalChange"
    />
  </div>
  <div class="mb-[2px]">
    <QuestionnaireRadio
      content="Hysterectomy:"
      height="short"
      width="narrow"
      :isRequired="true"
      :options="hysterectomyOptions"
      :value="
        hormoneQuestionnaire && hormoneQuestionnaire.hysterectomy !== undefined
          ? hormoneQuestionnaire.hysterectomy
          : ''
      "
      @onChange="handleHysterectomyChange"
    />
  </div>
  <div class="mb-[14px]">
    <QuestionnaireRadio
      content="Ovaries Removed:"
      height="short"
      width="narrow"
      :isRequired="true"
      :options="ovariesRemovedOptions"
      :value="
        hormoneQuestionnaire &&
        hormoneQuestionnaire.is_ovaries_removed !== undefined
          ? hormoneQuestionnaire.is_ovaries_removed
          : ''
      "
      @onChange="handleOvariesRemovedChange"
    />
  </div>
  <div class="subpartTitle">Menstrual History</div>
  <div class="mb-[2px] menstrualPhaseRadio">
    <QuestionnaireRadio
      content="Specimen Collected During Menstrual Cycle Phase:"
      height="short"
      width="wide"
      :isRequired="true"
      :options="menstrualCyclePhaseOptions"
      :value="
        hormoneQuestionnaire &&
        hormoneQuestionnaire.current_menstrual_phase !== undefined
          ? hormoneQuestionnaire.current_menstrual_phase
          : ''
      "
      @onChange="handleMenstrualPhaseChange"
    />
  </div>
  <div class="importantNote">
    <p>
      <b>Recommended:</b> Collect on day 19,20 or 21 for regular menstrual
      cycles; 5 days before period in case of irregular menstrual cycles, and
      any day when not bleeding for unpredictable menstrual cycles, no menstrual
      cycles for less than 1 year, and no menstrual cycles for greater than 1
      year.
    </p>
  </div>
  <div class="importantNote mt-[10px]">
    <p>
      <b>Note:</b> Reference ranges have been established for luteal,
      follicular, and ovulatory phases and postmenopausal status. Pre-puberty
      and pregnancy reference ranges have not been established due to high
      intra- and interindividual variability.
    </p>
  </div>
  <div class="questionnaireDivider mt-[16px] mb-[16px]" />
</template>

<script>
import QuestionnaireRadio from "./QuestionnaireRadio.vue";
import { useQuestionnaireStore } from "@/stores/QuestionnaireStore.ts";
import { mapState, mapActions } from "pinia";
import {
  yesNoOptions,
  hysterectomyOptions,
  ovariesRemovedOptions,
  menstrualCyclePhaseOptions,
} from "@/utils/questionnaireOptions.js";

export default {
  name: "FemaleRelatedQuestions",
  components: {
    QuestionnaireRadio,
  },
  data() {
    return {
      yesNoOptions,
      hysterectomyOptions,
      ovariesRemovedOptions,
      menstrualCyclePhaseOptions,
    };
  },
  computed: {
    ...mapState(useQuestionnaireStore, ["gender", "hormoneQuestionnaire"]),
  },
  methods: {
    ...mapActions(useQuestionnaireStore, [
      "setPregnancy",
      "setPostmenopausal",
      "setHysterectomy",
      "setOvariesRemoved",
      "setMenstrualPhase",
    ]),
    handlePrengancyChange(value) {
      this.setPregnancy(value);
    },
    handlePostmenopausalChange(value) {
      this.setPostmenopausal(value);
    },
    handleHysterectomyChange(value) {
      this.setHysterectomy(value);
    },
    handleOvariesRemovedChange(value) {
      this.setOvariesRemoved(value);
    },
    handleMenstrualPhaseChange(value) {
      this.setMenstrualPhase(value);
    },
  },
};
</script>

<style scoped>
.subpartTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.175px;
  color: #0e4672;
  margin-bottom: 12px;
}

.importantNote p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  color: #0e4672;
}

.importantNote b {
  font-weight: 700;
}

@media only screen and (max-width: 1024px) {
  .subpartTitle {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .importantNote p {
    font-size: 12px;
  }
}
</style>
