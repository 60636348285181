<template>
    <div id = "confirm-appointment-view">
        <div class = "status-after-confirm-operation-view">
            <img src = "@/assets/ChangeShippingAddress/failed_icon.svg" class = "view-status-icon"/>
            <div class = "bold-content" >Confirmation Failed</div>
            <div class = "regular-content">
                We cannot confirm your appointment at this moment. Please try again later.
            </div>
        </div>
    </div>
</template>

<style scoped>
#confirm-appointment-view{
    margin-top: 80px;
    padding:0 316px;
}
.status-after-confirm-operation-view{
    display:flex;
    flex-direction: column;
    align-items:center;
}
.bold-content{
    margin-top: 62px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.status-after-confirm-operation-view .regular-content{
    margin-top: 36px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}
@media only screen and (max-width: 1260px) {
    #confirm-appointment-view{
        margin-top: 80px;
        padding:0 100px;
    }
}
@media only screen and (max-width: 767px) {
    #confirm-appointment-view{
        margin-top: 49px;
        padding-left:28px;
        padding-right:27px;
    }

    .bold-content{
        margin-top: 36px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
    }
    .view-status-icon{
        height:119px;
        width:119px;
    }
    .status-after-confirm-operation-view .regular-content{
        margin-top: 24px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}
</style>