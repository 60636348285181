<template>
  <div class="questionnaireDivider mt-[24px] mb-[16px]" />
  <div class="flex flex-col">
    <div class="heightContainer">
      <div class="subTitle">
        Height
        <span class="required">*</span>
      </div>
      <el-input
        class="heightInput"
        v-model="this.feetInput"
        @blur="handleHeightChange"
      />
      <div class="unitPart feetUnit">ft</div>
      <el-input
        class="heightInput"
        v-model="this.inchesInput"
        @blur="handleHeightChange"
      />
      <div class="unitPart">inches</div>
    </div>
    <div class="weightContainer">
      <div class="subTitle">
        Weight
        <span class="required">*</span>
      </div>
      <el-input
        class="weightInput"
        v-model="this.weightInput"
        @blur="handleWeightChange"
      />
      <div class="unitPart">pounds</div>
    </div>
  </div>
  <div class="questionnaireDivider mb-[16px]" />
</template>

<script>
import { useQuestionnaireStore } from "@/stores/QuestionnaireStore.ts";
import { mapActions, mapState } from "pinia";

export default {
  name: "HeightAndWeightFields",
  data() {
    return {
      feetInput: 0,
      inchesInput: 0,
      weightInput: 0,
    };
  },
  watch: {
    weight: {
      immediate: true,
      handler(newValue) {
        this.weightInput = newValue;
      },
    },
    height: {
      immediate: true,
      handler(newValue) {
        this.feetInput = Math.floor(newValue / 12);
        this.inchesInput = newValue % 12;
      },
    },
  },
  computed: {
    ...mapState(useQuestionnaireStore, [
      "questionnaireData",
      "height",
      "weight",
    ]),
  },
  methods: {
    ...mapActions(useQuestionnaireStore, ["setHeight", "setWeight"]),
    handleHeightChange() {
      if (!this.feetInput) {
        this.feetInput = 0;
      }
      if (!this.inchesInput) {
        this.inchesInput = 0;
      }
      let height = Number(this.feetInput * 12) + Number(this.inchesInput);
      this.setHeight(height);
    },
    handleWeightChange() {
      if (!this.weightInput) {
        this.weightInput = 0;
      }
      this.setWeight(Number(this.weightInput));
    },
  },
};
</script>

<style scoped>
.required {
  color: #ff1d00;
}

.heightContainer,
.weightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.subTitle {
  width: 10vw;
  min-width: 100px;
}

:deep(.el-input__inner) {
  color: #000000 !important;
}
.el-input {
  height: 42px;
  font-family: Roboto;
  width: 16vw;
  max-width: 180px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
}
:deep(.el-input__wrapper) {
  box-shadow: none;
  border: 1px solid #bcccdc;
  padding-left: 21px;
}

.unitPart {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  margin-left: 10px;
  max-width: 70px;
  width: 5vw;
}

.questionnaireDivider {
  margin: 16px 0;
}

@media only screen and (max-width: 1024px) {
  .unitPart,
  .subTitle {
    font-size: 14px;
  }
  .unitPart {
    margin-left: 5px;
  }
  .el-input {
    font-size: 12px;
  }
  .subTitle {
    width: 10vw;
    min-width: 60px;
  }
  .feetUnit {
    width: 5vw;
  }
  .heightInput.el-input {
    width: 28vw;
    max-width: 767px;
  }
  .weightInput.el-input {
    width: 61.5vw;
    max-width: 767px;
  }
  .questionnaireDivider {
    margin: 12px 0;
  }
}
</style>
