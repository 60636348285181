<template>
  <div id="page-container">
    <div class="pageBodyContainer">
      <div class="nyFormTitle">NY Waive Form</div>
      <div class="nyFormSubtitle">
        Patient Confirmation Regarding Specimen Collection Site
      </div>
      <div class="nyFormContent">
        <div class="nyFormDivider mt-[24px] mb-[16px]" />
        <div class="subpartContainer">
          <NYFormInput
            content="Patient Name"
            :isRequired="true"
            :value="patient_name"
          />
          <NYFormInput
            content="Patient Phone"
            :isRequired="true"
            :value="patient_phone"
            @onChange="setPatientPhone"
            @updateAllFieldsFilled="updateAllFieldsFilled"
          />
        </div>
        <div class="subpartContainer">
          <NYFormInput
            content="Patient Email"
            :isRequired="true"
            :value="patient_email"
          />
          <NYFormInput
            content="Sample Collection Date"
            inputType="date"
            :isRequired="false"
            :value="sample_collection_date"
            @onChange="setSampleCollectionDate"
            @updateAllFieldsFilled="updateAllFieldsFilled"
          />
        </div>
        <div class="subpartContainer">
          <NYFormTextarea
            content="Comments"
            :isRequired="false"
            @onChange="setComment"
          />
        </div>
        <div class="nyFormNote">
          Vibrant America Clinical Laboratory has currently obtained a permit to
          perform clinical laboratory testing for specimens derived in the state
          of New York for all testing in Clinical Chemistry, Diagnostic
          Immunology, Endocrinology and Hematology but not for its Vibrant
          Wellness Testing.
          <br />
          <br />
          I confirm that the samples for Vibrant Wellness testing provided to
          Vibrant America Clinical laboratory, in my name listed above, were not
          collected within the boundaries of the state of New York. I
          acknowledge that Vibrant America Clinical laboratory will rely upon
          this signed statement from me as proof of that fact, and that Vibrant
          America Clinical Laboratory will complete the requested testing upon
          receipt of this signed statement consistent with its existing
          laboratory permits.
        </div>
        <div class="subpartContainer">
          <SignatureDialog @updateAllFieldsFilled="updateAllFieldsFilled"/>
        </div>
        <div class="subpartContainer">
          <NYFormInput
            content="Printed Name of Patient or Legal Guardian"
            :isRequired="true"
            @onChange="setPrintedName"
            @updateAllFieldsFilled="updateAllFieldsFilled"
          />
          <NYFormInput
            content="Date"
            inputType="date"
            :isRequired="true"
            @onChange="setDate"
            @updateAllFieldsFilled="updateAllFieldsFilled"
          />
        </div>
        <div class="subpartContainer">
          <NYFormInput
            content="Relationship to Patient, if signed by Legal Guardian"
            inputType="selector"
            :options="relationshipOptions"
            :isRequired="false"
            @onChange="setRelationship"
            @updateAllFieldsFilled="updateAllFieldsFilled"
          />
          <NYFormInput
            content="If choose other, please type your relationship here"
            :isRequired="this.relationship == 'Other'"
            @onChange="setOtherRelationship"
            @updateAllFieldsFilled="updateAllFieldsFilled"
          />
        </div>
        <div class="subpartContainer confirmContainer">
          <el-button
            class="submitNYFormButton"
            @click="submitNYForm"
            :disabled="!allFieldsFilled"
            >Submit</el-button
          >
        </div>
        <div class="subpartContainer nyContactInfo" v-if="screenSize > 808">
          <span class="text-base"> 4829-4506-7001.2 </span>
          <div class="contactInfoDivider" />
          <span class="font-medium"> HIPAA Document </span>
          <span>
            Laboratory Director: Mervyn Sahud, MD&nbsp;&nbsp;CLIA:
            05D2078809&nbsp;&nbsp;CLF: 00346278
          </span>
          <span class="font-medium"> Vibrant America Clinical Laboratory </span>
          <span>
            Address: 3521 Leonard Ct, Santa Clara, CA, 95054&nbsp;&nbsp;Phone:
            +1(866)364-0963&nbsp;&nbsp;FAX: +1(650)805-8262&nbsp;&nbsp;Email:
            support@vibrant-america.com
          </span>
        </div>
        <div class="subpartContainer nyContactInfo" v-else>
          <span class="text-sm"> 4829-4506-7001.2 </span>
          <div class="contactInfoDivider" />
          <span class="font-medium"> HIPAA Document </span>
          <span> Laboratory Director: Mervyn Sahud, MD </span>
          <span> CLIA:05D2078809 </span>
          <span> CLF: 00346278 </span>
          <span class="font-medium"> Vibrant America Clinical Laboratory </span>
          <span> Address: 3521 Leonard Ct, Santa Clara, CA, 95054 </span>
          <span> Phone:+1(866)364-0963 </span>
          <span> FAX: +1(650)805-8262 </span>
          <span> Email: support@vibrant-america.com </span>
        </div>
      </div>
      <img id="footerImage" alt="Footer Image" src="@/assets/footerImage.svg" />
    </div>
  </div>
</template>

<script>
import { useNYFormStore } from "@/stores/NYFormStore.ts";
import { mapState, mapActions } from "pinia";
import NYFormInput from "./NYFormInput.vue";
import NYFormTextarea from "./NYFormTextarea.vue";
import SignatureDialog from "./SignatureDialog.vue";
import relationshipOptions from "@/utils/relationshipOptions.js";

export default {
  name: "NYFormView",
  components: {
    NYFormInput,
    NYFormTextarea,
    SignatureDialog,
  },
  data() {
    return {
      allFieldsFilled: false,
      relationshipOptions,
      screenSize: window.innerWidth,
    };
  },
  async created() {
    try {
      await this.getNYFormData();
    } catch (error) {
      console.log(error);
    }
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapState(useNYFormStore, [
      "patient_name",
      "patient_phone",
      "patient_email",
      "sample_collection_date",
      "signature",
      "printed_name",
      "date",
      "relationship",
      "other_relationship",
    ]),
  },
  methods: {
    ...mapActions(useNYFormStore, [
      "getNYFormData",
      "setPatientPhone",
      "setSampleCollectionDate",
      "setSignature",
      "setPrintedName",
      "setDate",
      "setRelationship",
      "setOtherRelationship",
      "setComment",
      "submitNYForm",
    ]),
    handleResize() {
      this.screenSize = window.innerWidth;
    },
    updateAllFieldsFilled() {
      let allFields;
      allFields = {
        patient_name: this.patient_name,
        patient_phone: this.patient_phone,
        patient_email: this.patient_email,
        signature: this.signature,
        printed_name: this.printed_name,
        date: this.date,
      };
      this.allFieldsFilled = Object.values(allFields).every(
        (field) => field !== null && field !== undefined && field !== ""
      );
      if (this.relationship == "Other" && this.other_relationship == "") {
        this.allFieldsFilled = false;
      }
    },
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  min-height: 1000px;
  position: relative;
}

.nyFormTitle {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.175px;
  color: #0e4672;
}

.nyFormContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56vw;
}

.nyFormSubtitle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.175px;
  color: #0e4672;
  margin-top: 15px;
}

.subpartContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.confirmContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 60px;
}

.nyFormNote {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.175px;
  color: #0e4672;
  background: #faece6;
  padding: 12px 21px;
  border-radius: 4px;
}

.nyFormDivider {
  border-top: 1px solid #bcccdc;
  width: 100%;
}

.contactInfoDivider {
  border-top: 1px solid #bcccdc;
  width: 60%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.nyContactInfo {
  font-family: Roboto;
  color: #0e4672;
  font-size: 12px;
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
}

.submitNYFormButton {
  border: 1px solid #0e4672;
  background: #0e4672;
  width: 56vw;
  height: 42px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.4px;
}

.submitNYFormButton:hover {
  border: 1px solid #5194d2;
  background: #5194d2;
}

.submitNYFormButton.is-disabled {
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
  color: #bbbbbb !important;
}

.dialogTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.46px;
  text-align: center;
  color: #0e4672;
  margin-bottom: 10px;
}

.dialogContent {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.46px;
  text-align: center;
  color: #0e4672;
}

@media only screen and (max-width: 1180px) {
  #footerImage {
    display: none;
  }

  .nyFormContent {
    width: 90%;
  }

  .confirmContainer {
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 50px;
  }

  .submitNYFormButton {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 808px) {
  .nyFormTitle {
    font-size: 18px;
  }
  .nyFormSubtitle {
    font-size: 12px;
    margin-top: 6px;
  }
  .nyFormNote {
    font-size: 12px;
  }
  .dialogTitle,
  .dialogContent {
    font-size: 14px;
  }

  .subpartContainer {
    flex-direction: column;
  }

  .nyContactInfo {
    margin-bottom: 60px;
  }
}
</style>
