<template>
    <el-dialog
        v-model="visible"
        top = '10vh'
        class = 'confirm-address-dialog'
        :close-on-click-modal="false"
        @close = "handleDialogClose()"
    >
        <template #header = '{close}'>
            <div class = 'dialog-header-content'>
                <img src = "@/assets/BloodDraw/close_icon.svg" @click="close" class = "close-icon"/>
            </div>
        </template>
        <div class  = 'confirm-address-dialog-content'>
            <div class = 'confirm-address-dialog-title'>
                Action Required: Schedule Your Blood Draw
            </div>
            <div class = "current-shipping-address-summary">
                It appears that you haven't received your blood draw kit yet. The expected delivery time is within the next 3 business days. Please <span class = "bold">ensure that your concierge blood draw service is scheduled for a time after this period.</span>
            </div>
            <div class = "current-shipping-address-summary">
                For added convenience, once your kit arrives, we will send you another notification with the option to select your service time. You may also choose to schedule the service at that point.
            </div>
            <div class = "current-shipping-address-summary">
                Thank you for your cooperation!
            </div>
            <div class = "confirm-ops-btns-div">
                <div class = "confirm-btn" @click="clickGoScheduleBtn" >Go Schedule</div>
                <div class = "edit-btn" @click="visible=false">I will schedule after receiving kits</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import moment from "moment";
import { addBusinessDays } from "@/components/shippingAddress/shippingAddressData/dateFunctions";
import { isProd }  from "@/components/bloodDraw/BloodDrawData/request";
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { mapState } from 'pinia';
export default{
    data(){
        return{
            visible:true,
        }
    },
    computed:{
        ...mapState(useShippingAddressStore, [
            'accession_id'
        ]),
    },
    emits:['close'],
    methods:{
        handleDialogClose(){
            this.$emit('close');
        },
        async clickGoScheduleBtn(){
            console.log('click Go Schedule');
            // -> jump to Zhang Jun Page for scheduling: start day is 3 days later (in millinseconds)
            const zhang_jun_base_url = isProd ? 'https://phleb.vibrant-wellness.com/app/concierge-request/concierge':'https://phleb.vibrant-wellness.com/app/staging/concierge-request/concierge';
            const three_business_days_later_milliseconds = addBusinessDays(moment(), 3).valueOf();
            const url = `${zhang_jun_base_url}/${this.accession_id}?startDate=${three_business_days_later_milliseconds}`;
            window.location.href = url;
            this.visible = false;
        },
    }
}
</script>

<style scoped>
.dialog-header-content{
    display:flex;
    justify-content:flex-end;
    padding-right:24px;
    padding-top:24px;
    padding-bottom:58px;
}
.close-icon{
    cursor:pointer;
}
.confirm-address-dialog-content{
    padding-left: 96px;
    padding-right:96px;
    padding-bottom:60px;

    display:flex;
    flex-direction:column;
    gap:60px;
}

.confirm-address-dialog-title{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.458px;
}
.current-shipping-address-summary{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.458px;
}
.current-shipping-address-summary .bold{
    font-weight: 700;
}

.summary-row{
    display:flex;
    justify-content:space-between;
    gap:35px;
}
.summary-text{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.4px;
}
.summary-divider{
    margin-top:12px;
    margin-bottom:16px;
    display:block;
    content:'';
    height:1px;
    background: #0E4672;

}
.confirm-ops-btns-div{
    display:flex;
    justify-content:center;
    gap:16px;
}
.edit-btn{
    padding: 12px 0px;
    width:calc(50% - 8px);
    min-width:132px;
    border-radius: 4px;
    border: 1px solid #0E4672;
    text-align:center;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
.confirm-btn{
    padding: 13px 0px;
    width:calc(50% - 8px);
    min-width:132px;
    border-radius: 4px;
    background:#0E4672;
    text-align:center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
.retry-notice-div{
    margin-top:4px;
    color: #C14F49;
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.3px;
    text-align: center;
}
@media only screen and (max-width: 1260px){
    .confirm-address-dialog-content{
        padding-left: 48px;
        padding-right:48px;
        padding-bottom:75px;
        gap:40px;
    }
}

@media only screen and (max-width: 767px){
    .dialog-header-content{
        display:flex;
        justify-content:flex-end;
        padding-right:18px;
        padding-top:18px;
        padding-bottom:18px;
    }
    .confirm-address-dialog-content{
        padding-left: 24px;
        padding-right:24px;
        padding-bottom:30px;
        gap:24px;
    }
    .confirm-address-dialog-title {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0.458px;
    }
    .summary-text {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
    }
    .summary-divider {
        margin-top: 12px;
        margin-bottom: 16px;
    }

    .confirm-ops-btns-div{
        display:flex;
        flex-direction: column;
        align-items: center;
        row-gap: 8px;
    }

    .edit-btn,
    .confirm-btn{
        width:100%;
        text-align: center;
    }
}
</style>