<template>
  <div class="inputContainer">
    <div class="inputTitle">
      {{ content }}
      <span v-if="isRequired" class="required">*</span>
    </div>
    <el-input
      v-if="this.inputType == 'text'"
      v-model="this.inputValue"
      @change="changeHandler"
      :disabled="
        this.content == 'Patient Name' || this.content == 'Patient Email'
      "
    />
    <el-date-picker
      class="date-picker"
      v-if="inputType === 'date'"
      v-model="inputValue"
      type="date"
      placeholder="MM/DD/YYYY"
      format="MM/DD/YYYY"
      :disabledDate="disabledDate"
      @change="changeHandler"
    />
    <el-select
      v-if="this.inputType == 'selector'"
      v-model="this.inputValue"
      placeholder="- Select -"
      @change="changeHandler"
      :options="options"
      clearable
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: "NYFormInput",
  props: {
    isRequired: {
      type: Boolean,
    },
    content: {
      type: String,
      required: true,
    },
    value: {
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.inputValue = newValue;
      },
      immediate: true,
    },
  },
  emits: ["onChange", "updateAllFieldsFilled"],
  data() {
    return {
      inputValue: this.value,
    };
  },
  methods: {
    changeHandler() {
      this.$emit("onChange", this.inputValue);
      this.$emit("updateAllFieldsFilled");
    },
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
  },
};
</script>

<style scoped>
.inputContainer {
  margin-bottom: 16px;
  width: 27vw;
}

:deep(.date-picker .el-input__wrapper) {
  height: 42px;
}

:deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
  width: 27vw;
}

.inputTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  margin-bottom: 8px;
}

.required {
  color: #ff1d00;
}

:deep(.el-input__inner) {
  color: #000000 !important;
}
.el-input {
  height: 42px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
}
:deep(.el-input__wrapper) {
  box-shadow: none;
  border: 1px solid #bcccdc;
  padding-left: 21px;
}

.el-select {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
  width: 100%;
}

:deep(.el-select .el-input__inner) {
  color: #000000 !important;
}

:deep(.el-select .el-input__wrapper) {
  box-shadow: none !important;
  border: 1px solid #bcccdc;
  padding-left: 21px;
  height: 42px;
}

@media only screen and (max-width: 1180px) {
  .inputContainer {
    width: 48%;
  }

  :deep(.el-select .el-input__wrapper) {
    width: 48%;
  }

  :deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
    width: 100%;
  }
}

@media only screen and (max-width: 808px) {
  .inputContainer {
    width: 100%;
  }

  .inputTitle {
    font-size: 14px;
    margin-bottom: 4px;
  }

  :deep(.el-select .el-input__wrapper) {
    width: 100%;
  }

  :deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
    width: 100%;
  }
}
</style>
