export function getPublishableKey() {
  const customerId = localStorage.getItem("customer_id");
  // const isMarkupOrder = localStorage.getItem("is_markup_order") == "true";
  const providerHasRevenue = localStorage.getItem("provider_has_revenue") == "true";
  const isProduction = process.env.VUE_APP_ENV === "production";

  if (isProduction) {
    if (providerHasRevenue) {
      if (
        customerId !== "999997" &&
        customerId !== "29520" &&
        customerId !== "29818" &&
        customerId !== "31157" &&
        customerId !== "1000002" &&
        customerId !== ""
      ) {
        return process.env.VUE_APP_STRIPE_MARKUP_PRODUCTION_KEY;
      } else {
        return process.env.VUE_APP_STRIPE_MARKUP_SANDBOX_KEY;
      }
    } else {
      if (
        customerId !== "999997" &&
        customerId !== "29520" &&
        customerId !== "29818" &&
        customerId !== "31157" &&
        customerId !== "1000002" &&
        customerId !== ""
      ) {
        return process.env.VUE_APP_STRIPE_PRODUCTION_KEY;
      } else {
        return process.env.VUE_APP_STRIPE_SANDBOX_KEY;
      }
    }
  } else {
    return providerHasRevenue
      ? process.env.VUE_APP_STRIPE_MARKUP_SANDBOX_KEY
      : process.env.VUE_APP_STRIPE_SANDBOX_KEY;
  }
}
