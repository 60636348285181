<template>
  <el-radio-group v-model="value" @change="onChange">
    <el-radio
      v-for="option in options"
      :key="option.value"
      :label="option.value"
      border
      :class="{
        'bnpl-option': option.label === 'Buy Now Pay Later' && this.count >= 3,
      }"
      :disabled="
        (option.label == 'Klarna' && disableKlarna) ||
        (option.label == 'After Pay' && disableAfterPay)
      "
    >
      <div class="flex items-center">
        <img
          v-if="option.label == 'Klarna'"
          class="radioImage"
          src="@/assets/payment/klarna.svg"
          alt="klarna"
        />
        <img
          v-if="option.label == 'After Pay'"
          class="radioImage"
          src="@/assets/payment/afterpay.svg"
          alt="afterpay"
        />
        <img
          v-if="option.label == 'Credit / Debit Card' && this.value !== 'card'"
          class="cardImage"
          src="@/assets/payment/card.svg"
          alt="card"
        />
        <img
          v-if="option.label == 'Credit / Debit Card' && this.value == 'card'"
          class="cardImage"
          src="@/assets/payment/card_blue.svg"
          alt="card_blue"
        />
        <div class="flex flex-col">
          <div class="labelContainer">
            {{ option.label }}
            <div class="bnplLogoContainer">
              <img
                v-if="option.label == 'Buy Now Pay Later' && this.count >= 3"
                class="logoImage"
                src="@/assets/payment/afterpay_chip.svg"
                alt="afterpay"
              />
              <img
                v-if="option.label == 'Buy Now Pay Later' && this.count >= 3"
                class="logoImage"
                src="@/assets/payment/klarna_chip.svg"
                alt="klarna"
              />
            </div>
          </div>
          <span
            v-if="option.label === 'Buy Now Pay Later' && this.count >= 3"
            class="payAmountMessage"
          >
            Interest Free Payments of ${{ this.amountToPayBiweekly }} Only
            Bi-Weekly
          </span>
        </div>
        <span
          v-if="option.label === 'Buy Now Pay Later' && this.count >= 3"
          class="bnplHintMessage"
        >
          Your exact rate is personalized based on your unique financial
          situation.
        </span>
      </div>
    </el-radio>
    <GooglePayButton v-if="isPaymentMethod" />
    <ApplePayButton v-if="isPaymentMethod" />
  </el-radio-group>
</template>

<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState } from "pinia";
import ApplePayButton from "@/components/payment/ApplePayButton.vue";
import GooglePayButton from "@/components/payment/GooglePayButton.vue";

export default {
  name: "PaymentRadio",
  props: {
    options: {
      type: Array,
      required: true,
    },
    isPaymentMethod: {
      type: Boolean,
      required: false,
    },
    preSelectValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    ApplePayButton,
    GooglePayButton,
  },
  data() {
    return {
      value: this.preSelectValue,
      patientId: localStorage.getItem("patient_id"),
    };
  },
  created() {
    this.value = this.preSelectValue;
    if (this.preSelectValue !== "") {
      this.$emit("onChange", this.preSelectValue);
    }
  },
  computed: {
    ...mapState(usePaymentStore, ["billingInfo", "count"]),
    amountToPayBiweekly() {
      return (
        (this.billingInfo && Number(this.billingInfo.total / 4).toFixed(2)) || 0
      );
    },
    disableKlarna() {
      return (
        this.billingInfo &&
        (Number(this.billingInfo.total) < 10 ||
          Number(this.billingInfo.total) > 5000)
      );
    },
    disableAfterPay() {
      return (
        this.billingInfo &&
        (Number(this.billingInfo.total) < 1 ||
          Number(this.billingInfo.total) > 4000)
      );
    },
  },
  methods: {
    onChange(value) {
      this.$matomo &&
        this.$matomo.trackEvent(
          "pns",
          "payment_page",
          `payment_method:${value}`,
          1
        );
      this.$emit("onChange", value);
    },
  },
};
</script>

<style scoped>
.el-radio-group {
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.payAmountMessage {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  letter-spacing: 0.45px;
  text-align: left;
  color: #20a8c3;
  width: 100%;
  white-space: normal;
  margin-top: 4px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bnplLogoContainer {
  display: flex;
}

.bnplHintMessage {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  letter-spacing: 0.458px;
  color: #979797;
  position: absolute;
  margin-top: 110px;
  margin-left: -80px;
  width: 27vw;
  white-space: normal;
}

.bnpl-option.el-radio.is-bordered {
  margin-bottom: 30px;
}

.logoImage {
  margin-left: 3px;
  height: 24px;
}

/* .klarna-option::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  white-space: normal;
  margin-top: 100px;
  color: #000;
  font-size: 14px; 
} */

:deep(.el-radio.is-bordered) {
  width: 27vw;
  /* min-width: 284px; */
  height: 80px;
  border-radius: 4px;
  border: 1px solid #bcccdc;
  margin-bottom: 15px;
  margin-right: 0px;
}

:deep(.el-radio__label) {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.46px;
  color: #000000;
}

:deep(.el-radio.is-bordered.is-checked) {
  border: 1px solid #20a8c3;
}

:deep(.el-radio__input.is-checked + .el-radio__label) {
  font-weight: 500;
  color: #20a8c3;
}

:deep(.el-radio__inner) {
  height: 20px;
  width: 20px;
  margin-left: 23px;
  margin-right: 23px;
  border: 2px #000000 solid !important;
}

:deep(.el-radio.is-disabled .el-radio__inner) {
  border: 2px #c4c4c4 solid !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  border: 2px #20a8c3 solid !important;
  background: transparent !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner::after) {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #20a8c3;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px #20a8c3 solid !important;
}

.cardImage {
  margin-right: 8px;
}

.radioImage {
  margin-right: 16px;
}

@media only screen and (max-width: 1350px) {
  .labelContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1024px) {
  .el-radio-group {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    flex-wrap: nowrap;
  }

  .labelContainer {
    flex-direction: row;
    align-items: center;
  }

  .el-radio {
    margin-right: 0px;
    width: 100% !important;
    margin-bottom: 10px;
  }

  .bnplHintMessage {
    width: 100%;
    margin-top: 110px;
  }
}

@media only screen and (max-width: 808px) {
  .cardImage {
    margin-right: 4px;
    height: 12px;
  }

  .logoImage {
    margin-left: 3px;
    height: 18px;
  }

  .radioImage {
    margin-right: 8px;
    height: 20px;
  }

  .el-radio.is-bordered {
    height: 42px;
    margin-bottom: 8px;
  }

  .bnplHintMessage {
    width: 100%;
    margin-top: 90px;
    margin-left: -55px;
  }

  .bnpl-option.el-radio.is-bordered {
    height: 60px !important;
    margin-bottom: 30px !important;
  }

  .el-radio div {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  :deep(.el-radio__inner) {
    height: 16px !important;
    width: 16px !important;
    margin-left: 12px;
    margin-right: 12px;
    border: 1px #000000 solid !important;
  }

  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border: 1px #20a8c3 solid !important;
  }
}
</style>
