import moment from "moment";
const federal_holidays_2024_and_2025 = [
    '2024-10-14',
    '2024-11-11',
    '2024-11-28',
    '2024-12-25',
    '2025-01-01',
    '2025-01-20',
    '2025-02-17',
    '2025-05-26',
    '2025-06-19',
    '2025-07-04',
    '2025-09-01',
    '2025-10-13',
    '2025-11-11',
    '2025-11-27',
    '2025-12-25'
];
export function addBusinessDays(currentDate, businessDays){
//currentDate
//businessDays: Number
    currentDate = moment(currentDate);
    while(businessDays>0){
        currentDate.add(1,'days');
        if(currentDate.isoWeekday() < 6 && !federal_holidays_2024_and_2025.includes(currentDate.format('YYYY-MM-DD'))){
            businessDays -= 1;
        }
    }
    return currentDate;
}