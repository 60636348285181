import { defineStore } from "pinia";
import { nyformService } from "@/utils/nyformRequest";
import router from "../routes";

export const useNYFormStore = defineStore("NYFormStore", {
  state: () => {
    return {
      sample_id: localStorage.getItem("sample_id")
        ? localStorage.getItem("sample_id")
        : "",
      accession_id: localStorage.getItem("accession_id")
        ? localStorage.getItem("accession_id")
        : "",
      hasBeenSubmitted: false,
      isCompleted: false,
      loadingNYform: false,
      loadingSubmit: false,
      patient_name: "",
      patient_email: "",
      patient_phone: "",
      sample_collection_date: "",
      signature: "",
      printed_name: "",
      date: "",
      relationship: "",
      other_relationship: "",
      comment: "",
    };
  },
  actions: {
    setPatientName(val) {
      this.patient_name = val;
    },
    setPatientEmail(val) {
      this.patient_email = val;
    },
    setPatientPhone(val) {
      this.patient_phone = val;
    },
    setSampleCollectionDate(val) {
      if (val) {
        this.sample_collection_date = new Date(val).toISOString();
      } else {
        this.sample_collection_date = "";
      }
    },
    setSignature(val) {
      this.signature = val;
    },
    setPrintedName(val) {
      this.printed_name = val;
    },
    setDate(val) {
      if (val) {
        this.date = new Date(val).toISOString();
      } else {
        this.date = "";
      }
    },
    setRelationship(val) {
      this.relationship = val;
    },
    setOtherRelationship(val) {
      this.other_relationship = val;
    },
    setComment(val) {
      this.comment = val;
    },
    async getNYFormData() {
      const params = {
        accession_id: this.accession_id,
      };
      this.loadingNYform = true;
      try {
        const res = await nyformService.get("/getPatientWaiverForm", {
          params: params,
        });
        this.loadingNYform = false;
        this.setPatientName(res.data.result.patient_name);
        this.setPatientEmail(res.data.result.patient_email);
        this.setPatientPhone(res.data.result.patient_phone);
        this.setSampleCollectionDate(res.data.result.sample_collection_time);
        if(res.data.result.is_submitted){
          router.push("/nyform_success");
        }
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to retrieve ny form data", err);
        this.loadingNYform = false;
        router.push("/nyform_failed");
        return Promise.reject(err);
      }
    },
    submitNYForm() {
      let params = {
        accession_id: this.accession_id,
        patient_name: this.patient_name,
        patient_phone: this.patient_phone,
        patient_email: this.patient_email,
        printed_name: this.printed_name,
        date: this.date,
        sample_collection_time: this.sample_collection_date,
        signature: this.signature,
        // relationship_to_patient: this.relationship,
        relationship: this.relationship,
        other_relationship: this.other_relationship,
        comment: this.comment,
      };
      this.loadingSubmit = true;
      nyformService
        .post("/waiverFormRequest", 
          params,
        )
        .then((res) => {
          this.loadingSubmit = false;
          router.push("/nyform_success");
          console.log("res", res);
        })
        .catch((err) => {
          this.loadingSubmit = false;
          router.push("/nyform_failed");
          console.log("failed to submit ny form", err);
        });
    },
  },
});
