import { defineStore } from "pinia";
import { provideApolloClient, useMutation, useQuery } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import gql from 'graphql-tag'
import { ElNotification } from "element-plus";

export const useConfirmAppointmentStore = defineStore( 'ConfirmAppointmentStore',{
    state:()=>{
        return{
            accession_id:null,
            sample_id:null,
            event_id:null,
            edit_type:null,
            occurrence_start:null,
            token:null,
        }
    },
    actions:{
        storeAllQueryInfomation(query){
            this.accession_id = query.accession_id;
            this.sample_id = query.sample_id;
            this.event_id = Number(query.event_id);
            this.edit_type = query.edit_type;
            this.occurrence_start = query.occurrence_start;

            this.token = query.token;
            localStorage.setItem('graphql-token', this.token);
        },
        //graphQL api
        async confirmAppointmentAPI(){
            provideApolloClient(apolloClient);
            const CONFIRM_EVENT = 
            gql `
                mutation SelectiveEditEvent($selectiveEditEventData2: selectiveEditEventInput!) {
                    selectiveEditEvent(data: $selectiveEditEventData2) {
                        id
                        status
                    }
                }
            `;
            const {mutate: confirm_appointment_in_backend} = useMutation(
                CONFIRM_EVENT,
                {
                    context:{
                        headers:{
                            authorization: this.token ? `jwt ${this.token}` : '',
                        }
                    }
                }
            );
            try {
                const response = await confirm_appointment_in_backend(
                    {
                        "selectiveEditEventData2": {
                            "event_id": this.event_id,
                            "event_input":{
                                "status":"confirmed"
                            },
                            "edit_type": this.edit_type,
                            "occurrence_start":this.occurrence_start,
                        }
                    }
                );
                // # NOTE: don't need to manually update, as Apollo Client is configured with cache:new InMemoryCache(),
                // InMemoryCache is designed to handle normalization and cache updates automatically. 
                // It ensures that when you mutate data, the cache is updated accordingly, and any queries that rely on that data are refreshed.
                // this.event_info = response.data.deleteEvent;
                console.log('response confirmed appointment', response);
                return Promise.resolve(response.data);
            } catch (err) {
                // Handle the error
                console.error("confirmed appointment error:", err.message);
                ElNotification({
                    title: 'Error',
                    type: 'error',
                    customClass: "errorNotification",
                    message: err.message,
                    duration: 0,
                });
                return Promise.reject(err);
            }
        }
    }
})