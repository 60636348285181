<template>
  <div class="hintMessage">
    Specify hormones/medications used below if you answered
    <span>“Yes”</span> to hormones/medications used in the past two months
  </div>
  <table v-if="screenSize > 1024" class="originalTable">
    <thead>
      <tr>
        <th>Hormone type</th>
        <th>Brand</th>
        <th>Delivery</th>
        <th>Dosage</th>
        <th>Last Used</th>
        <th>Times per Day</th>
        <th>How Long Used</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="tableData.length > 0">
      <tr v-for="(item, index) in tableData" :key="index" class="dataRow">
        <td>{{ item.hormone_type }}</td>
        <td>{{ item.brand }}</td>
        <td>{{ item.delivery }}</td>
        <td>{{ item.dosage }}</td>
        <td>{{ item.last_used }}</td>
        <td>{{ item.times_per_day }}</td>
        <td>{{ item.how_long_used }}</td>
        <td>
          <EditHormoneDialog
            :editIndex="index"
            :hormoneRecord="item"
            @editMedicationHistory="editMedicationHistory"
          />
          <img
            src="@/assets/questionnaire/delete_icon.svg"
            alt="delete"
            class="deleteIcon cursor-pointer"
            @click="openDeleteDialog(index)"
          />
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="7" class="emptyMessage">
          No Hormones / Medications Added
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else>
    <table class="mobileTable">
      <thead>
        <tr>
          <th>Hormone type</th>
          <th>Brand</th>
        </tr>
      </thead>
      <tbody v-if="tableData.length > 0">
        <tr v-for="(item, index) in tableData" :key="index" class="dataRow">
          <td>{{ item.hormone_type }}</td>
          <td>{{ item.brand }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" class="emptyMessage">
            No Hormones / Medications Added
          </td>
        </tr>
      </tbody>
    </table>
    <table class="mobileTable">
      <thead>
        <tr>
          <th>Delivery</th>
          <th>Dosage</th>
        </tr>
      </thead>
      <tbody v-if="tableData.length > 0">
        <tr v-for="(item, index) in tableData" :key="index" class="dataRow">
          <td>{{ item.delivery }}</td>
          <td>{{ item.dosage }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" class="emptyMessage">
            No Hormones / Medications Added
          </td>
        </tr>
      </tbody>
    </table>
    <table class="mobileTable">
      <thead>
        <tr>
          <th>Last Used</th>
          <th>Times per Day</th>
        </tr>
      </thead>
      <tbody v-if="tableData.length > 0">
        <tr v-for="(item, index) in tableData" :key="index" class="dataRow">
          <td>{{ item.last_used }}</td>
          <td>{{ item.times_per_day }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" class="emptyMessage">
            No Hormones / Medications Added
          </td>
        </tr>
      </tbody>
    </table>
    <table class="mobileTable">
      <thead>
        <tr>
          <th>How Long Used</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="tableData.length > 0">
        <tr v-for="(item, index) in tableData" :key="index" class="dataRow">
          <td>{{ item.how_long_used }}</td>
          <td>
            <EditHormoneDialog
              :editIndex="index"
              :hormoneRecord="item"
              @editMedicationHistory="editMedicationHistory"
            />
            <img
              src="@/assets/questionnaire/delete_icon.svg"
              alt="delete"
              class="deleteIcon cursor-pointer"
              @click="openDeleteDialog(index)"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" class="emptyMessage">
            No Hormones / Medications Added
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <AddHormoneDialog @addToMedicationHistory="addToMedicationHistory" />
  <el-dialog
    v-model="deleteDialog"
    modal-class="deleteHormoneDialog"
    width="808px"
  >
    <template #title>
      <span class="dialog-title"
        >Are you sure you want to delete this hormone?</span
      >
    </template>
    <div class="dialog-content">
      This action is permanent and cannot be undone.
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="cancelBtn" @click="closeDeleteDialog"
          >Cancel</el-button
        >
        <el-button type="deleteHormoneBtn" @click="deleteHormoneRecord"
          >Delete</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import AddHormoneDialog from "./AddHormoneDialog.vue";
import { useQuestionnaireStore } from "@/stores/QuestionnaireStore.ts";
import { mapActions, mapState } from "pinia";
import EditHormoneDialog from "./EditHormoneDialog.vue";

export default {
  name: "HormoneRecord",
  components: {
    AddHormoneDialog,
    EditHormoneDialog,
  },
  data() {
    return {
      tableData: [],
      screenSize: window.innerWidth,
      deleteDialog: false,
      deleteIndex: null,
    };
  },
  watch: {
    hormoneMedication: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.tableData = newValue;
      },
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapState(useQuestionnaireStore, ["hormoneMedication"]),
  },
  methods: {
    ...mapActions(useQuestionnaireStore, ["setHormoneMedication"]),
    addToMedicationHistory(value) {
      let howLongUsed = "";
      if (value.usedYears) {
        howLongUsed += value.usedYears + " Years ";
      }
      if (value.usedMonths) {
        howLongUsed += value.usedMonths + " Months ";
      }
      if (value.usedDays) {
        howLongUsed += value.usedDays + " Days ";
      }
      const hormoneRecord = {
        hormone_type: value.hormoneType,
        brand: value.brand,
        delivery: value.delivery,
        dosage: value.dosage,
        last_used: value.lastUsed,
        times_per_day: value.timesPerDay,
        how_long_used: howLongUsed,
      };
      this.tableData.push(hormoneRecord);
      this.setHormoneMedication(this.tableData);
    },
    editMedicationHistory(value, index) {
      let howLongUsed = "";
      if (value.usedYears) {
        howLongUsed += value.usedYears + " Years ";
      }
      if (value.usedMonths) {
        howLongUsed += value.usedMonths + " Months ";
      }
      if (value.usedDays) {
        howLongUsed += value.usedDays + " Days ";
      }
      const hormoneRecord = {
        hormone_type: value.hormoneType,
        brand: value.brand,
        delivery: value.delivery,
        dosage: value.dosage,
        last_used: value.lastUsed,
        times_per_day: value.timesPerDay,
        how_long_used: howLongUsed,
      };
      this.tableData[index] = hormoneRecord;
      this.setHormoneMedication(this.tableData);
    },
    handleResize() {
      this.screenSize = window.innerWidth;
    },
    openDeleteDialog(index) {
      this.deleteIndex = index;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.deleteIndex = null;
    },
    deleteHormoneRecord() {
      this.tableData.splice(this.deleteIndex, 1);
      this.setHormoneMedication(this.tableData);
      this.deleteDialog = false;
      this.deleteIndex = null;
    },
  },
};
</script>

<style scoped>
.hintMessage {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  color: #0e4672;
  margin-top: 6px;
  margin-bottom: 16px;
}

.hintMessage span {
  color: #20a8c3;
}

table {
  border: 1px solid #0e4672;
  width: 100%;
  border-collapse: collapse;
}

th {
  border-top: 1px solid #0e4672;
  border-bottom: 1px solid #0e4672;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.175px;
  color: #0e4672;
  height: 39px;
}

thead {
  background: #faece6;
}

.emptyMessage {
  height: 39px;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.dataRow td {
  border-top: 1px solid #0e4672;
  border-bottom: 1px solid #0e4672;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.175px;
  text-align: center;
  height: 39px;
}

th,
td {
  width: 12%;
}

.deleteIcon {
  display: inline;
  filter: invert(69%) sepia(20%) saturate(17%) hue-rotate(354deg)
    brightness(87%) contrast(75%);
}

.deleteIcon:hover {
  filter: invert(52%) sepia(85%) saturate(438%) hue-rotate(142deg)
    brightness(90%) contrast(87%);
}

@media only screen and (max-width: 1024px) {
  .hintMessage {
    font-size: 12px;
  }

  th,
  td {
    font-size: 12px;
    height: 34px;
  }

  table:nth-child(1),
  table:nth-child(2),
  table:nth-child(3) {
    border-bottom: none;
  }
}
</style>
