<template>
  <div class="signatureContainer">
    <div class="signatureTitle">
      Signature of Patient or Legal Guardian
      <span class="required">*</span>
    </div>
    <div class="signatureInputArea">
      <button
        class="signatureButton"
        @click="showSignatureDialog = true"
        v-if="this.isEmpty"
      >
        <img
          src="@/assets/NYForm/signature_icon.svg"
          alt="signature"
          class="mr-[10px]"
        />
        Add Signature
      </button>
      <img
        :src="signatureImage"
        @click="showSignatureDialog = true"
        alt="signature"
        v-else
        style="width: 100%; height: 100%"
      />
    </div>
  </div>
  <el-dialog v-model="showSignatureDialog" center modal-class="signatureDialog">
    <div class="dialogTitle">Set Signature</div>
    <div class="dialogContent">
      Please draw your signature by hand in the draw box
    </div>
    <div class="signatureCanvas">
      <vueSignature ref="signature" :sigOption="option"></vueSignature>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <div class="clearBtnContainer">
          <el-button class="clearBtn" @click="clear">Clear</el-button>
          <el-button class="clearBtn" @click="undo">Undo</el-button>
        </div>
        <el-button class="saveBtn" @click="save">Done</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import vueSignature from "vue-signature";
import { useNYFormStore } from "@/stores/NYFormStore.ts";
import { mapActions } from "pinia";

export default {
  name: "app",
  components: {
    vueSignature,
  },
  data() {
    return {
      option: {
        penColor: "rgb(196, 196, 196)",
        backgroundColor: "rgb(255,255,255)",
      },
      showSignatureDialog: false,
      signatureImage: "",
      isEmpty: true,
    };
  },
  emits: ["updateAllFieldsFilled"],
  methods: {
    ...mapActions(useNYFormStore, ["setSignature"]),
    save() {
      console.log("save signature");
      this.isEmpty = this.$refs.signature.isEmpty();
      if (this.isEmpty) {
        console.log("signature is empty");
        this.setSignature("");
        this.$emit("updateAllFieldsFilled");
        this.showSignatureDialog = false;
        return;
      }
      let svg = this.$refs.signature.save("image/svg+xml");
      this.signatureImage = svg;

      // compress the svg using pako
      // const base64Data = svg.split(",")[1];
      // const svgContent = atob(base64Data);
      // const compressedSvg = pako.gzip(svgContent);
      // const compressedBase64 = btoa(
      //   String.fromCharCode.apply(null, new Uint8Array(compressedSvg))
      // );
      // const compressedSvgDataUri = `data:image/svg+xml;base64,${compressedBase64}`;


      this.setSignature(svg);
      this.$emit("updateAllFieldsFilled");
      this.showSignatureDialog = false;
    },
    clear() {
      this.$refs.signature.clear();
      this.isEmpty = true;
    },
    undo() {
      this.$refs.signature.undo();
    },
  },
};
</script>

<style scoped>
.signatureContainer {
  margin-bottom: 16px;
  width: 100%;
}
.signatureTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  margin-bottom: 8px;
}

.signatureInputArea {
  width: 100%;
  height: 84px;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signatureButton {
  height: 60px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9f6f9;
  border-radius: 2px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.3px;
  color: #20a8c3;
}

.required {
  color: #ff1d00;
}

.signatureCanvas {
  width: 616px;
  height: 168px;
  border-radius: 4px;
  border: 1px solid #bcccdc;
  padding: 5px;
}

.dialogTitle {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.45px;
  text-align: center;
  color: #0e4672;
  margin-top: 10px;
  margin-bottom: 20px;
}

.dialogContent {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
  letter-spacing: 0.45px;
  text-align: center;
  color: #0e4672;
  margin-bottom: 24px;
}

.clearBtn {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.4px;
  width: 166px;
  height: 42px;
  color: #20a8c3;
  border: 1px solid #20a8c3;
  background: #ffffff;
}

.clearBtn:hover {
  color: #30c0db;
  border: 1px solid #30c0db;
}

.saveBtn {
  width: 166px;
  height: 42px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.4px;
  color: #ffffff;
  background: #20a8c3;
  margin-left: 10px;
}

.saveBtn:hover {
  background: #30c0db;
}

.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.clearBtnContainer {
  width: fit-content;
}

@media only screen and (max-width: 808px) {
  .signatureCanvas {
    width: 100%;
  }
  .signatureTitle {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .signatureButton {
    height: 60px;
    width: 90%;
    font-size: 14px;
  }

  .dialogTitle {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .dialogContent {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 15px;
  }

  .dialog-footer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .clearBtnContainer {
    width: 98%;
    display: flex;
    justify-content: space-between;
  }

  .clearBtn {
    width: 48%;
  }

  .saveBtn {
    margin-top: 15px;
    width: 98%;
    margin-left: 0px;
  }
}
</style>
