<template>
  <div class="billingContent">
    <div class="paymentInputContainer">
      <PaymentInput
        content="Email"
        :isRequired="true"
        width="half"
        @onChange="setBillingEmail"
      />
    </div>
    <!-- <div class="paymentInputContainer">
      <div class="inputTitle">Phone Number</div>
      <vue-tel-input v-model="phone" v-bind="vueTel.props"></vue-tel-input>
    </div> -->
  </div>
</template>

<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapActions } from "pinia";
import PaymentInput from "@/components/payment/PaymentInput.vue";

export default {
  name: "ContactInfo",
  components: {
    PaymentInput,
  },
  data() {
    return {
      // phone: "",
      // vueTel: {
      //   phone: "",
      //   props: {
      //     onlyCountries: ["US", "CA", "MX"],
      //     defaultCountry: "US",
      //     placeholder: "",
      //     mode: "international",
      //     inputOptions: {
      //       showDialCode: true,
      //     },
      //     disabledFormatting: false,
      //     wrapperClasses: "country-phone-input",
      //   },
      // },
    };
  },
  // watch: {
  //   phone(newVal) {
  //     this.setBillingPhone(newVal);
  //   },
  // },
  methods: {
    ...mapActions(usePaymentStore, ["setBillingEmail", "setBillingPhone"]),
  },
};
</script>

<style scoped>
.billingContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.paymentInputContainer {
  width: 27vw;
  /* min-width: 284px; */
}

.el-input {
  height: 42px;
}

.vue-tel-input {
  height: 42px;
  border: 1px solid #bcccdc !important;
  box-shadow: none !important;
  border-radius: 4px;
}

:deep(.vue-tel-input .vti__flag) {
  height: 24px;
  width: 24px;
  margin-left: 14px;
  margin-right: 6px;
  border-radius: 50%;
}

:deep(.vue-tel-input .vti__flag.ca) {
  background: url(@/assets/payment/ca_flag.svg) no-repeat;
}

:deep(.vue-tel-input .vti__flag.mx) {
  background: url(@/assets/payment/mx_flag.svg) no-repeat;
}

:deep(.vue-tel-input .vti__flag.us) {
  background: url(@/assets/payment/us_flag.svg) no-repeat;
}

:deep(.vue-tel-input .vti__input) {
  padding-left: 21px;
}

:deep(.vue-tel-input .vti__dropdown) {
  background: #ffffff;
}

:deep(.vti__selection) {
  width: 70px;
  border-right: 1px solid #dcdfe6;
}

:deep(.vue-tel-input .vti__dropdown-item) {
  display: flex;
  align-items: center;
  padding-left: 6px;
}

:deep(.vue-tel-input .vti__dropdown-list) {
  overflow: auto;
  margin-top: 20px;
  border-radius: 4px;
  padding: 6px 12px;
  border: none;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.4);
}

:deep(.vue-tel-input .vti__dropdown-item:hover) {
  background: #faece6;
}

:deep(.vue-tel-input .vti__dropdown-item.highlighted) {
  background: #faece6;
}

:deep(.vue-tel-input strong) {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
  text-align: left;
}

:deep(.vue-tel-input strong + span) {
  display: none;
}

@media only screen and (max-width: 767px) {
  .billingContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .paymentInputContainer {
    width: 100%;
    height: 68px;
  }

  .vue-tel-input {
    font-size: 14px;
    height: 36px;
  }

  .inputTitle {
    font-size: 12px;
  }

  :deep(.vue-tel-input .vti__input) {
    padding-left: 15px;
  }

  :deep(.vue-tel-input .vti__dropdown-item > *) {
    font-size: 12px;
  }

  :deep(.vue-tel-input .vti__flag) {
    height: 20px;
    width: 20px;
  }

  :deep(.vue-tel-input .vti__dropdown-list) {
    padding: 0px;
  }
}
</style>
