import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
//configure Apollo Client
const graphQL_endpoint = process.env.VUE_APP_ENV === "production" ? "https://api.vibrant-wellness.com/v1/lis/portal-calendar/graphql" : 'https://www.vibrant-america.com/lisapi/v1/lis/calendar-staging/graphql';
const httpLink = createHttpLink({
    uri: graphQL_endpoint,
  });
const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export default apolloClient;