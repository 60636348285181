<template>
  <div id="page-container" v-loading="loadingPayment"></div>
</template>

<script>
import { useCommonStore } from "@/stores/CommonStore.ts";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapActions, mapState } from "pinia";
export default {
  name: "PaymentPage",
  props: {
    sample_id: String,
    accession_id: String,
    jwtToken: String,
    charge_type_id: String,
    charge_type: String,
    concierge_needed: String,
    direct_to_blooddraw: String,
    amount_to_pay: Number,
    count: Number,
    always_show_payment:String,
  },
  created() {
    this.setSampleId(this.sample_id);
    this.setAccessionId(this.accession_id);
    this.setSampleIdViaPaymentStore(this.sample_id);
    this.setAccessionIdViaPaymentStore(this.accession_id);
    this.setChargeTypeId(this.charge_type_id);
    this.setChargeType(this.charge_type);
    this.setJwtToken(this.jwtToken);
    this.setConciergeNeeded(this.concierge_needed);
    this.setDirectToBloodDraw(this.direct_to_blooddraw);
    this.setFixedAmountToPay(this.amount_to_pay);
    this.setCount(this.count);
    this.setAlwaysShowPayment(this.always_show_payment);
    this.getAllPaymentInfo();
    // this.getBillingInfo()
    //   .then((billingInfo) => {
    //     this.getPaymentStatus().then((status) => {
    //       if (status == 4) {
    //         this.$router.push("/order_cancelled");
    //         return;
    //       }
    //       if (Number(billingInfo.total) == 0) {
    //         this.$router.push("/payment_succeed");
    //         return;
    //       }
    //       if (status == 0) {
    //         this.$router.push("/payment_form");
    //       } else {
    //         this.$router.push("/payment_status");
    //       }
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.$router.push("/payment_failed");
    //     localStorage.setItem("billingInfo", "");
    //   });
  },
  computed: {
    ...mapState(usePaymentStore, [
      "loadingPayment",
    ]),
  },
  methods: {
    ...mapActions(useCommonStore, ["setSampleId", "setAccessionId"]),
    ...mapActions(usePaymentStore, [
      "setPaymentSampleId",
      "setPaymentAccessionId",
      'setSampleIdViaPaymentStore',
      'setAccessionIdViaPaymentStore',
      "setChargeTypeId",
      "setChargeType",
      "setJwtToken",
      "getPaymentStatus",
      "setConciergeNeeded",
      "setDirectToBloodDraw",
      "setFixedAmountToPay",
      "setCount",
      'setAlwaysShowPayment',
    ]),
    async getAllPaymentInfo() {
      try {
        const paymentStatus = await this.getPaymentStatus();
        // if order is cancelled, push to order_cancelled page
        if (paymentStatus == 4) {
          this.$router.push("/order_cancelled");
        } else if (paymentStatus == 2) {
          this.$router.push("/payment_failed");
        } else if (paymentStatus == 1) {
          this.$router.push("/payment_pending");
        } else {
          this.$router.push("/payment_form");
        }
        // if order balance is 0
        // if (Number(results[1].total) == 0) {
        //   if (results[2].customer_id !== 999997) {
        //     this.$router.push("/payment_succeed");
        //   } else {
        //     if (results[2].chargeMethod == "patientPayLater") {
        //       this.$router.push("/payment_succeed");
        //     } else {
        //       const token = localStorage.getItem("pnsToken");
        //       const customerId = localStorage.getItem("customer_id");
        //       const sampleId = localStorage.getItem("sample_id");
        //       const accessionId = localStorage.getItem("accession_id");
        //       const bloodDrawNeeded = localStorage.getItem("blood_draw_needed");
        //       if (results[2].addressCheckNeeded) {
        //         console.log("addressCheckNeeded, push to address_check page");
        //         const redirectUrl = `https://pns-singlepage.pages.dev/uploadShippingAddress?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&bloodDrawNeeded=${bloodDrawNeeded}`;
        //         window.location.href = redirectUrl;
        //       } else {
        //         if (results[2].bloodDrawNeeded) {
        //           console.log("bloodDraw, push to blood_draw page");
        //           const redirectUrl = `https://pns.vibrant-wellness.com/bloodDraw?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}`;
        //           window.location.href = redirectUrl;
        //         } else {
        //           console.log("push to sample collection tutorial page");
        //           window.location.href = "https://pns.vibrant-wellness.com/collection/";
        //         }
        //       }
        //     }
        //   }
        // } else {
        //   if (results[0] == 0) {
        //     this.$router.push("/payment_form");
        //   } else {
        //     this.$router.push("/payment_status");
        //   }
        // }
      } catch (error) {
        console.error("failed to retrieve payment status", error);
        this.$router.push("/failed");
      }
    },
  },
};
</script>
