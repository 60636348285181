import { defineStore } from "pinia";
import { provideApolloClient, useMutation, useQuery } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import gql from 'graphql-tag'
import { ElNotification } from "element-plus";



export const useCancelAppointmentStore = defineStore( 'CancelAppointmentStore',{
    state:()=>{
        return {
            accession_id:null,
            sample_id:null,
            event_id:null,
            token:null,

            //want_to_cancel: indicate the status of user's operation
            //null: before doing any cancel operation
            //true: after selecting 'want to cancel'
            //false: after selecting 'don't want to cancel'
            want_to_cancel:null,
        }
    },
    actions:{
        storeAllQueryInfomation(query){
            this.accession_id = query.accession_id;
            this.sample_id = query.sample_id;
            this.event_id = Number(query.event_id);

            this.token = query.token;
            localStorage.setItem('graphql-token', this.token);
        },
        updateWantToCancel(val){
            this.want_to_cancel = val;
        },
        //graphQL api
        async cancelAppointmentAPI(){
            provideApolloClient(apolloClient);
            const DELETE_EVENT = 
            gql `
                mutation DeleteEvent($deleteEventData2: DeleteEventInput!) {
                    deleteEvent(data: $deleteEventData2) {
                        deleted_time
                        id
                    }
                }
            `;
            const {mutate: cancel_appointment_in_backend} = useMutation(
                DELETE_EVENT,
                {
                    context:{
                        headers:{
                            authorization: this.token ? `jwt ${this.token}` : '',
                        }
                    }
                }
            );
            try {
                const response = await cancel_appointment_in_backend(
                    {
                        "deleteEventData2": {
                            "event_id": this.event_id
                        }
                    }
                );
                // # NOTE: don't need to manually update, as Apollo Client is configured with cache:new InMemoryCache(),
                // InMemoryCache is designed to handle normalization and cache updates automatically. 
                // It ensures that when you mutate data, the cache is updated accordingly, and any queries that rely on that data are refreshed.
                // this.event_info = response.data.deleteEvent;
                console.log('response cancel appointment', response);
                // return Promise.resolve(true);
            } catch (err) {
                // Handle the error
                console.error("cancel appointment error:", err.message);
                ElNotification({
                    title: 'Cancel Appointment Failed',
                    type: 'error',
                    customClass: "errorNotification",
                    message: err.message,
                    duration: 0,
                });
                return Promise.reject(err);
            }
        }
    }
});