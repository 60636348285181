<template>
  <div class="questionnaireHeader">
    <div class="questionnaireTitle">Hormone Testing Questionnaire</div>
    <p>
      To ensure your report is accurate and complete, please complete this
      questionnaire thoroughly.
    </p>
    <div class="importantNote">
      <img
        class="ml-[20px] mr-[20px]"
        alt="Important Note"
        src="@/assets/questionnaire/round_warning_mark.svg"
      />
      <p>
        <b>Important note for Urine and Saliva Hormones Test:</b> If this
        questionnaire is not completed, your final report may be delayed or may
        not include vital reference ranges. Please complete this questionnaire thoroughly on the day of sample collection.
      </p>
    </div>
    <p>Thank you for choosing Vibrant for your health journey!</p>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireHeader",
};
</script>

<style scoped>
.questionnaireHeader {
  width: 56vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.questionnaireTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.175px;
  color: #0e4672;
  margin-bottom: 18px;
}

.questionnaireHeader p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.175px;
  color: #0e4672;
}

.importantNote {
  background: #faece6;
  border-radius: 4px;
  min-height: 65px;
  height: fit-content;
  margin: 18px 0;
  padding: 12px;
  display: flex;
  align-items: center;
}

.importantNote b {
  font-weight: 700;
}

@media only screen and (max-width: 1024px) {
  .questionnaireHeader {
    width: 90%;
  }

  .questionnaireTitle {
    font-size: 18px;
    margin-bottom: 6px;
  }

  .questionnaireHeader p {
    font-size: 12px;
  }

  .importantNote {
    min-height: 65px;
    margin: 12px 0;
    padding: 8px;
  }
}
</style>
