<template>
    <div id = "confirm-appointment-view" v-loading = "loading">
        <div class = "status-after-confirm-operation-view" v-if = "is_already_confirmed">
            <img src = "@/assets/ChangeShippingAddress/success_icon.svg" class = "view-status-icon"/>
            <div class = "bold-content" >Appointment Already Confirmed</div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "pinia";
import { useConfirmAppointmentStore } from "@/stores/ConfirmAppointmentStore.ts";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { useRoute } from 'vue-router';
import { ElNotification } from "element-plus";

import { useQuery } from "@vue/apollo-composable";
import gql from 'graphql-tag';

export default{
    setup(){
        //setup() in vue 3 doesn't have access of 'this'
        const route = useRoute();
        const CommonStore = useCommonStore();
        const ConfirmAppointmentStore = useConfirmAppointmentStore();

        CommonStore.setAccessionId(route.query.accession_id);
        CommonStore.setSampleId(route.query.sample_id);
        ConfirmAppointmentStore.storeAllQueryInfomation(route.query);
        //graphQL query:
        const GET_EVENT_BY_ID = 
        gql`
            query GetEventById($getEventByIdId: Int!) {
                getEventById(id: $getEventByIdId) {
                    id
                    status
                }
            }
        `;
        //named result as getEventByIdResult
        const {result:getEventByIdResult, loading, error:getEventByIdError} = useQuery(
            GET_EVENT_BY_ID, 
            {
                getEventByIdId: ConfirmAppointmentStore.event_id,
            },
            {
                context:{
                    headers:{
                        authorization: ConfirmAppointmentStore.token ? `jwt ${ConfirmAppointmentStore.token}` : '',
                    }
                }
            }
        )
        return {
            //result named getEventByIdResult will be automatically refetched after related mutate operation
            //since we configure ApolloClient with cache: new InMemoryCache(),
            getEventByIdResult, 
            loading, 
            getEventByIdError
        }
    },
    data(){
        return{
            is_already_confirmed:null,
            has_checked_initial_status:false,
        }
    },
    // computed:{
    //     event_info(){
    //         return this.getEventByIdResult?this.getEventByIdResult.getEventById:null;
    //     }
    // },
    watch:{
        getEventByIdResult(newVal){
            console.log('getEventByIdResult change', newVal);
            //store the initial status of this appointment: confirmed or not
            //begin checking when getEventByIdResult is not null
            if(!this.has_checked_initial_status && newVal){
                this.is_already_confirmed = newVal.getEventById.status == "confirmed";
                this.has_checked_initial_status = true;
            }
        },
        getEventByIdError(newVal){
            console.log('getEventById error watch', newVal);
            if(newVal){
                ElNotification({
                    title: 'Error',
                    type: 'error',
                    customClass: "errorNotification",
                    message: newVal.message,
                    duration: 0,
                });
            }
            this.$router.push('/confirmAppointment/failed');
        },
        async is_already_confirmed(newVal){
            //is_already_confirmed logically can only change once which is when firstly get event_info
            //represent the inital status of the current appointment
            //if not confirmed yet:
            if(!newVal){
                //call backend to confirm
                try{
                    this.loading = true;
                    await this.confirmAppointmentAPI();
                    this.loading = false;
                    this.$router.push('/confirmAppointment/success');
                }catch(err){
                    this.loading = false;
                    this.$router.push('/confirmAppointment/failed');
                }
            }
        }
    },
    methods:{
        ...mapActions(useConfirmAppointmentStore,[
            'storeAllQueryInfomation',
            'confirmAppointmentAPI'
        ]),
        ...mapActions(useCommonStore, [
            'setAccessionId',
            'setSampleId',
        ]),
    }
}
</script>

<style scoped>
#confirm-appointment-view{
    margin-top: 80px;
    padding:0 316px;
    min-height: 347px;
}
.status-after-confirm-operation-view{
    display:flex;
    flex-direction: column;
    align-items:center;
}
.bold-content{
    margin-top: 62px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.status-after-confirm-operation-view .regular-content{
    margin-top: 36px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}
@media only screen and (max-width: 1260px) {
    #confirm-appointment-view{
        margin-top: 80px;
        padding:0 100px;
    }
}
@media only screen and (max-width: 767px) {
    #confirm-appointment-view{
        margin-top: 49px;
        padding-left:28px;
        padding-right:27px;
        min-height: 216px;
    }

    .bold-content{
        margin-top: 36px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
    }
    .view-status-icon{
        height:119px;
        width:119px;
    }
    .status-after-confirm-operation-view .regular-content{
        margin-top: 24px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}
</style>