<template>
    <el-dialog
        v-model="visible"
        width="56%"
        destroy-on-close
    >
        <template #header="{close}">
            <div class="my-header">
                <img src = "@/assets/BloodDraw/close_icon.svg" @click="close" class = "close-icon"/>
            </div>
        </template>
        <div class = 'send-to-my-phone-dialog-content'>
            <div class = "input-guidance-text">
                <span class = "regular">Please hold off on proceeding with this process</span>
                <span class = "bold"> until you get your Blood-Kit.</span>
            </div>
            <div class = "btns-container">
                <div class = 'cancel-btn' @click = "clickCancel">
                   Cancel
                </div>
                <div class = 'continue-btn' @click = "clickContinue">
                   Continue
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { mapState } from "pinia";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { useBloodDrawStore } from "@/stores/BloodDrawStore.ts";
import { isProd } from "@/components/bloodDraw/BloodDrawData/request";
import moment from "moment";
import { addBusinessDays } from "@/components/shippingAddress/shippingAddressData/dateFunctions";
export default{
    data(){
        return{
            visible:false,
        }
    },
    computed:{
        ...mapState(useCommonStore, ["sample_id", "accession_id"]),
        ...mapState(useBloodDrawStore, ['is_kit_delivered'])
    },
    methods:{
        clickCancel(){
            this.visible = false;
        },
        clickContinue(){
            this.visible = false;
            const zhang_jun_base_url = isProd ? 'https://phleb.vibrant-wellness.com/app/concierge-request/concierge':'https://phleb.vibrant-wellness.com/app/staging/concierge-request/concierge';
            var url = `${zhang_jun_base_url}/${this.accession_id}`;
            if(!this.is_kit_delivered){
                const three_business_days_later_milliseconds = addBusinessDays(moment(), 3).valueOf();
                url = `${url}?startDate=${three_business_days_later_milliseconds}`;
            }
            window.location.href = url;
            this.$matomo && this.$matomo.trackEvent('pns', 'blood-draw-page', 'pns-go-to-concierge', 1);
        },
    }
}

</script>

<style scoped>
.my-header{
    display:flex;
    justify-content:flex-end;
}
.close-icon{
    cursor:pointer;
}
.input-guidance-text{
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.458px;
}
.input-guidance-text .bold{
    font-weight: 700;
}
.input-guidance-text .regular{
    font-weight: 400;
}
@media only screen and (max-width: 1260px){
    .input-guidance-text{
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.458px;
    }
}
.btns-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.cancel-btn{
    margin-top:36px;
    width:25%;
    box-sizing: border-box;

    padding:12px 21px;
    border-radius: 4px;
    border: 1px solid #0E4672;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
}
.continue-btn{
    margin-top:36px;
    width:25%;
    box-sizing: border-box;

    padding:13px 21px;
    border-radius: 4px;
    background: #0E4672;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
}
@media only screen and (max-width: 500px){
    .btns-container{
        gap:15px;
    }
    .continue-btn,
    .cancel-btn{
        width:40%;
    }
}
</style>