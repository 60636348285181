<template>
    <div id = "page-header">
      <img :id="customized_logo_src?'customized_logo':'vibrant-logo'" alt="Vibrant Logo" :src = "customized_logo_src?customized_logo_src:default_vibrant_logo" />
      <div id = "sample-id-info">
        Accession ID: {{ this.accession_id == 'undefined' ? '' : this.accession_id}}
      </div>
    </div>
</template>

<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState} from "pinia";
import default_vibrant_logo from '@/assets/vibrantLogo.svg';
export default{
    props:{
        accession_id:String,
    },
    data(){
      return{
        default_vibrant_logo,
      }
    },
    computed:{
      ...mapState(usePaymentStore,[
        'customized_pns_logo',
        'provider_has_revenue',
      ]),
      currentPath() {
        console.log('currentPath',this.$route.path);
        return this.$route.path;
      },
      customized_logo_src(){
        return this.currentPath && this.currentPath.includes('/payment') && this.customized_pns_logo && this.provider_has_revenue
        ? 
        this.customized_pns_logo
        :
        "";
      }
    }
}
</script>

<style scoped>
#page-header{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top:26px;
  padding-left: 80px;
  padding-right: 100px;
  width: 100%;
  /* min-width: 1024px; */
}
#vibrant-logo{
  height:50px;
  width: 185px;
}
#customized_logo{
  height:50px;
}
#sample-id-info{
  color: #2E557B;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.515px;
}
@media only screen and (max-width: 767px) {
  #page-header{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 18px;
    padding-left: 28px;
    padding-right: 27px;
    width: 100%;
  }
  #vibrant-logo{
    height:30px;
    width: 110px;
  }
  #customized_logo{
    height:30px;
  }
  #sample-id-info{
    color: #2E557B;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.515px;
  }
}
</style>