const genderOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
];

const yesNoOptions = [
    {
        label: "Yes",
        value: true,
    },
    {
        label: "No",
        value: false,
    },
];

const hysterectomyOptions = [
    {
        label: "Yes- Total",
        value: "TOTAL_HYSTERECTOMY",
    },
    {
        label: "Yes- Partial",
        value: "PARTIAL_HYSTERECTOMY",
    },
    {
        label: "No",
        value: "NO",
    },
];

const ovariesRemovedOptions = [
    {
        label: "Both Ovaries",
        value: "BOTH",
    },
    {
        label: "One Ovary",
        value: "ONE",
    },
    {
        label: "None",
        value: "NO",
    },
];

const menstrualCyclePhaseOptions = [
    {
        label: "Luteal Phase (Days 15 – 28)",
        value: "LUTEAL",
    },
    {
        label: "Follicular Phase (Days 6 – 14)",
        value: "FOLLICULAR",
    },
    {
        label: "Ovulatory Phase (Days 14 – 15) ",
        value: "OVULATORY",
    },
    {
        label: "Pre-puberty (has not had 1st period) ",
        value: "PRE_PUBERTY",
    },
    {
        label: "Unable to select Phase due to irregular cycle(s), for less than 1 year, for any reason",
        value: "UNABLE_TO_SELECT",
    },
    {
        label: "No menstrual cycles for greater than 1 year, for any reason",
        value: "NO_MENSTRUAL_CYCLES",
    },
];


export {
    genderOptions,
    yesNoOptions,
    hysterectomyOptions,
    ovariesRemovedOptions,
    menstrualCyclePhaseOptions,
}
