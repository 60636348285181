<template>
    <div class = 'input-unit'>
        <div :class = "['input-name-div',{'required-input':required}]">
            {{ input_display_name }} <span v-if = "input_display_note" class = "input-note" v-html = "input_display_note"></span>
        </div>
        <div :class = "[
            'input-wrapper',
            {'error-highlighted':has_po_box_error}
        ]">
            <el-input v-if = "input_type == 'input'" 
            :model-value = "modelValue" @input = "inputContentChagne" :disabled = "disabled" />
            <el-input v-if = "input_type=='date'" 
            :model-value = "modelValue" 
            type='date' 
            @input = "inputContentChagne" 
            :disabled = "disabled" />
            <el-select v-if = "input_type =='selector'"
            popper-class = 'selector-dropdown'
            fit-input-width
            :modelValue = 'modelValue' @change = 'inputContentChagne'
            :disabled = "disabled" >
                <el-option
                v-for="item in dropdown_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
            <component v-if = "input_type == 'customized_input' && event!=null && event.emit_event!=null && event.triggered_parent_event!=null"
            :is = "customized_component" 
            :value = "modelValue"
            v-bind="customized_props"
            @[event.emit_event]= "(selected_option, type)=>this[event.triggered_parent_event](selected_option,type)" />
<!-- @selected-option-change = "(description)=>this[event.triggered_parent_event](description)" -->
            <!-- @selected-option-change = "(description)=>triggerCorrespondingMethod(description, event.triggered_parent_event)" -->
            <div v-if = "customized_component=='AddressAutoFilledInput'"
            ref = "google-autocomplete-dummy-map"></div>
            <div v-if = "has_po_box_error" class = "error-text">
                We DO NOT ship kits to P.O Boxes
            </div>
        </div> 
    </div>
</template>

<script>
// import all possible needed input component
import AddressAutoFilledInput from '@/components/shippingAddress/AddressAutoFilledInput.vue';
import { mapActions } from 'pinia';
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
export default{
    components:{
        AddressAutoFilledInput,
    },
    props:{
        modelValue:String,
        input_id:{
            type:String,
            default:''
        },
        input_display_name:{
            type:String,
            default:''
        },
        input_display_note:{
            type:String,
            default:''
        },
        required:{
            type:Boolean,
            default:false,
        },
        disabled:{
            type:Boolean,
            default:false,
        },
        input_type:{
            type:String,
            default:'input',
        },
        dropdown_options:{
            type:Array,
            default:()=>[]
        },
        // can_create_new_item:{
        //     type:Boolean,
        //     default:false,
        // },
        customized_component:{
            type:String,
            default:'',
        },
        customized_props:{
            type:Object,
            default:()=>{},
        },
        event:{
            type:Object,
            default:()=>{}
        },
    },
    mounted(){
        //store required input fields in store
        if(this.required){
            this.$emit('add-required-input-field', this.input_id);
            // this.addRequiredInputFields(this.input_id);
        }
    },
    data(){
        return{
            has_po_box_error:false,
            google_place_details_to_adrress_dict:{
                street_number:'address',
                route:'address',
                subpremise:'address',
                administrative_area_level_2:'address', //-> county
                locality: 'city',
                administrative_area_level_1: 'state',
                country:'country',
                postal_code:'zipcode',
                postal_code_suffix:'zip_code_suffix',
                postal_code_prefix: 'zip_code_prefix',
            },
            address_input_hierarchy:[
                {
                    id:'street_number',
                },
                {
                    id:'route',
                },
                {
                    id:'subpremise'
                },
                {
                    id:'administrative_area_level_2',
                    //optional means, only concate this field when the previous ones are all empty.
                    optional: true, 
                }
            ],
        }
    },
    watch:{
        modelValue(newVal){
            console.log('modelValue:',this.input_id, newVal);
        },
        required(newVal){
            if(newVal){
                this.$emit('add-required-input-field', this.input_id);
            }else{
                this.$emit('remove-required-input-field', this.input_id);
            }
        },
    },
    emits:[
        'update:modelValue', 
        'add-required-input-field',
        'remove-required-input-field',
    ],
    methods:{
        ...mapActions(useShippingAddressStore,[
            'updateCurrentShippingAddress',
            'addRequiredInputFields',
        ]),
        inputContentChagne(cur_content){
            // console.log('inputcontentchange', cur_content);
            this.$emit('update:modelValue', cur_content);
        },
        async autofillAdressComponentSelectedOptionChange(selected_option, type){
            console.log('autofillAdressComponentSelectedOptionChange', selected_option);
            if(type == 'self-input'){
                if(!this.isPoBoxError(selected_option)){
                    console.log('not has po box error');
                    this.has_po_box_error = false;
                    this.$emit('update:modelValue', selected_option);
                }else{
                    console.log(' has po box error');
                    this.has_po_box_error = true;
                    this.$emit('update:modelValue', '');
                }
                
            }else if (type == 'google-place-id'){
                const { PlacesService, PlacesServiceStatus } = await window.google.maps.importLibrary("places");
                const placServiceInstance = new PlacesService(this.$refs['google-autocomplete-dummy-map']);
                placServiceInstance.getDetails(
                    {
                        placeId:selected_option,
                    },
                    (result,status)=>{
                        if(status === PlacesServiceStatus.OK){
                            const output = this.filterGooglePlaceDetails(result.address_components, this.google_place_details_to_adrress_dict);
                            //get value to be put in address/street input
                            var filteredAddress = this.concatAddressInfo(output, this.address_input_hierarchy);
                            if(!this.isPoBoxError(filteredAddress)){
                                this.has_po_box_error = false;
                                this.$emit('update:modelValue', filteredAddress);
                                
                                //autofill other related fields: state, country, zip_code, city
                                this.updateCurrentShippingAddress(this.google_place_details_to_adrress_dict['locality'], output['locality']);
                                this.updateCurrentShippingAddress(this.google_place_details_to_adrress_dict['administrative_area_level_1'], output['administrative_area_level_1']);
                                this.updateCurrentShippingAddress(this.google_place_details_to_adrress_dict['country'], output['country']);
                                this.updateCurrentShippingAddress(this.google_place_details_to_adrress_dict['postal_code'], output['postal_code']);

                                // console.log('find place detailed info', result.address_components);
                            }else{
                                this.has_po_box_error = true;
                                this.$emit('update:modelValue', '');
                            }
        
                        }else{
                            console.log('google find places error:', status);
                        }
                    }
                );
                
            }
        },
        filterGooglePlaceDetails(address_components, dict){
            const output = {};
            for(const component of address_components){
                for(const type of component.types){
                    if(dict[type]){
                        output[type] = component.short_name;
                        break;
                    }
                }
            }
            //cancel postal code suffix and prefix
            // if(output.postal_code_suffix!=null){
            //     output.postal_code = `${output.postal_code}-${output.postal_code_suffix}`;
            // }
            // if(output.postal_code_prefix!=null){
            //     output.postal_code = output.postal_code_prefix + '-' + output.postal_code;
            // }
            return output;
        },
        concatAddressInfo(info, hierarchy){
            var filteredAddress = '';
            for(const level_info of hierarchy){
                if(info[level_info.id] && !level_info.optional){
                    filteredAddress += filteredAddress==''?info[level_info.id]:` ${info[level_info.id]}`;
                }else if(filteredAddress == '' && info[level_info.id] && level_info.optional){
                    filteredAddress += info[level_info.id];
                }
            }
            return filteredAddress;
        },
        isPoBoxError(streetAddress) {
            // console.log('test PO box error', streetAddress);
            const regex =  /.*(P\.?O\.?\s+Box|Post\s+Office\s+Box)\s+((#|number|num)\s*)?\d+/igm;
            if (streetAddress && streetAddress.match(regex)) {
                return true;
            }
            return false
           
        },
    }
}
</script>

<style scoped>
.input-unit{
    width:100%;
    display:flex;
    flex-direction:column;
    gap:6px;
}
.input-name-div{
    color: #000;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.input-note{
    color:#BCCCDC;
}
.input-name-div.required-input:after{
    content:' *';
    color: #FF1D00;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.error-text{
    margin-top: 4px;
    color: #DF2B25;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
}
@media only screen and (max-width:767px){
    .input-unit{
        width:100%;
        display:flex;
        flex-direction:column;
        gap:4px;
    }
    .input-name-div{
        color: #000;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.175px;
    }
    .input-name-div.required-input:after{
        content:' *';
        color: #FF1D00;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.175px;
    }
    .error-text{
        margin-top: 4px;
        color: #DF2B25;
        font-family: 'Roboto';
        font-size:  12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.3px;
    }

}
</style>