<template>
  <div class="billingContent" v-if="showAllBillingInfo">
    <PaymentInput
      content="First Name"
      :isRequired="true"
      width="half"
      @onChange="setBillingFirstName"
    />
    <PaymentInput
      content="Last Name"
      :isRequired="true"
      width="half"
      @onChange="setBillingLastName"
    />
  </div>
  <div class="billingContent" v-if="showAllBillingInfo">
    <PaymentInput
      content="Address Line"
      :isRequired="true"
      width="full"
      @onChange="setBillingAddress"
    />
  </div>
  <div class="billingContent" v-if="showAllBillingInfo">
    <PaymentInput
      content="Address Line 2"
      :isRequired="false"
      width="full"
      @onChange="setBillingAddress2"
    />
  </div>
  <div class="billingContent" v-if="showAllBillingInfo">
    <PaymentInput
      content="Zip Code"
      :isRequired="true"
      width="half"
      @onChange="setBillingZipCode"
    />
    <PaymentInput
      content="City"
      :isRequired="true"
      width="half"
      @onChange="setBillingCity"
    />
  </div>
  <div class="billingContent">
    <PaymentSelect
      content="Country"
      :isRequired="true"
      width="half"
      :options="countryList"
      @onChange="setBillingCountry"
    />
    <PaymentSelect
      v-if="this.billing_country == 'US'"
      content="State"
      :isRequired="true"
      width="half"
      :options="stateList"
      @onChange="setBillingState"
    />
    <PaymentInput
      v-else
      content="State"
      :isRequired="true"
      width="half"
      @onChange="setBillingState"
    />
  </div>
</template>

<script>
import PaymentInput from "@/components/payment/PaymentInput.vue";
import PaymentSelect from "@/components/payment/PaymentSelect.vue";
import countryList from "@/utils/countryList.js";
import stateList from "@/utils/us_states.js";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState, mapActions } from "pinia";
export default {
  name: "BillingInfo",
  components: {
    PaymentInput,
    PaymentSelect,
  },
  props: {
    showAllBillingInfo: {
      type: Boolean,
    },
  },
  data() {
    return {
      countryList,
      stateList,
    };
  },
  created() {
    console.log("BillingInfo created");
    this.setBillingFirstName("");
    this.setBillingLastName("");
    this.setBillingAddress("");
    this.setBillingAddress2("");
    this.setBillingZipCode("");
    this.setBillingCity("");
    this.setBillingCountry("");
    this.setBillingState("");
  },
  computed: {
    ...mapState(usePaymentStore, ["billing_country"]),
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "setBillingFirstName",
      "setBillingLastName",
      "setBillingAddress",
      "setBillingAddress2",
      "setBillingZipCode",
      "setBillingCity",
      "setBillingCountry",
      "setBillingState",
    ]),
  },
};
</script>

<style scoped>
.billingContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

@media only screen and (max-width: 767px) {
  .billingContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
