<template>
  <div class="addBtn" @click="showDialog = true">
    + Add Hormones / Medications
  </div>
  <el-dialog
    v-model="showDialog"
    center
    title="Add Hormones / Medications"
    modal-class="hormoneDialog"
  >
    <div class="flex flex-col">
      <div class="medicationFormLine">
        <div class="medicationLabel">Hormone Type<span>*</span></div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.hormoneType"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Brand</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.brand"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Delivery</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.delivery"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Dosage</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.dosage"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Last Used</div>
        <el-input
          class="medicationInput"
          placeholder="YYYY-MM-DD"
          v-model="this.medicationRecords.lastUsed"
        />
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">Times Per Day</div>
        <el-input
          class="medicationInput"
          v-model="this.medicationRecords.timesPerDay"
        >
          <template #suffix>
            <span>Times</span>
          </template>
        </el-input>
      </div>
      <div class="medicationFormLine">
        <div class="medicationLabel">How Long Used</div>
        <div class="howLongUsedContainer">
          <el-input
            class="howLongUsedInput pl-[25px]"
            v-model="this.medicationRecords.usedYears"
          >
            <template #suffix>
              <span>Years</span>
            </template>
          </el-input>
          <el-input
            class="howLongUsedInput"
            v-model="this.medicationRecords.usedMonths"
          >
            <template #suffix>
              <span>Months</span>
            </template>
          </el-input>
          <el-input
            class="howLongUsedInput"
            v-model="this.medicationRecords.usedDays"
          >
            <template #suffix>
              <span>Days</span>
            </template>
          </el-input>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="cancelBtn" @click="this.closeHormoneDialog"
          >Cancel</el-button
        >
        <el-button
          class="submitBtn"
          @click="this.confirmUpdateMedicationRecords"
          :disabled="!this.medicationRecords.hormoneType"
        >
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "AddHormoneDialog",
  data() {
    return {
      showDialog: false,
      medicationRecords: {
        hormoneType: "",
        brand: "",
        delivery: "",
        dosage: "",
        lastUsed: "",
        times: "",
        usedYears: "",
        usedMonths: "",
        usedDays: "",
      },
    };
  },
  emits: ["addToMedicationHistory"],
  watch: {
    "medicationRecords.lastUsed": function (newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal.length === 0) return;
      if (newVal.length > 10) {
        this.medicationRecords.lastUsed = oldVal;
        return;
      }
      let curInput = newVal[newVal.length - 1];
      const regex = /[0-9]/;
      // delete user current input if it is not a number (need deal with '/')
      if (!curInput.match(regex)) {
        if (curInput === "-") {
          if (newVal.length !== 5 && newVal.length !== 8) {
            this.medicationRecords.lastUsed =
              this.medicationRecords.lastUsed.slice(0, -1);
          }
        } else {
          this.medicationRecords.lastUsed =
            this.medicationRecords.lastUsed.slice(0, -1);
        }
      } else {
        // autofill the "/" between month&year
        if (oldVal.length === 3 && newVal.length === 4)
          this.medicationRecords.lastUsed += "-";
        if (oldVal.length === 6 && newVal.length === 7)
          this.medicationRecords.lastUsed += "-";
      }
    },
  },
  methods: {
    confirmUpdateMedicationRecords() {
      this.$emit("addToMedicationHistory", this.medicationRecords);
      this.closeHormoneDialog();
    },
    closeHormoneDialog() {
      this.medicationRecords = {
        hormoneType: "",
        brand: "",
        delivery: "",
        dosage: "",
        lastUsed: "",
        timesPerDay: "",
        usedYears: "",
        usedMonths: "",
        usedDays: "",
      };
      this.showDialog = false;
    },
  },
};
</script>

<style scoped>
.addBtn {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  text-align: left;
  color: #20a8c3;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 12px;
}

.medicationFormLine {
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: space-between;
  width: 600px;
}

.medicationLabel {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.17px;
  text-align: left;
}

.medicationLabel span {
  color: #ff1d00;
  margin-left: 4px;
}

.medicationInput {
  width: 400px;
  height: 42px;
  margin-left: 20px;
}

:deep(.el-dialog__title) {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.175px;
  text-align: center;
  color: #0e4672;
}

:deep(.el-input__suffix) {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #0e4672;
  margin-right: 20px;
}

.howLongUsedContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  width: 400px;
  height: 42px;
  margin-left: 20px;
}

.howLongUsedInput {
  height: 30px;
}

.el-button.submitBtn.is-disabled {
  background: #f3f3f3 !important;
  border: 1px solid #f3f3f3 !important;
  color: #bbbbbb !important;
}

@media only screen and (max-width: 1024px) {
  .addBtn {
    font-size: 14px;
  }
}

@media only screen and (max-width: 808px) {
  .medicationFormLine {
    flex-direction: column;
    width: 90vw;
  }

  .medicationInput {
    width: 80vw;
    margin-left: 0px;
  }

  :deep(.el-input__suffix) {
    font-size: 12px;
  }

  .howLongUsedContainer {
    width: 80vw;
    margin-left: 0px;
  }

  .medicationLabel {
    font-size: 12px !important;
    width: 90% !important;
  }

  .el-input {
    font-size: 12px;
  }

  .cancelBtn,
  .submitBtn {
    width: 30vw !important;
    font-size: 12px !important;
    max-width: 166px;
  }
}
</style>
