<template>
    <div :class = "['embeded-map',{'no-location-bg':!hasLocationInfo}]">
        <div v-if = "!hasLocationInfo" class ='no-location-notice-wrapper'>
            <span class = "no-location-notice-text">Current map unavailable</span>
        </div>
        <div ref = "google-map-wrapper" :class = "['google-map',{'empty-div':!hasLocationInfo}]"></div>
    </div>
</template>

<script>
import { useBloodDrawStore } from '@/stores/BloodDrawStore.ts';
import { mapState } from 'pinia';
import map_marker from '@/assets/BloodDraw/map_marker.svg';
import mobile_map_marker from '@/assets/BloodDraw/mobile_map_marker.svg';
import tooltip_address_icon from '@/assets/BloodDraw/map_marker_tooltip_address_icon.svg';

export default{
    mounted(){
        this.initMap();
    },
    data(){
        return{
            is_popup_open:true,
        }
    },
    computed:{
        ...mapState(useBloodDrawStore,[
            'geo_info_of_selected_location',
            'location_info_of_selected_location',
        ]),
        hasLocationInfo(){
            return this.geo_info_of_selected_location 
            && this.geo_info_of_selected_location.coordinates 
            && this.geo_info_of_selected_location.coordinates.length>0;
        }
    },
    watch:{
        geo_info_of_selected_location(newVal){
            console.log('geo_info_of_selected_location', newVal);
            this.initMap();
        }
    },
    methods:{
        async initMap(){
            if(this.hasLocationInfo){
                if(this.geo_info_of_selected_location.type == "Point"){
                    this.showSingleLocation();
                }else if(this.geo_info_of_selected_location.type == "Polygon" || this.geo_info_of_selected_location.type == "MultiPolygon"){
                    const all_polygons_data = this.geo_info_of_selected_location.coordinates;
                    const polygon_and_polyline_objects = [];
                    const {LatLngBounds} = await window.google.maps.importLibrary("core");
                    // Create an empty bounds object to encompass all polygons
                    //possible has multiple polygons far away from each other
                    const allPolygonsBounds = new LatLngBounds();
                    // dashed border unit of polygon
                    const lineSymbol = {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        strokeWeight: 2.5,
                        // fillColor: '#20A8C3',
                        // fillOpacity:1,
                        strokeColor: '#20A8C3',
                        scale: 2,
                    };
                    // recursive function
                    await this.createPolygons(all_polygons_data, polygon_and_polyline_objects, allPolygonsBounds, lineSymbol);
                    const polygonBounds = new window.google.maps.LatLngBounds();
                    // choose the first polygon in the array as center -> Extend bounds for each point in the polygon
                    polygon_and_polyline_objects[0].polygon.getPath().forEach((point) => {
                        polygonBounds.extend(point);
                    });
                    const centerLat = (polygonBounds.getNorthEast().lat() + polygonBounds.getSouthWest().lat()) / 2;
                    const centerLng = (polygonBounds.getNorthEast().lng() + polygonBounds.getSouthWest().lng()) / 2;
                    // // use all polygons to locate the center
                    // const centerLat = (allPolygonsBounds.getNorthEast().lat() + allPolygonsBounds.getSouthWest().lat()) / 2;
                    // const centerLng = (allPolygonsBounds.getNorthEast().lng() + allPolygonsBounds.getSouthWest().lng()) / 2;
            
                    console.log('map info', polygon_and_polyline_objects, centerLat, centerLng);
                    this.drawMultiPolygons(polygon_and_polyline_objects, centerLat, centerLng);
                }
            }else{
                this.$refs['google-map-wrapper'].innerHTML = '';
            }

            // else if(this.geo_info_of_selected_location.type == "Polygon"){
            //         const converted_coordinates = this.convertCoordinates(this.geo_info_of_selected_location.coordinates[0]);
            //         this.showPolygonRange(converted_coordinates);
            //     }

        },
        async showSingleLocation(){
            const mapMarker = window.innerWidth < 768? mobile_map_marker:map_marker;
            const { Map } = await window.google.maps.importLibrary("maps");
            const map = new Map(this.$refs['google-map-wrapper'], {
                center: { lat:this.geo_info_of_selected_location.coordinates[1], lng: this.geo_info_of_selected_location.coordinates[0] },
                zoom: 18,
            });
            const marker = new window.google.maps.Marker({
                position: { lat:this.geo_info_of_selected_location.coordinates[1], lng: this.geo_info_of_selected_location.coordinates[0] },
                icon: mapMarker ,
                map: map,
            });
            const contentString = `<div class = 'map-marker-tooltip'">
                    <div class = 'tooltip-location-name'>${this.location_info_of_selected_location.location_name}</div>
                    <div class = 'tooltip-address'>
                        <img src = '${tooltip_address_icon}' class = 'tooltip-address-icon' />
                        <div>${this.location_info_of_selected_location.address}</div>
                    </div>
                </div>`;

            // Create an InfoWindow instance with the custom content
            const infoWindow = new window.google.maps.InfoWindow({
                content: contentString,
            });
            infoWindow.open(map, marker);
            // Add a click event listener to the marker to open the tooltip when clicked
            marker.addListener('click', () => {
                this.is_popup_open = !this.is_popup_open;
                if(this.is_popup_open){
                    infoWindow.open(map, marker);
                }else{
                    infoWindow.close();
                }
            });
            infoWindow.addListener('closeclick', ()=>{
                // Handle focus manually.
                // console.log('infoWindow closeclick');
                this.is_popup_open = false;
            });
        },
        convertCoordinates(points){
            const output = [];
            for(const point of points){
                output.push({lat:point[1],lng:point[0]});
            }
            return output;
        },
        // async showPolygonRange(points){
        //     const {Map, Polygon, Polyline} = await window.google.maps.importLibrary("maps");

        //     // construct polygon range
        //     const polygon = new Polygon({
        //         paths: points,
        //         fillColor: "#20A8C3",
        //         fillOpacity: 0.1,
        //         // strokeColor: '#20A8C3',
        //         strokeWeight: 0,
        //         strokeOpacity:0,
                
        //     });

        //     //get map's center via polygon
        //     const polygonBounds = new window.google.maps.LatLngBounds();
        //     polygon.getPath().forEach((point) => {
        //         polygonBounds.extend(point);
        //     });

        //     // Calculate the center of the polygon's bounds 
        //     // if there are multiple polygons far away from each other, the center might no in the range
        //     const centerLat = (polygonBounds.getNorthEast().lat() + polygonBounds.getSouthWest().lat()) / 2;
        //     const centerLng = (polygonBounds.getNorthEast().lng() + polygonBounds.getSouthWest().lng()) / 2;

        //     // Set the map's center to the calculated center
        //     const map = new Map(this.$refs['google-map-wrapper'], {
        //         center:{lat:centerLat,lng:centerLng},
        //         zoom: 8,
        //     });
        //     polygon.setMap(map);

        //     // dashed border of polygon
        //     const lineSymbol = {
        //         path: "M 0,-1 0,1",
        //         strokeOpacity: 1,
        //         strokeWeight: 2,
        //         // fillColor: '#20A8C3',
        //         // fillOpacity:1,
        //         strokeColor: '#20A8C3',
        //         scale: 2,
        //     };
        //     points.push(points[0]);
        //     new Polyline({
        //         path: points,
        //         strokeOpacity: 0,
        //         strokeWeight: 0,
        //         icons: [{
        //             icon: lineSymbol,
        //             offset: '0',
        //             repeat: '10px'
        //         }],
        //         map:map,
        //     });
        // },
        async createPolygons(all_polygons_data, polygon_and_polyline_objects, allPolygonsBounds, lineSymbol){
            if(typeof all_polygons_data[0][0] == 'number'){
                const {Polygon, Polyline} = await window.google.maps.importLibrary("maps");

                // console.log('createMultiPolygons', all_polygons_data);
                const converted_coordinates = this.convertCoordinates(all_polygons_data);
                // console.log('createMultiPolygons', converted_coordinates);
                // construct polygon range
                const polygon = new Polygon({
                    paths: converted_coordinates,
                    fillColor: "#20A8C3",
                    fillOpacity: 0.2,
                    // strokeColor: '#20A8C3',
                    strokeWeight: 0,
                    strokeOpacity:0,
                    
                });
                // union current polygon with others
                const polygonBounds = new window.google.maps.LatLngBounds();
                // Extend bounds for each point in the polygon
                polygon.getPath().forEach((point) => {
                    polygonBounds.extend(point);
                });

                // Extend the overall bounds to include the bounds of the current polygon
                allPolygonsBounds.union(polygonBounds);

                // dashed border of polygon
                converted_coordinates.push(converted_coordinates[0]);
                const polyline = new Polyline({
                    path: converted_coordinates,
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    icons: [{
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px'
                    }],
                });
                polygon_and_polyline_objects.push({polygon: polygon, polyline:polyline}); 
            }else{
                for(const child_unit of all_polygons_data){
                    this.createPolygons(child_unit, polygon_and_polyline_objects, allPolygonsBounds, lineSymbol);
                }
            }
            
        },
        async drawMultiPolygons(polygon_and_polyline_objects, centerLat, centerLng){
            const { Map } = await window.google.maps.importLibrary("maps");
            const map = new Map(this.$refs['google-map-wrapper'], {
                center: { lat: centerLat, lng: centerLng},
                zoom: 10,
            });
            // console.log('polygon_and_polyline_objects', polygon_and_polyline_objects, centerLat, centerLng);
            for(const {polygon, polyline} of polygon_and_polyline_objects){
                polygon.setMap(map);
                polyline.setMap(map);
            }
        }

    }
}
</script>

<style scoped>
.embeded-map{
    width:100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
}
.google-map{
    width:100%;
    height:100%;
    border-radius:inherit;
}
.empty-div{
    width:0px;
    height:0px;
}
.no-location-bg{
    background-image: url('@/assets/BloodDraw/static_map.svg');
    background-position: center;
    background-size: cover;
}
.no-location-bg::after{
    content: url('@/assets/BloodDraw/static_map_zoom_icon.svg');
    position:absolute;
    top:21px;
    right:22px;
    z-index: 0;
}
.no-location-notice-wrapper{
    border-radius: inherit;
    background: rgba(217, 217, 217, 0.90);
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
}
.no-location-notice-text{
    color: #5F6368;
    font-family:'Roboto';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
}

/* @media only screen and (max-width: 767px){
    
} */
</style>