<template>
    <div class = 'collect-status-view' v-loading = 'loading'>
        <div v-if = "status == 'ALREADY_COLLECTED'" class = 'already-collected'>
            <img src = "@/assets/ChangeShippingAddress/success_icon.svg" />
            <div class = "notice-text">Dear Customer, you just chose <span class = 'bold'>" Yes, I've Collected My Sample "</span>. Thanks for your cooperation!</div>
        </div>
        <div v-if = "status == 'NOT_COLLECTED'" class = 'not-collected'>
            <img src = "@/assets/CollectStatus/not_collected_icon.svg" />
            <div class = "notice-text">Dear Customer, you just chose <span class = 'bold'>" No, I Haven't Collected My Sample "</span>. Please check here for collection tutorial. Thanks for your cooperation!</div>
            <div class = 'redirect-btns'>
                <div class = 'go-to-blood-draw' @click = "clickBloodDrawMap">Blood Draw Map</div>
                <div class = 'non-blood-sample-tutorial' @click = "clickNonBloodSampleTutorial">Non-Blood Sample Collection Tutorial</div>
            </div>
        </div>
        <div v-if = "status == 'NOT_RECEIVED'" class = 'not-received'>
            <img src = "@/assets/CollectStatus/not_received_icon.svg" />
            <div class = "notice-text">Dear Customer, you just chose <span class = 'bold'>" I Haven't Received My Sample "</span>. Please <span class = "link" @click = 'contactSupportTeam'>contact our support team</span> for help. Thanks for your cooperation!</div>
        </div>
        <div v-if = "status == 'ERROR'" class = 'not-received'>
            <img src = "@/assets/ChangeShippingAddress/failed_icon.svg" />
            <div class = "notice-text">Oops, something went wrong. Please try again later, thanks!</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState} from 'pinia';
import { useCommonStore } from "@/stores/CommonStore.ts";
import { baseURL } from '@/components/bloodDraw/BloodDrawData/request.js';
export default{
    created(){
        this.loading = true;
        this.setAccessionId(this.$route.query.accession_id);
        this.setSampleId(this.$route.query.sample_id);
        const params = {
            sample_id:this.$route.query.sample_id,
            status:this.$route.query.status,
        }
        //call api to store status for this sample
        baseURL.put('/utility/updateCollectionStatus', params, {
            headers:{
                Authorization: `Bearer ${this.$route.query.token}`,
            }
        }).then(()=>{
            this.status = this.$route.query.status;
            this.loading = false;
        }).catch((err)=>{
            console.log('updateCollectionStatus api err:', err);
            this.status = 'ERROR';
            this.loading = false;
        })
    },
    data(){
        return{
            status:'',
            loading:false,
        }
    },
    computed:{
        ...mapState(useCommonStore,[
            'accession_id',
            'sample_id',
        ])
    },
    methods:{
        ...mapActions(useCommonStore,[
            'setAccessionId',
            'setSampleId',
        ]),
        contactSupportTeam(){
            window.open('https://www.vibrant-wellness.com/Contact', '_blank');
        },
        clickNonBloodSampleTutorial(){
            window.open('https://www.vibrant-wellness.com/collections', '_blank');
        },
        clickBloodDrawMap(){
            window.open(`https://pns-singlepage.pages.dev/bloodDraw?customer_id=${this.$route.query.customer_id}&token=${this.$route.query.token}&sample_id=${this.sample_id}&accession_id=${this.accession_id}`, '_blank');
        }
    }
}
</script>

<style scoped>
.collect-status-view{
    margin-top:80px;
    padding:0 316px;
    display:flex;
    justify-content:center;
    width:100%;
}
.not-received,
.already-collected,
.not-collected{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
}

.notice-text{
    margin-top: 62px;
    text-align:center;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    width:100%;
}
.notice-text .bold{
    font-weight: 700;
}
.notice-text .link{
    color: #20A8C3;
    text-decoration: underline;
    cursor:pointer;
}

.redirect-btns{
    margin-top:46px;
    display:flex;
    align-items:center;
    gap:18px;
    width:100%;
}
.go-to-blood-draw,
.non-blood-sample-tutorial{
    padding-top:12px;
    padding-bottom:11px;
    border-radius: 4px;
    background: #0E4672;
    width:calc(100% - 18px);

    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor:pointer;
}
@media only screen and (max-width:1260px){
    .collect-status-view{
        margin-top:80px;
        padding:0 58px;
        display:flex;
        justify-content:center;
    }
}

@media only screen and (max-width:767px){
    .collect-status-view{
        margin-top:49px;
        padding-left:27px;
        padding-right:28px;
        display:flex;
        justify-content:center;
    }
    .collect-status-view img{
        width:120px;
        height:120px;
    }
    .notice-text{
        margin-top: 36px;
        text-align:center;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        width:100%;
    }
    .redirect-btns{
        margin-top:24px;
        display:flex;
        align-items:center;
        flex-direction:column;
        row-gap:12px;
        width:100%;
    }
    .go-to-blood-draw,
    .non-blood-sample-tutorial{
        width:100%;
        font-size:14px;
    }
}
</style>