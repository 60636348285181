<template>
  <div id="page-container">
    <div class="pageBodyContainer">
      <img
        class="paymentStatusIcon"
        src="@/assets/payment/success.svg"
        alt="payment successful"
      />
      <div class="paymentStatusTitle">Completed!</div>
      <div class="paymentStatusInfo">
        Thank you for your information, we are preparing your final report.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireCompleted",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  position: relative;
}

.paymentStatusInfo {
  margin-bottom: 46px !important;
}

@media only screen and (max-width: 1024px) {
  .paymentStatusInfo {
    margin-bottom: 36px !important;
  }
}
</style>
