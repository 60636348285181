<template>
    <div id = "cancel-appointment-view" v-loading = "loading">
        <!-- appointment isn't canceled, haven't click No/Yes button (no cancellation happened before) -->
        <div class = "cancel-operation-view" v-if = "!is_appointment_deleted && want_to_cancel==null">
            <img src = "@/assets/CancelAppointment/pending_icon.svg" class = "view-status-icon"/>
            <div class = "bold-content" >Are you sure to cancel your appointment?</div>
            <div class = "ops-buttons">
                <div class = 'next-step-notice filled clickable' @click = "handleClickNo()" >No</div>
                <div class = 'next-step-notice outlined clickable' @click = "handleClickCancelAppointment()">Yes</div>
            </div>
        </div>

        <!-- appointment is canceled, haven't click No/Yes button (canceled before) -->
        <div class = "status-after-cancel-operation-view" v-if = "is_appointment_deleted && want_to_cancel==null">
            <img src = "@/assets/ChangeShippingAddress/success_icon.svg" class = "view-status-icon"/>
            <div class = "bold-content" >Appointment Already Cancelled</div>
            <div class = "regular-content">
                If you wish to reschedule your appointment, please contact your provider.
            </div>
        </div>

        <!-- appointment is canceled, clicked Yes button (canceled right now) -->
        <!-- <div class = "status-after-cancel-operation-view" v-else-if = "is_appointment_deleted && want_to_cancel">
            <img src = "@/assets/ChangeShippingAddress/success_icon.svg" class = "view-status-icon"/>
            <div class = "bold-content">
                Cancellation Success
            </div>
            <div class = "regular-content">
                If you wish to reschedule your appointment, please contact your provider.
            </div>
        </div> -->

        <!-- appointment isn't canceled, clicked No button (don't want to cancel right now) -->
        <!-- <div class = "status-after-cancel-operation-view" v-else-if = "!is_appointment_deleted && want_to_cancel==false">
            <img src = "@/assets/ChangeShippingAddress/failed_icon.svg" class = "view-status-icon"/>
            <div class = "bold-content">
                Cancellation Failed
            </div>
            <div class = "bold-content">
                If you wish to reschedule your appointment, please contact your provider.
            </div>
        </div> -->
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useCancelAppointmentStore } from "@/stores/CancelAppointmentStore.ts";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { useRoute } from 'vue-router';
import { ElNotification } from "element-plus";

import { useQuery } from "@vue/apollo-composable";
import gql from 'graphql-tag'


export default{
    setup(){
        //setup() in vue 3 doesn't have access of 'this'
        const route = useRoute();
        const CommonStore = useCommonStore();
        const CancelAppointmentStore = useCancelAppointmentStore();

        CommonStore.setAccessionId(route.query.accession_id);
        CommonStore.setSampleId(route.query.sample_id);
        CancelAppointmentStore.storeAllQueryInfomation(route.query);
        //graphQL query:
        const GET_EVENT_BY_ID = 
        gql`
            query GetEventById($getEventByIdId: Int!) {
                getEventById(id: $getEventByIdId) {
                    id
                    deleted_time
                }
            }
        `;
        //named result as getEventByIdResult
        const {result:getEventByIdResult, loading, error:getEventByIdError} = useQuery(
            GET_EVENT_BY_ID, 
            {
                getEventByIdId: CancelAppointmentStore.event_id,
            },
            {
                context:{
                    headers:{
                        authorization: CancelAppointmentStore.token ? `jwt ${CancelAppointmentStore.token}` : '',
                    }
                }
            }
        )
        return {
            //result named getEventByIdResult will be automatically refetched after related mutate operation
            //since we configure ApolloClient with cache: new InMemoryCache(),
            getEventByIdResult, 
            loading, 
            getEventByIdError
        }
    },
    created(){
        // this.setAccessionId(this.$route.query.accession_id);
        // this.setSampleId(this.$route.query.sample_id);
        // this.storeAllQueryInfomation(this.$route.query);
        // this.checkWhetherAppointmentIsDeleted();
    },
    data(){
        return {
        }
    },
    computed:{
        ...mapState(useCancelAppointmentStore, [
            'event_id',
            'want_to_cancel',
        ]),
        is_appointment_deleted(){
            // console.log('is_appointment_deleted', this.event_info);
            return this.event_info==null
            ?
            false
            :
            this.event_info.deleted_time!=null
        },
        event_info(){
            return this.getEventByIdResult?this.getEventByIdResult.getEventById:null;
        },
    },
    watch:{
        // event_info(newVal){
        //     console.log('event_info change', newVal);
        // },
        // loading(newVal){
        //     console.log('loading change', newVal);
        // },
        getEventByIdError(newVal){
            console.log('getEventById error watch', newVal);
            if(newVal){
                ElNotification({
                    title: 'Error',
                    type: 'error',
                    customClass: "errorNotification",
                    message: newVal.message,
                    duration: 0,
                });
                this.$router.push('/cancelAppointment/failed');
            }
        }
    },
    methods:{
        ...mapActions(useCancelAppointmentStore, [
            'updateWantToCancel',
            'cancelAppointmentAPI',
            'storeAllQueryInfomation',
        ]),
        ...mapActions(useCommonStore, [
            'setAccessionId',
            'setSampleId',
        ]),
        handleClickNo(){
            // this.updateWantToCancel(false);
            this.$router.push('/cancelAppointment/failed');
        },
        async handleClickCancelAppointment(){
            this.loading = true;
            // this.updateWantToCancel(true);
            try{
                await this.cancelAppointmentAPI();
                this.$router.push('/cancelAppointment/success');
            }catch(err){
                this.$router.push('/cancelAppointment/failed');
            }
            this.loading = false;
        }
    }

}
</script>

<style scoped>
#cancel-appointment-view{
    margin-top: 80px;
    padding:0 316px;
    min-height: 347px;
}
.cancel-operation-view,
.status-after-cancel-operation-view{
    display:flex;
    flex-direction: column;
    align-items:center;
}
.bold-content{
    margin-top: 62px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.next-step-notice{
    padding: 24px 56px;
    border-radius: 4px;

    text-align: center;  
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}
.outlined{
    color: #20A8C3;
    background: #FFF;
    border:2px solid #20A8C3;
}
.filled{
    background: #20A8C3;
    color: #FFF;
    border:2px solid #20A8C3;
}
.clickable{
    cursor: pointer;
}

.ops-buttons{
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:18px;
}

.status-after-cancel-operation-view .regular-content{
    margin-top: 36px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}

@media only screen and (max-width: 1260px) {
    #cancel-appointment-view{
        margin-top: 80px;
        padding:0 100px;
    }
}
@media only screen and (max-width: 767px) {
    #cancel-appointment-view{
        margin-top: 49px;
        padding-left:28px;
        padding-right:27px;
        min-height: 216px;
    }

    .bold-content{
        margin-top: 36px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
    }

    .next-step-notice{
        padding: 12px 13px;
        border-radius: 4px;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }
    .ops-buttons{
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap:6px;
    }
    .view-status-icon{
        height:119px;
        width:119px;
    }
    .status-after-cancel-operation-view .regular-content{
        margin-top: 24px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}
</style>