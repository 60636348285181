<template>
  <div class="stripeCardContainer">
    <div class="billingContent">
      <PaymentInput
        content="Name on card"
        :isRequired="true"
        width="half"
        @onChange="handleNameOnCardChange"
      />
      <div class="paymentInputContainer">
        <div class="inputTitle">
          Card Number
          <span class="required">*</span>
          <div
            id="card-number"
            :class="showInvalidNumber ? 'redBorder' : ''"
          ></div>
        </div>
        <div v-if="showInvalidNumber" class="warningMessage">
          Invalid Card Number
        </div>
      </div>
    </div>
    <div class="billingContent">
      <div class="paymentInputContainer">
        <div class="inputTitle">
          Expiration
          <span class="required">*</span>
          <div
            id="card-expiry"
            :class="showInvalidExpiry ? 'redBorder' : ''"
          ></div>
        </div>
        <div v-if="showInvalidExpiry" class="warningMessage">
          Invalid expiration date
        </div>
      </div>
      <div class="paymentInputContainer">
        <div class="inputTitle">
          CVC
          <span class="required">*</span>
          <div id="card-cvc" :class="showInvalidCvc ? 'redBorder' : ''"></div>
        </div>
        <div v-if="showInvalidCvc" class="warningMessage">Invalid Card CVC</div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentInput from "@/components/payment/PaymentInput.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { mapActions, mapState } from "pinia";
import { getPublishableKey } from "@/utils/stripeKeySelector";
import { ElNotification } from "element-plus";
export default {
  name: "StripeCardInfo",
  props: {
    isHSACard: {
      type: Boolean,
    },
  },
  components: {
    PaymentInput,
  },
  data() {
    return {
      publishableKey: getPublishableKey(),
      cardHolder: "",
      showInvalidNumber: false,
      showInvalidExpiry: false,
      showInvalidCvc: false,
      cardNumberComplete: false,
      cardExpiryComplete: false,
      cardCvcComplete: false,
      returnURL: "https://pns.vibrant-wellness.com/payment_status",
      patientId: localStorage.getItem("patient_id"),
    };
  },
  mounted() {
    this.stripeJS();
  },
  computed: {
    ...mapState(usePaymentStore, [
      "hasEmptyFields",
      "billing_country",
      "billing_state",
      "billing_zipCode",
      "billing_city",
      "billing_address",
      "billing_address2",
      "billing_email",
      "billingInfo",
    ]),
  },
  methods: {
    ...mapActions(useCommonStore,[
      'setShowVWLoadingAnimation'
    ]),
    ...mapActions(usePaymentStore, [
      "setCheckEmptyFields",
      "initHasEmptyFields",
      "generateStripeCardPayIntent",
      "saveBillingInfo",
      "updateConciergeServiceViaBackend",
    ]),
    stripeJS() {
      const stripe = window.Stripe(this.publishableKey);
      const elements = stripe.elements();
      const cardNumberElement = elements.create("cardNumber", {
        style: {
          base: {
            color: "#000",
            fontWeight: "400",
            fontSize: "14px",
            letterSpacing: "0.3px",
            "::placeholder": {
              color: "#fff",
            },
          },
          invalid: {
            color: "#000",
          },
        },
      });
      const cardExpiryElement = elements.create("cardExpiry", {
        style: {
          base: {
            color: "#000",
            fontWeight: "400",
            fontSize: "14px",
            letterSpacing: "0.3px",
            "::placeholder": {
              color: "#bcccdc",
            },
          },
          invalid: {
            color: "#000",
          },
        },
      });
      const cardCvcElement = elements.create("cardCvc", {
        style: {
          base: {
            color: "#000",
            fontWeight: "400",
            fontSize: "14px",
            letterSpacing: "0.3px",
            "::placeholder": {
              color: "#fff",
            },
          },
          invalid: {
            color: "#000",
          },
        },
      });

      cardNumberElement.mount("#card-number");
      cardExpiryElement.mount("#card-expiry");
      cardCvcElement.mount("#card-cvc");

      cardNumberElement.on("change", (event) => {
        this.showInvalidNumber = false;
        if (event.complete) {
          this.cardNumberComplete = true;
        } else {
          this.cardNumberComplete = false;
        }
      });
      cardExpiryElement.on("change", (event) => {
        this.showInvalidExpiry = false;
        if (event.complete) {
          this.cardExpiryComplete = true;
        } else {
          this.cardExpiryComplete = false;
        }
      });
      cardCvcElement.on("change", (event) => {
        this.showInvalidCvc = false;
        if (event.complete) {
          this.cardCvcComplete = true;
        } else {
          this.cardCvcComplete = false;
        }
      });

      document.querySelector("#submitStripePaymentButton").onclick =
        async () => {
          console.log("submit button clicked");
          this.$matomo && this.$matomo.trackEvent("pns", "payment_page", "submitPayByHSA", 1);
          this.$emit("triggerLoading", true);
          // show whole page loading animation
          this.setShowVWLoadingAnimation(true);
          let record_concierge_service_at_backend;
          try{
            //update concierge service record at backend
            record_concierge_service_at_backend = await this.updateConciergeServiceViaBackend();
          }catch(err){
            console.log(err);
            this.$emit("triggerLoading", false);
            // end whole page loading animation, when error
            this.setShowVWLoadingAnimation(false);
            ElNotification({
                title: 'Failed',
                type: 'error',
                customClass: "errorNotification",
                message: 'Add or Remove Concierge Service Failed',
                duration: 0,
            });
            this.$router.push("/payment_failed");
          }
          //after record the concierge service at the backend,
          if(record_concierge_service_at_backend.success){
            console.log('stripeJS record_concierge_service_at_backend');
            //check empty fields
            if (!this.cardNumberComplete) {
              this.showInvalidNumber = true;
            }
            if (!this.cardExpiryComplete) {
              this.showInvalidExpiry = true;
            }
            if (!this.cardCvcComplete) {
              this.showInvalidCvc = true;
            }
            this.initHasEmptyFields();
            console.log("should return1", this.hasEmptyFields);
            this.setCheckEmptyFields(true);
            await new Promise((resolve) => setTimeout(resolve, 100));
            if (
              this.hasEmptyFields.length == 0 ||
              this.hasEmptyFields.includes(true) ||
              this.showInvalidNumber ||
              this.showInvalidExpiry ||
              this.showInvalidCvc
            ) {
              console.log("should return2", this.hasEmptyFields);
              this.$emit("triggerLoading", false);
              // end whole page loading animation
              this.setShowVWLoadingAnimation(false);
              window.scrollTo(0, 580);
              this.setCheckEmptyFields(false);
              return;
            }
            // if balance total is not 0, then processing payment submission
            console.log("stripeJS this.billingInfo", this.billingInfo, Number(this.billingInfo.total));
            if(this.billingInfo && Number(this.billingInfo.total)){
              let stripeCardInfo = {
                billing_details: {
                  // email: this.billing_email,
                  name: this.cardHolder,
                  address: {
                    country: this.billing_country,
                    // line1: this.billing_address,
                    // line2: this.billing_address2,
                    // city: this.billing_city,
                    // state: this.billing_state,
                    // postal_code: this.billing_zipCode,
                  },
                },
                // shipping: {
                //   name: this.cardHolder,
                //   address: {
                //     country: this.billing_country,
                //     line1: this.billing_address,
                //     line2: this.billing_address2,
                //     city: this.billing_city,
                //     state: this.billing_state,
                //     postal_code: this.billing_zipCode,
                //   },
                // },
              };
              try {
                console.log('StripeCardInfo generateStripeCardPayIntent');
                let clientSecret = await this.generateStripeCardPayIntent(this.isHSACard);
                const { paymentIntent, error } = await stripe.confirmCardPayment(
                  clientSecret,
                  {
                    payment_method: {
                      card: cardNumberElement,
                      billing_details: stripeCardInfo.billing_details,
                    },
                  }
                );
                if (paymentIntent) {
                  // if record_concierge_service_at_backend.real_operation: 
                  // NOTE: means truely send the request to update the backend record
                  if(record_concierge_service_at_backend.real_operation){
                    // wait for 5s for all backend updates, end loading animation, and jump to /payment_succeed
                    setTimeout(()=>{
                      this.$emit("triggerLoading", false);
                      // end whole page loading animation
                      this.setShowVWLoadingAnimation(false);
                      this.$router.push("/payment_succeed");
                    },5000);
                  }else{
                  // if record_concierge_service_at_backend.real_operation == false: directly jump to /payment_succeed
                    this.$emit("triggerLoading", false);
                    // end whole page loading animation
                    this.setShowVWLoadingAnimation(false);
                    this.$router.push("/payment_succeed");
                  }
                }
                if (error) {
                  this.$emit("triggerLoading", false);
                  // end whole page loading animation, when error
                  this.setShowVWLoadingAnimation(false);
                  this.$router.push("/payment_failed");
                }
              } catch (error) {
                console.log(error);
                this.$emit("triggerLoading", false);
                // end whole page loading animation, when error
                this.setShowVWLoadingAnimation(false);
                this.$router.push("/payment_failed");
              }
            }else{
            // if balance total is 0 or no billingInfo, fake pay and then go to payment success.
              // if record_concierge_service_at_backend.real_operation: 
              // NOTE: means truely send the request to update the backend record
              if(record_concierge_service_at_backend.real_operation){
                // wait for 5s for all backend updates, end loading animation, and jump to /payment_succeed
                setTimeout(()=>{
                  this.$emit("triggerLoading", false);
                  // end whole page loading animation
                  this.setShowVWLoadingAnimation(false);
                  this.$router.push("/payment_succeed");
                },5000);
              }else{
              // if record_concierge_service_at_backend.real_operation == false: directly jump to /payment_succeed
                this.$emit("triggerLoading", false);
                // end whole page loading animation
                this.setShowVWLoadingAnimation(false);
                this.$router.push("/payment_succeed");
              }
            } 
          }
        }
    },
    handleNameOnCardChange(value) {
      this.cardHolder = value;
    },
  },
};
</script>

<style scoped>
.stripeCardContainer {
  margin-bottom: 22px;
}

.billingContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.paymentInputContainer {
  width: 27vw;
  /* min-width: 284px; */
}
.required {
  color: #ff0000;
}

.warningMessage {
  color: #de583f;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.175px;
  text-align: left;
}

.redBorder {
  border: 1px solid #df2b25 !important;
}

#card-number {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  padding-left: 21px;
  padding-top: 12px;
}

#card-expiry {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  padding-left: 21px;
  padding-top: 12px;
}

#card-cvc {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  padding-left: 21px;
  padding-top: 12px;
}

@media only screen and (max-width: 767px) {
  .billingContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .paymentInputContainer {
    width: 100%;
    height: 68px;
  }

  .inputTitle {
    font-size: 12px;
  }

  .warningMessage {
    font-size: 12px;
  }

  #card-number {
    height: 36px;
    padding-left: 15px;
    padding-top: 10px;
  }

  #card-expiry {
    height: 36px;
    padding-left: 15px;
    padding-top: 10px;
  }

  #card-cvv {
    height: 36px;
    padding-left: 15px;
    padding-top: 10px;
  }
}
</style>
