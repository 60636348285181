<template>
  <div id="page-container">
    <div class="pageBodyContainer">
      <img
        class="paymentStatusIcon"
        src="@/assets/payment/failure.svg"
        alt="payment failed"
      />
      <div class="paymentStatusTitle">Failed</div>
      <div class="paymentStatusInfo">
        Hey, seems like there was some trouble. <br />
        We are there with you. Just hold back.
      </div>
      <el-button
        class="retryButton"
        @click="retryPayment"
      >
        Try Again
      </el-button>
      <div class="paymentDetailsLink">
        If you need to view your payment details
        <a
          href="https://www.vibrant-america.com/patient-nav-portal"
          target="_blank"
          >&nbsp;Log in to PNS Portal</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericFailed",
  data() {
    return {
      patientId: localStorage.getItem("patient_id"),
    };
  },
  mounted() {
      window.scrollTo(0, 0);
  },
  methods: {
    retryPayment() {
      this.$matomo && this.$matomo.trackEvent("pns", "payment_page", "failed_to_retrieve_billing_info", 1);
      this.$router.push("/payment_form");
    },
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  position: relative;
}

.retryButton {
  height: 42px;
  width: 190px;
  padding: 10px, 21px, 10px, 21px;
  border-radius: 4px;
  gap: 10px;
  background-color: #0e4672 !important;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.43px;
}

.retryButton:hover {
  background-color: #5194d2 !important;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .retryButton {
    width: 132px;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
