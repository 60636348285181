<template>
    <el-dialog
        v-model="visible"
        top = '10vh'
        class = 'confirm-address-dialog'
        :close-on-click-modal="false"
        @close = "handleDialogClose()"
    >
        <template #header = '{close}'>
            <div class = 'dialog-header-content'>
                <img src = "@/assets/BloodDraw/close_icon.svg" @click="close" class = "close-icon"/>
            </div>
        </template>
        <div class  = 'confirm-address-dialog-content'>
            <div class = 'confirm-address-dialog-title'>
                From our records it seems like you have been worked with us previously, please confirm are you the same patient as:
            </div>
            <div class = "current-shipping-address-summary">
                <div class = "summary-row">
                    <div class = 'summary-text'>
                        Name:
                    </div>
                    <div class = 'summary-text'>
                        {{ name }}
                    </div>
                </div>
                <div class = 'summary-divider'></div>
                <div class = "summary-row">
                    <div class = 'summary-text'>
                        DOB:
                    </div>
                    <div class = 'summary-text'>
                        {{ birthdate }}
                    </div>
                </div>
                <div class = 'summary-divider'></div>
                <div class = "summary-row">
                    <div class = 'summary-text'>
                       Gender:
                    </div>
                    <div class = 'summary-text'>
                        {{ gender }}
                    </div>
                </div>
                <div class = 'summary-divider' v-if = "last_order_date"></div>
                <div class = "summary-row" v-if ="last_order_date">
                    <div class = 'summary-text'>
                        Last Order Date with Clinic Name
                    </div>
                    <div class = 'summary-text'>
                        {{ clinic_name }}: {{ last_order_date }}
                    </div>
                </div>
            </div>
            <div class = "confirm-ops-btns-div">
                <div class = "confirm-btn" @click="clickConfirmBtn" v-loading = "loading" >Confirm</div>
                <div class = "edit-btn" @click="clickContactUs">Contact Us by 866-364-0963</div>
            </div>
            <div v-if = 'show_retry_notice' class = "retry-notice-div">Merge Patient Failed. Retry Please.</div>
        </div>
    </el-dialog>
</template>

<script>
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { mapState, mapActions } from 'pinia';
import moment from "moment";
export default{
    data(){
        return{
            visible:true,
            show_retry_notice:false,
            loading:false,
        }
    },
    computed:{
        ...mapState(useShippingAddressStore, [
            'potential_duplicated_patient'
        ]),
        name(){
            //concate full name: firstname + lastname
            //firstname and lastname are both cannot-be-null from backend
            if(this.potential_duplicated_patient){
                const output = `${this.potential_duplicated_patient.patient_firstname} ${this.potential_duplicated_patient.patient_lastname}`;
                return output;
            }
            return '';
        },
        birthdate(){
            //value from backend won't be Null
            return this.potential_duplicated_patient ? moment(this.potential_duplicated_patient.patient_birthdate).format('MM/DD/YYYY') : "";
        },
        gender(){
            //value from backend won't be Null
            return this.potential_duplicated_patient ? this.potential_duplicated_patient.patient_gender : "";
        },
        clinic_name(){
            return this.potential_duplicated_patient && this.potential_duplicated_patient.patient_clinic 
            ?
            (
                this.potential_duplicated_patient.patient_clinic.clinic_name 
                ?
                this.potential_duplicated_patient.patient_clinic.clinic_name 
                :
                "N/A"
            )
            :
            "";
        },
        last_order_date(){
            return this.potential_duplicated_patient && this.potential_duplicated_patient.patient_last_order_date 
            ?
            moment(this.potential_duplicated_patient.patient_last_order_date).format('MM/DD/YYYY') 
            :
            "";
        }
    },
    emits:['close-by-click-confirm','close-by-click-contact-us','close'],
    methods:{
        ...mapActions(useShippingAddressStore,[
            'mergePatientIds',
            'updateMergedPatient'
        ]),
        handleDialogClose(){
            this.$emit('close');
        },
        async clickConfirmBtn(){
            console.log('click confirm');
            //reset the retry notice visibility
            this.show_retry_notice = false;
            try {
                //dialog loading before getting response from backend
                this.loading = true;
                //call backend to merge patient
                var merge_succeed = await this.mergePatientIds();
                //update merged_patient in the store to sync whether the user complete the merge operation
                this.updateMergedPatient(merge_succeed);
                //finish loading after getting response
                this.loading = false;
                //if successfully merge patients, close the dialog and aske parent component to save the current info
                if(merge_succeed){
                    this.$emit('close-by-click-confirm');
                }else{
                //if failed, error and popup still appear and suggest users to try again.
                    this.show_retry_notice = true;
                }
            }catch(err){
                console.log('mergePatientIds failed', err);
                //finish loading, if get error
                this.loading = false;
                this.show_retry_notice = true;
            }
        },
        clickContactUs(){
            console.log('click ContactUs');
            //close dialog
            this.$emit('close-by-click-contact-us');
        }
    }
}
</script>

<style scoped>
.dialog-header-content{
    display:flex;
    justify-content:flex-end;
    padding-right:24px;
    padding-top:24px;
    padding-bottom:58px;
}
.close-icon{
    cursor:pointer;
}
.confirm-address-dialog-content{
    padding-left: 96px;
    padding-right:96px;
    padding-bottom:60px;

    display:flex;
    flex-direction:column;
    gap:60px;
}

.confirm-address-dialog-title{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.458px;
}

.summary-row{
    display:flex;
    justify-content:space-between;
    gap:35px;
}
.summary-text{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.4px;
}
.summary-divider{
    margin-top:12px;
    margin-bottom:16px;
    display:block;
    content:'';
    height:1px;
    background: #0E4672;

}
.confirm-ops-btns-div{
    display:flex;
    justify-content:center;
    gap:16px;
}
.edit-btn{
    padding: 12px 0px;
    width:calc(50% - 8px);
    min-width:132px;
    border-radius: 4px;
    border: 1px solid #0E4672;
    text-align:center;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
.confirm-btn{
    padding: 13px 0px;
    width:calc(50% - 8px);
    min-width:132px;
    border-radius: 4px;
    background:#0E4672;
    text-align:center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
.retry-notice-div{
    margin-top:4px;
    color: #C14F49;
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.3px;
    text-align: center;
    /* animation: fadeInOut 3.5s; */
}
/* @keyframes fadeInOut{
    0%{
        opacity:0;
    }
    40%{
        opacity:1;
    }
    60%{
        opacity:1;
    }
    100%{
        opacity:0;
    }
} */
@media only screen and (max-width: 1260px){
    .confirm-address-dialog-content{
        padding-left: 48px;
        padding-right:48px;
        padding-bottom:75px;
        gap:40px;
    }
}

@media only screen and (max-width: 767px){
    .dialog-header-content{
        display:flex;
        justify-content:flex-end;
        padding-right:18px;
        padding-top:18px;
        padding-bottom:18px;
    }
    .confirm-address-dialog-content{
        padding-left: 24px;
        padding-right:24px;
        padding-bottom:30px;
        gap:24px;
    }
    .confirm-address-dialog-title {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0.458px;
    }
    .summary-text {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
    }
    .summary-divider {
        margin-top: 12px;
        margin-bottom: 16px;
    }

    .confirm-ops-btns-div{
        display:flex;
        flex-direction: column;
        align-items: center;
        row-gap: 8px;
    }

    .edit-btn,
    .confirm-btn{
        width:100%;
        text-align: center;
    }
}
</style>