<template>
    <div id = 'blood-draw-view-container' v-loading="page_loading">
        <SwitchTabs :tabs="tabs" v-model="active_tab"/>
  
        <div v-if = "locations_shown!=null && locations_shown.length!=0" class = 'locations-info-wrapper'>
            <div class = "all-location-units" ref = 'all-location-units'>
                <LocationUnit v-for = "(location,index) in locations_shown" :key = "`location_unit-${location.location_name.replaceAll(' ','_')}`"
                v-bind="location" :index = "index"
                @select="(cur_selected_index)=>selectNewLocationUnit(cur_selected_index)"
                :ref = "`location-unit-${index}`"/>
            </div>
            <div class = 'map-div'>
                <EmbededGoogleMap />
            </div>
        </div>
        <div v-else class = 'no-locations-notice-wrapper'>
            <div class = "no-locations-notice">
                Your provider does not have a preferred draw site. 
                <br />
                Please use <span class = "bold">"Go to map search"</span> button below to search for a draw site close to you.
            </div>
        </div>
        <div class = "nav-to-new-ops-bar">
            <div v-if = "is_concierge_valid && is_blood_concierge_beta_launch" class = "nav-to-concierge-div">
                <div class = 'guidance-info-and-ops-wrapper'>
                    <div class = "guidance-title">
                        Include Concierge Blood Draw Service for Only $99 
                    </div>
                    <div class = "guidance-text">
                        Three quick easy steps!     
                        <ul class = "guidance-text step-list">
                            <li>
                                Request your appointment times when receiving your Blood Kit. 
                            </li>
                            <li>
                                We confirm your appointment and Phlebotomy Company. 
                            </li>
                            <li>
                                Your phlebotomist arrives to draw your blood at your requested location and time.
                            </li>
                        </ul>        
                    </div>
                    
                    <div class = "nav-to-concierge-btn" @click = "goToConcierge()">
                        <div>Schedule Now</div>
                        <img src = "@/assets/BloodDraw/nav_to_new_icon.svg" class = "nav-to-new-icon"/>
                    </div>
                </div>
                <ConciergeRedirectDialog ref = "concierge-redirect-dialog" />
                <img src="@/assets/BloodDraw/nav_to_concierge_pic.svg" class = "nav-to-concierge-pic"/>
            </div>
            <div :class = "[
                'go-to-map-search-div',
                {'full-width':!is_concierge_valid || !is_blood_concierge_beta_launch},
            ]">
                <div class = 'guidance-info-and-ops-wrapper'>
                    <div class = "guidance-title">
                        Need more options?
                    </div>
                    <div class = "guidance-text">
                        If these options don't work for you, please click here for all draw site options near your location.
                    </div>
                    <div class = "go-to-map-search-btn" @click = "goToMapSearch()">
                        <div>Go to map search</div>
                        <img src = "@/assets/BloodDraw/nav_to_new_icon.svg" class = "nav-to-new-icon"/>
                    </div>
                </div>
            
                <img src="@/assets/BloodDraw/go_to_map_pic.svg" class = "go-to-map-search-pic"/>
            </div>
        </div>
    </div>
</template>

<script>
import SwitchTabs from "./SwitchTabs.vue";
import LocationUnit from "./LocationUnit.vue";
import EmbededGoogleMap from "./EmbededGoogleMap.vue";
import ConciergeRedirectDialog from "./ConciergeRedirectDialog.vue";
import { mapActions, mapState } from "pinia";
import { useBloodDrawStore } from '@/stores/BloodDrawStore.ts';
import { useCommonStore } from "@/stores/CommonStore.ts";
export default{
    components:{
        SwitchTabs,
        LocationUnit,
        EmbededGoogleMap,
        ConciergeRedirectDialog,
    },
    data(){
        return{
            tabs:['At-Home Blood Draw', 'Blood Draw Site'],
            active_tab:'',
            location_test:{
                location_name: 'Mills Health Center Lab Satellite Draw Station',
                open_hour: 'Mon - Fri 8:00 - 17:00',
                address:' 1936 Camden Ave Suite 4A, San Jose CA 95124',
                service_tags:['Walk In', 'Appointment']
            },
            window_size:'',
            selected_location_unit_index:0,
        }
    },
    async created(){
        this.storeAllQueryInfomation(this.$route.query);
        this.setAccessionId(this.$route.query.accession_id);
        this.setSampleId(this.$route.query.sample_id);
        // save patient as a visitor in matomo (already saved on payment page)
        // this.addPatientIDToMatomo();
        // ------------ remove the redirect feature due to null pointer during refresh ----------------------
        // //if token, sample_id or accession_id in BloodDrawStore are null or empty, means user refresh at the current page:
        // // -> need to get the query from the localStorage, and reset variables in store
        // if(!this.sample_id || !this.accession_id || !this.token){
        //     console.log('getLocalStorageQuery blood-draw-page-query',localStorage.getItem('blood-draw-page-query'));
        //     this.storeAllQueryInfomation(JSON.parse(localStorage.getItem('blood-draw-page-query')));
        // }
        await this.getBloodDrawData();
        this.getCountryOptions();
        //set tabs (handle tabs_order == null, when this customer has no pns setting)
        this.tabs = this.blood_draw_data==null || this.blood_draw_data.tabs_order==null || this.blood_draw_data.tabs_order[0] == 'mobile'?['At-Home Blood Draw', 'Blood Draw Site']:['Blood Draw Site', 'At-Home Blood Draw']
        this.active_tab = this.tabs[0];
        this.setCurActiveTab(this.active_tab);
        //default select the first location unit
        this.selected_location_unit_index = 0;
    },
    computed:{
        // ...mapState(useCommonStore, ["sample_id", "accession_id"]),
        ...mapState(useBloodDrawStore,[
            'accession_id',
            'sample_id',
            'token',
            'blood_draw_data',
            'page_loading',
            'is_concierge_valid',
            'patient_id',
            'is_kit_delivered',
            'is_blood_concierge_beta_launch'
        ]),
        locations_shown(){
            if(this.blood_draw_data!=null){
                if((this.blood_draw_data['in_office']==null || this.blood_draw_data['in_office'].length == 0) && (this.blood_draw_data['draw_site']==null || this.blood_draw_data['draw_site'].length == 0) && (this.blood_draw_data['mobile']==null || this.blood_draw_data['mobile'].length == 0)){
                    // window.location.href = 'https://www.vibrant-america.com/blood-draw-maps/v2';
                    return [];
                }else if(this.active_tab == 'Blood Draw Site'){
                    const idx_of_in_office = this.blood_draw_data.tabs_order.indexOf('in_office');
                    const idx_of_draw_site = this.blood_draw_data.tabs_order.indexOf('draw_site');
                    const modified_in_office_locations=this.blood_draw_data['in_office'].map((item)=>({
                        ...item,
                        tab_type:'in_office',
                    }));
                    const modified_draw_site_locations=this.blood_draw_data['draw_site'].map((item)=>({
                        ...item,
                        tab_type:'draw_site',
                    }));
                    const output = idx_of_in_office < idx_of_draw_site ?[...modified_in_office_locations, ...modified_draw_site_locations]:[...modified_draw_site_locations,...modified_in_office_locations];
                    return output;
                }else if(this.active_tab == 'At-Home Blood Draw'){
                    return this.blood_draw_data['mobile'];
                }
            }
            return [];
        }
    },
    watch:{
        active_tab(newVal){
            console.log('active_tab change', newVal);
            // init selected_location_unit_index to 0 -> default select first location
            this.selected_location_unit_index = 0;
            this.setCurActiveTab(newVal);
            // select first location card

        },
        locations_shown(newVal){
            console.log('location_shown change', newVal);
            if(newVal && newVal.length!=0){
                setTimeout(this.adjustLocationWrappersWhenAllLocationUnitsOverflow, 10);
            }
        }
    },
    methods:{
        ...mapActions(useBloodDrawStore,[
            'getBloodDrawData',
            'storeAllQueryInfomation',
            'getCountryOptions',
            'setCurActiveTab',
        ]),
        ...mapActions(useCommonStore,[
            'setAccessionId',
            'setSampleId',
        ]),
        addPatientIDToMatomo(){
            if(this.patient_id){
                const intervalId = setInterval(()=>{
                    console.log('addPatientIDToMatomo');
                    if(this.$matomo){
                        console.log('addPatientIDToMatomo1', this.$matomo, window._paq);
                        this.$matomo.setUserId(this.patient_id);
                        this.$matomo.trackEvent('pns', 'blood-draw-page', 'pns-login', this.patient_id);
                        // console.log('addPatientIDToMatomo2');
                        clearInterval(intervalId);
                    }
                }, 500)
            }
        },
        selectNewLocationUnit(cur_selected_index){
            //dehighlight the previously selected location unit
            const matched_unit = this.$refs[`location-unit-${this.selected_location_unit_index}`];
            console.log('matched_unit',matched_unit);
            if(matched_unit){
                // console.log('matched_unit');
                // console.dir(matched_unit);
                matched_unit[0].$data.selected = false;
            }
            this.selected_location_unit_index = cur_selected_index;
        },
        goToMapSearch(){
            window.open('https://www.vibrant-america.com/blood-draw-maps/v2', '_blank');
        },
        goToConcierge(){
            // open ConciergeRedirectDialog;
            this.$refs['concierge-redirect-dialog'].$data.visible = true;
            // window.open(`https://phleb.vibrant-wellness.com/app/concierge-request/concierge/${this.accession_id}`, '_blank');
        },
        adjustLocationWrappersWhenAllLocationUnitsOverflow(){
            console.log('checkAllLocationUnitsOverflow',this.$refs['all-location-units'].clientHeight, this.$refs['all-location-units'].scrollHeight);
            const checkedTarget =this.$refs['all-location-units'];
            if(checkedTarget ){
                if(checkedTarget.clientHeight < checkedTarget.scrollHeight){
                    if(window.innerWidth > 500){
                        checkedTarget.style.setProperty('padding-right','16px');
                    }else{
                        checkedTarget.style.setProperty('padding-right','8px');
                    }
                }else{
                    checkedTarget.style.setProperty('padding-right','0px');
                }
            }
        }
    }
}

</script>

<style scoped>
#blood-draw-view-container{
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 30px;

    /*need to explicitly set parent div for children div height to be dynamic*/ 
    height:calc(100vh - 106px);
}

.locations-info-wrapper{
    display: flex;
    align-items:center;
    justify-content: space-between;
    gap:16px;
    /*if window's width < 1260: flex-direction: column*/

    margin-top: 38px;
    height:53%;
}
.all-location-units{
    height: 100%; /* if window's width < 1260: 62%*/
    width:52%; /* if window's width < 1260: 100%*/
    box-sizing: border-box; 
    padding-right: 16px;

    flex-shrink:0;

    overflow: auto;

    display: flex;
    flex-direction: column;
    gap:12px;

    /*if window's width < 1260, map is before all-location-units: order: 1 */
}
.all-location-units::-webkit-scrollbar{
    width:8px;
}
.all-location-units::-webkit-scrollbar-track{
    border-radius: 10px;
    background: #F1F3F4;
}
.all-location-units::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: #D8D8D8;
}
.map-div{
    width:100%; 
    height: 100%; /*if window's width < 1260: 30% */
    border-radius: 10px;
    /* background-color: rgb(231, 168, 168); */
    flex-shrink:1;
}
.no-locations-notice-wrapper{
    width:100%;
    height:498px;

    display:flex;
    align-items:center;
    justify-content:center;
}
.no-locations-notice{
    width:54%;
    text-align:center;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    white-space:pre-wrap;
    word-break: break-word;
}
.no-locations-notice .bold{
    font-weight: 700;
}
.nav-to-new-ops-bar{
    width:100%;
    /* height:197px; */
    margin-top:36px;
    margin-bottom: 106px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.go-to-map-search-div{
    min-height:100%;
    width:calc(50% - 9.5px);
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-top:24px;
    padding-bottom:18px;

    position:relative;

    border-radius: 10px;
    background: #FAECE6;
}
.go-to-map-search-div.full-width{
    width:100%;
}
.nav-to-concierge-div{
    min-height:100%;
    width:calc(50% - 9.5px);
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-top:24px;
    padding-bottom:18px;

    position:relative;

    border-radius: 10px;
    background: #EBF2EF;
}
.go-to-map-search-div .guidance-info-and-ops-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap:20px;

    padding-left:36px;
    padding-right:178px;

    width: 100%;
    position: relative;
}
.nav-to-concierge-div .guidance-info-and-ops-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap:20px;

    padding-left:36px;
    padding-right:55px;

    width: 100%;
    position: relative;
}
.guidance-title{
    color: #000;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 700;
    line-height: 18px; /* 100% */
}
.guidance-text{
    color: #000;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
}
.step-list{
    list-style-type: disc; 
    margin-left:20px;
    margin-top:12px;
}
.step-list>li{
    margin-top:6px;
}
.nav-to-concierge-btn{
    width:fit-content;
    display: flex;
    gap:6px;
    align-items: center;
    padding: 9px 18px;
    border-radius: 4px;
    background: #0E4672;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    cursor:pointer;
}
.go-to-map-search-div.full-width .go-to-map-search-btn{
    position:relative !important;
}
.go-to-map-search-btn{
    margin-top:6px;
    width:fit-content;
    display: flex;
    gap:6px;
    align-items: center;
    padding: 9px 18px;
    border-radius: 4px;
    background: #0E4672;

    position:absolute;
    bottom:0;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    cursor:pointer;
}
.go-to-map-search-pic{
    width:124px;
    height:105px;
    position:absolute;
    right:7px;
    bottom:0px;
}
.nav-to-concierge-pic{
    width:140px;
    height:90px;
    position:absolute;
    right:0px;
    bottom:0px;
}
/* if screen is smaller than 1260px */
@media only screen and (max-width: 1260px) {
    .locations-info-wrapper{
        display: flex;
        align-items:center;
        justify-content: space-between;
        gap:16px;
        flex-direction: column;

        margin-top: 38px;
        height:65%;
    }
    .all-location-units{
        height: 53%; 
        width: 100%;
        box-sizing: border-box; 
        padding-right: 16px;

        flex-shrink:0;

        overflow: auto;

        display: flex;
        flex-direction: column;
        gap:12px;
        order:1;
    }
    .map-div{
        height:42%;
        width: 100%;
        border-radius: 10px;
        background-color: rgb(231, 168, 168);
        flex-shrink:1;
    }
    .nav-to-new-ops-bar{
        height:fit-content;
        flex-direction: column;
        gap:12px;
    }
    .nav-to-concierge-div,
    .go-to-map-search-div{
        width:100%;
        height:fit-content;
        padding:18px 0px;
    }s
    .go-to-map-search-div .guidance-info-and-ops-wrapper,
    .nav-to-concierge-div .guidance-info-and-ops-wrapper{
        row-gap: 12px;
    }
    .nav-to-concierge-btn,
    .go-to-map-search-btn{
        position: relative;
    }
    
}

@media only screen and (max-width: 767px){
    #blood-draw-view-container{
        padding-left: 28px;
        padding-right: 27px;
        margin-top: 40px;

        height:fit-content;
        min-height: calc(100vh - 88px);
    }
    .locations-info-wrapper{
        display: flex;
        align-items:center;
        justify-content: space-between;
        gap:12px;
        flex-direction: column;

        margin-top: 18px;
        /* height:65%; */
    }
    .map-div{
        height:221px;
        width: 100%;
        border-radius: 10px;
        background-color: rgb(231, 168, 168);
        flex-shrink:1;
    }
    .all-location-units{
        height: 300px; 
        width: 100%;
        box-sizing: border-box;

        flex-shrink:0;

        display: flex;
        flex-direction: column;
        gap:12px;
        order:1;
    }
    .no-locations-notice-wrapper{
        height: 400px;
        width:100%;
        padding:80px 0px;

        display:flex;
        align-items:center;
        justify-content:center;
    }
    .no-locations-notice{
        width:100%;
        text-align:center;

        color: #0E4672;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        white-space:pre-wrap;
        word-break: break-word;
    }
    .no-locations-notice .bold{
        font-weight: 700;
    }
    .go-to-map-search-div .guidance-info-and-ops-wrapper,
    .nav-to-concierge-div .guidance-info-and-ops-wrapper{
        row-gap: 6px;
        padding:0 18px;
    }
    .guidance-title{
        color: #000;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 700;
        line-height: 18px; /* 128.571% */ 
    }
    .guidance-text{
        color: #000;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }
    .step-list{
        list-style-type: disc; 
        margin-left:15px;
        margin-top:6px;
    }
    .step-list>li{
        margin-top:3px;
    }
    .nav-to-concierge-btn,
    .go-to-map-search-btn{
        padding: 8px 12px;
        color: #FFF;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
    .nav-to-new-icon{
        width: 20px;
        height: 20px;
    }
    .go-to-map-search-pic{
        width:77px;
        height:65px;
        position:absolute;
        right:9px;
        bottom:6px;
    }
    .nav-to-concierge-pic{
        width:89px;
        height:59px;
        position:absolute;
        right:15px;
        bottom:0px;
    }
}
</style>