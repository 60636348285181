import { defineStore } from "pinia";
import { shipping_address_inputs_structure_data, getCountryCode, basic_information_inputs_structure_data } from '@/components/shippingAddress/shippingAddressData/shippingAddressInputsStructure.js';
import { shipping_address_mock_data } from "@/components/shippingAddress/shippingAddressData/shippingAddressMockData";
import { beta_launch_customer_ids } from "@/utils/betaLaunchData.js";
import  { baseURL, inventoryBaseURL, coreServiceBaseURL, sampleBaseURL, isProd}  from "@/components/bloodDraw/BloodDrawData/request";
import { orderBaseURL } from "@/utils/orderRequest";
import { ElNotification } from "element-plus";
// import { addBusinessDays } from "@/components/shippingAddress/shippingAddressData/dateFunctions";
// import moment from "moment";
// import router from "@/routes";


export const useShippingAddressStore = defineStore('ShippingAddressStore',{
    state:()=>{
        return{
            //the basic information inputs structure data
            basic_information_inputs_structure_data,
            //the shipping address inputs structure data
            shipping_address_inputs_structure_data,
            previous_shipping_address_data:{},
            current_shipping_address_data:{},
            patient_profile_address:{},
            patient_details:{},
            required_input_fields:[],
            is_editable:true,
            accession_id:null,
            sample_id:null,
            patient_id:null,
            token:null,
            edits_saved:false,
            loading:true,
            is_confirmed_or_saved_before: false,
            is_address_change_significant_before_confirmed:null,
            // for next step --------
            address_needed:true,
            // for next step end ------
            incomplete_address_in_db:null,
            incomplete_basic_information_in_db:null,
            is_apply_to_future_order:false,
            is_current_shipping_address_same_with_patient_profile_address:false,
            //for confirm information loading 
            confirm_info_loading:false,
            //for contact support page due to potential duplicated patient
            show_contact_support_to_solve_duplicated_patient:false,
            //for duplicated patient dialog
            potential_duplicated_patient:null,
            //whether user has already done the merge patient operation
            merged_patient:false,

            // for next step
            valid_concierge_states:['CA', 'TX', 'FL'],
            is_concierge_valid:null,
            need_blood_draw:null,
            customer_id:null,
            is_kit_delivered:null,
            direct_to_blooddraw:null,
            sample_types_mapping_backend_to_url_params:{
                "Stool" : 'Stool',
                "Saliva(4 tubes)": 'Saliva (4 tubes)',
                "Saliva(1 tube)": 'Saliva (1 tube)',
                "Microtube(Blood)": 'Microtube (Blood)',
                "Urine(4 tubes)":'Urine (4 tubes)',
                "Urine(1 tube)": 'Urine',
                "DBS Card": 'DBS Card',
            },
            sample_types_params:'',
            is_concierge_paid:false,
            schedule_blood_draw_dialog_visibility:false,

            // temporary use for blood concierge beta 1 launch - blood_concierge
            blood_concierge_beta_1: false,
            // temporary use for blood concierge beta 1 launch - blood_concierge ------ end

        }
    },
    
    actions:{
        async storeAllQueryInfomation(query){
            this.accession_id = query.accession_id;
            this.sample_id = query.sample_id;
            this.token = query.token;
            this.need_blood_draw = query.bloodDrawNeeded?query.bloodDrawNeeded=='true':null;
            if(this.need_blood_draw==false){
                this.getSampleType();
            }
            this.direct_to_blooddraw = query.direct_to_blooddraw?query.direct_to_blooddraw=='true':null;
            // console.log('direct_to_blooddraw', this.direct_to_blooddraw);
            this.customer_id = query.customer_id;

            this.patient_id = localStorage.getItem('patient_id');
            this.address_needed=localStorage.getItem('address_needed')
            ?
            localStorage.getItem('address_needed')=='true'
            :
            (
                query.address_needed?query.address_needed=='true':null
            );
            
            // get info from localStorage
            if(localStorage.getItem('beta_program_enabled')!='undefined' && localStorage.getItem('beta_program_enabled')!=null && localStorage.getItem('beta_programs')!=null && localStorage.getItem('beta_programs')!='undefined'){

                const beta_program_enabled = localStorage.getItem('beta_program_enabled') == 'true';
                const beta_programs = localStorage.getItem('beta_programs');

                // temporary use for blood concierge beta 1 launch - blood_concierge
                if(beta_program_enabled && beta_programs.includes('blood_concierge')){
                    this. blood_concierge_beta_1 = true;
                }else{
                    this. blood_concierge_beta_1 = false; 
                }
                // temporary use for blood concierge beta 1 launch - blood_concierge ------ end
            }else{
                // temporary use for blood concierge beta 1 launch - blood_concierge
                this. blood_concierge_beta_1 = false; 
                // temporary use for blood concierge beta 1 launch - blood_concierge ------ end
            }
           
        },
        async getDuplicatedPatient(){
            console.log('getDuplicatedPatient in backend...');
            try{
                //first_name and last_name won't be null or undefined
                const [first_name, last_name] = this.current_shipping_address_data.name.split(' ', 2);
                if(first_name && last_name){
                    const params ={
                        sample_id: this.sample_id,
                        first_name: first_name,
                        last_name: last_name,
                        birthdate: this.current_shipping_address_data.birthdate,
                        gender: this.current_shipping_address_data.gender,
                    };
                    this.confirm_info_loading = true;
                    const res = await sampleBaseURL.post('/patients/duplicate-check',
                        params,
                        {
                            headers:{ 
                                Authorization: `Bearer ${this.token}`
                            },
                        }
                    );
                    if(res.data.has_duplicate){
                        this.potential_duplicated_patient = res.data.duplicate_patient;
                    }else{
                        this.potential_duplicated_patient = null;
                    }
                    this.confirm_info_loading = false;
                }
                return Promise.resolve(this.potential_duplicated_patient);
            }catch(err){
                console.log("getDuplicatedPatient error:", err);
                this.potential_duplicated_patient = null;
                this.confirm_info_loading = false;
                return Promise.reject(err);
            }
        },
        getSampleType(){
            orderBaseURL.get('/nonBloodSampleTubeType',{
                headers:{ 
                    Authorization: `Bearer ${localStorage.getItem('pnsToken')?localStorage.getItem('pnsToken'):this.token}`
                },
                params:{
                    sampleId:Number(this.sample_id),
                },
            }).then((res)=>{
                this.sample_types_params = this.convertSampleTypesToNextStepParams(res.data);
            }).catch((err)=>{
                this.sample_types_params = ''
            })
        },
        convertSampleTypesToNextStepParams(backend_sample_types){
            //white spaces in each sample type was replaced by '+'
            //connect each sample type by '-'
            var output = '';
            for(const sample_type of backend_sample_types){
                const converted_sample_type = this.sample_types_mapping_backend_to_url_params[sample_type]
                ?
                this.sample_types_mapping_backend_to_url_params[sample_type].replaceAll(' ','+')
                :
                '';
                if(output){
                    output += `-${converted_sample_type}`
                }else{
                    output = converted_sample_type;
                }
            }
            return output;
        },
        async getPatientProfileAddress(){
            if(localStorage.getItem('patient_details') && localStorage.getItem('patient_details')!='undefined'){
                this.patient_details = JSON.parse(localStorage.getItem('patient_details'));
                if(this.patient_details.patient_address && this.patient_details.patient_address.length>0){
                    this.patient_profile_address = this.patient_details.patient_address[0];
                }
            }else{
                try{
                    const profile_address = await this.fetchPatientAddressInfo();
                    this.patient_profile_address = profile_address;
                }catch(err){
                    console.log('getPatientProfileAddress err:', err);
                }
            }
        },
        async fetchPatientAddressInfo(){
            try{
                const res = await orderBaseURL.get('/orderTest/patient', {
                    headers:{ 
                        Authorization: `Bearer ${localStorage.getItem('pnsToken')?localStorage.getItem('pnsToken'):this.token}`
                    },
                    params:{
                        patientId:this.patient_id
                    },
                });
                this.patient_details = res.data;
                localStorage.setItem('patient_details', JSON.stringify(res.data));

                return res.data.patient_address && res.data.patient_address.length>0
                ? Promise.resolve(res.data.patient_address[0]):Promise.resolve({});
            }catch(err){
                localStorage.removeItem('patient_details');
                console.log("fetchPatientAddressInfo error:", err);
                return Promise.reject(err);
            }
        },
        goToNextStep(router){
            //if need_blood_draw, is_concierge_paid, blood_concierge beta_programs (concierge flow -> has schedule link):
            //NOTE: address_needed !=null (since blood concierge redirect logic is based on express V2)
            if(this.blood_concierge_beta_1 && this.need_blood_draw && this.is_concierge_paid && this.address_needed!=null){
                // if is_kit_delivered(kit to patient) or address_needed==false(kit by office):
                const zhang_jun_base_url = isProd ? 'https://phleb.vibrant-wellness.com/app/concierge-request/concierge':'https://phleb.vibrant-wellness.com/app/staging/concierge-request/concierge';
                if(this.is_kit_delivered || this.address_needed==false){
                    // -> jump to Zhang Jun Page for scheduling: start day is today
                    const url = `${zhang_jun_base_url}/${this.accession_id}`;
                    window.location.href = url;
                }else{
                    //show action required dialog
                    this.schedule_blood_draw_dialog_visibility = true;
                    // -> jump to Zhang Jun Page for scheduling: start day is 3 days later (in millinseconds)
                    // const three_business_days_later_milliseconds = addBusinessDays(moment(), 3).valueOf();
                    // const url = `${zhang_jun_base_url}/${this.accession_id}?startDate=${three_business_days_later_milliseconds}`;
                    // window.location.href = url;
                }
            }else{
            //else: (normal flow)
                //if need_blood_draw: go to blodDraw Page
                const url_params = {
                    customer_id:this.customer_id,
                    sample_id:this.sample_id,
                    accession_id:this.accession_id,
                    token:this.token,
                    is_concierge_valid:this.is_concierge_valid?true:false,
                    // is_kit_delivered: this.is_kit_delivered,
                };
                // console.log(' url_params',  url_params);
                if(this.is_kit_delivered!=null){
                    url_params['is_kit_delivered'] = this.is_kit_delivered;
                }
                if(this.need_blood_draw){
                    router.push({
                        name: 'bloodDrawView',
                        query: url_params
                    });
                }else{
                    //else: VW page
                    const url = `https://www.vibrant-wellness.com/collections?firstName=${this.patient_details && this.patient_details.patient_first_name?this.patient_details.patient_first_name[0]:"Dear"}&lastName=${this.patient_details && this.patient_details.patient_last_name?this.patient_details.patient_last_name[0]: "Customer" }&instructions=${this.sample_types_params}`;
                    window.location.href = url;
                }
            }
        },
        getShippingAddressInfo(){
            console.log('call /ifComfirmAddressV2 api ...');
            baseURL.get('/utility/ifConfirmAddressV2', {
                headers:{ 
                    Authorization: `Bearer ${this.token}`
                },
                params:{
                    sample_id: this.sample_id,
                    accession_id: this.accession_id,
                },
            }).then((res)=>{
                // first assign shipping address to help check concierge validation
                // and assign is_concierge_paid
                this.previous_shipping_address_data = res.data.result;
                this.current_shipping_address_data = JSON.parse(JSON.stringify(res.data.result));
                this.is_concierge_paid = res.data.result.is_concierge_paid;
                // 1. check is incomplete address and incomplete basic information in DB
                this.incomplete_address_in_db = !this.previous_shipping_address_data.street || !this.previous_shipping_address_data.city || !this.previous_shipping_address_data.state || !this.previous_shipping_address_data.zipcode || !this.previous_shipping_address_data.country;
                this.incomplete_basic_information_in_db = !this.previous_shipping_address_data.country || this.previous_shipping_address_data.country == "US"
                ?
                !this.previous_shipping_address_data.gender || !this.previous_shipping_address_data.birthdate
                :
                !this.previous_shipping_address_data.gender || !this.previous_shipping_address_data.birthdate || !this.previous_shipping_address_data.phone
                ;
                // 2. check country field of res.data.result to assign country input's dropdown options
                for(const input_unit_info of this.shipping_address_inputs_structure_data){
                    if(input_unit_info.input_id == "country"){
                        //  2.1 if country is empty:
                        //     -> only show 'US' as the option to limit the delivery range to avoid not paying delivery fee for international delivery
                        //     -> set the autofilled input's customized_props's search_range to be "US" (customized_props has all props except for value that the customized unit component has)
                        if(!this.previous_shipping_address_data.country){
                            input_unit_info.dropdown_options = [{
                                label: 'United States(US)',
                                value: 'US'
                            }];
                            //find autofilled input info in shipping_address_inputs_structure_data and set its search_range
                            for(const input_unit_info of this.shipping_address_inputs_structure_data){
                                if(input_unit_info.input_id == 'street'){
                                    input_unit_info.customized_props={
                                        search_range:'US',
                                    };
                                    break;
                                }
                            }  
                            //find phone input info in basic_information_inputs_structure_data and set its required field, remove display note
                            for( const input_unit_info of this.basic_information_inputs_structure_data){
                                if(input_unit_info.input_id == 'phone'){
                                    input_unit_info.required = false;
                                    delete input_unit_info.input_display_note;
                                    break;
                                }
                            }
                        }else{
                        //  2.2 if country has value:
                        //     -> do nothing -> all options (which is assigned as the default version) for dropdown 
                        //     -> add disabled:true for input field
                        //     -> set the autofilled input's customized_props's search_range to be the corresponding country code (all lower cases, cleaning and match non-country code data via countries.js)
                            input_unit_info.disabled = true;
                            const current_country_code = getCountryCode(this.previous_shipping_address_data.country);

                            //find autofilled input info in shipping_address_inputs_structure_data and set its search_range
                            for(const input_unit_info of this.shipping_address_inputs_structure_data){
                                if(input_unit_info.input_id == 'street'){
                                    input_unit_info.customized_props = {
                                        search_range:current_country_code
                                    };
                                    break;
                                }
                            }
                            //find phone input info in basic_information_inputs_structure_data:
                            for(const input_unit_info of this.basic_information_inputs_structure_data){
                                //find phone input info:
                                if(input_unit_info.input_id == 'phone'){
                                    //if country is US: set phone input info's required field to be false and no input_display_note
                                    if(current_country_code == 'US'){
                                        input_unit_info.required = false;
                                        delete input_unit_info.input_display_note;
                                    }else{
                                    //if country isn't US: set phone input info's required field to be true and add input_display_note
                                        input_unit_info.required = true;
                                        input_unit_info.input_display_note=`(To use a <span style = "font-weight:700;">non US</span> address, phone number will be required for FedEx requirement)`;
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }
                    
                //3. check editable
                this.is_editable = res.data.result.is_editable;
                //4. check initial concierge validation according to inital address
                this.is_concierge_valid = res.data.result.is_sample_address_valid_concierge;
                //5. check kit status for different status view page
                this.is_kit_delivered = res.data.result.reason == 'DELIVERED';
                //6. end the loading
                this.loading = false;
            }).catch((err)=>{
                console.log("/ifConfirmAddress api error:", err);
                this.is_editable = false;
                this.loading = false;
            })
            // this.previous_shipping_address_data = shipping_address_mock_data;
            // this.current_shipping_address_data = JSON.parse(JSON.stringify(shipping_address_mock_data));

            // check whether current sample's shippingn address is confirmed or saved before
            inventoryBaseURL.post('/orders/samples/kits/shipping-address/check',
            {
                sample_id_list: [this.sample_id],
            },
            {
                headers:{ 
                    Authorization: `Bearer ${this.token}`
                },
            }).then((res)=>{
                console.log('res', res.data.data.address_confirmed_samples, this.sample_id);
                if(res.data.data.address_confirmed_samples.includes(this.sample_id)){
                    this.is_confirmed_or_saved_before = true;
                }else{
                    this.is_confirmed_or_saved_before = false;
                }
            }).catch((err)=>{
                this.is_confirmed_or_saved_before = false;
                ElNotification({
                    title: 'Failed',
                    type: 'error',
                    customClass: "errorNotification",
                    message: 'Check Whether Confirmed Before Failed',
                    duration: 0,
                });
            });
        },
        async mergePatientIds(){
            console.log('call merge patient api...', this.patient_id, this.potential_duplicated_patient.patient_id); 
            try{
                const res = await coreServiceBaseURL.get('/patient/merge-patients', {
                    headers:{ 
                        Authorization: `Bearer ${localStorage.getItem('pnsToken')?localStorage.getItem('pnsToken'):this.token}`
                    },
                    params:{
                        primary_patient_id: this.potential_duplicated_patient.patient_id,
                        needToMerge_patient_id: this.patient_id,
                    }
                });
                console.log('merge patient successfully', res);
                //if merge success: show success notice and return true
                ElNotification({
                    title: 'Success',
                    customClass: 'successNotification',
                    message: `Successfully merge patient ${this.patient_id} into patient ${this.potential_duplicated_patient.patient_id}`,
                    type: 'success',
                    duration: 3500,
                });
                return Promise.resolve(true);
            }catch(err){
                console.log('/patient/merge-patients error: ', err);
                return Promise.reject(err);
            }
        },
        extractChangedInputs(){
            //compare current_shipping_address_data and previous_shipping_address_data,
            // only extract changed fields 
            var output = {};
            for(const field in this.current_shipping_address_data){
                if(this.current_shipping_address_data[field]!=this.previous_shipping_address_data[field]){
                    output[field] = this.current_shipping_address_data[field];
                }
            }
            return output;
        },
        async confirmShippingAddress(){
            console.log('call /confirmAddress api...');
            this.confirm_info_loading = true;
            //need to check whether changes are significant before actually change the shipping address
            const is_address_change_significant = await this.checkWhetherAddressChangeIsSignificant();
            this.is_address_change_significant_before_confirmed = is_address_change_significant;
            //extract changed field as we only send change values to the backend.
            var changeInputs = this.extractChangedInputs();
            console.log('confirmShippingAddress changedInputs', changeInputs);
            //if the user merged patient before, not send DOB, gender as its already the same in the backend
            //so delete DOB and gender
            if(this.merged_patient){
                delete changeInputs['birthdate'];
                delete changeInputs['gender'];
            }
            var params = {
                sample_id: this.sample_id,
                //changed inputs for shipping address and basic information
                ...changeInputs,
                //if is_current_shipping_address_same_with_patient_profile_address == true:
                //we won't use is_apply_to_future_order's value. Default treat it to be false;
                // is_address_applied_to_future: this.address_needed==false || this.is_current_shipping_address_same_with_patient_profile_address ? false : this.is_apply_to_future_order,
            };
            if(this.address_needed!=false){
                params['is_address_applied_to_future'] = this.is_current_shipping_address_same_with_patient_profile_address ? false : this.is_apply_to_future_order;
            }

            baseURL.post('/utility/confirmAddress',
                params,
                {
                    headers:{ 
                        Authorization: `Bearer ${this.token}`
                    },
                }
            ).then((res)=>{
                console.log('save shipping address', res.data.status_code);
                if(res.data.status_code == 200){
                    this.edits_saved = true;
                    this.is_concierge_valid = res.data.result.data.is_sample_address_valid_concierge;
                }else{
                    ElNotification({
                        title: 'Failed',
                        type: 'error',
                        customClass: "errorNotification",
                        message: 'Shipping Address Save Failed',
                        duration: 0,
                    });
                }
                this.confirm_info_loading = false;
            }).catch((err)=>{
                console.log('/confirmAddress api error:',err);
                this.confirm_info_loading = false;
            })
        },
        async checkWhetherAddressChangeIsSignificant(){
            // console.log('checkWhetherAddressChangeIsSignificant');
            try{
                const response = await inventoryBaseURL.post('/orders/samples/shipping-address/changes/check',
                {
                    sample_id: this.sample_id,
                    street:this.current_shipping_address_data.street,
                    city:this.current_shipping_address_data.city,
                    state:this.current_shipping_address_data.state,
                    zipcode:this.current_shipping_address_data.zipcode,
                    country:this.current_shipping_address_data.country,
                    name:this.current_shipping_address_data.name,   
                },
                {
                    headers:{ 
                        Authorization: `Bearer ${this.token}`
                    },
                });
                return response.data.data.address_verified_by_patient_samples;
            }catch(err){
                console.log('checkWhetherAddressChangeIsFake error', err);
                return true;
            }
        },
        updateEditsSaved(val){
            this.edits_saved = val;
        },
        updateCurrentShippingAddress(key, val){
            //if update key is country: only allow update when originally country value is empty
            if(key == 'country'){
                if(!this.previous_shipping_address_data['country']){
                    this.current_shipping_address_data[key] = val;
                }
            }else{
                this.current_shipping_address_data[key] = val;
            }
        },
        addRequiredInputFields(val){
            if(!this.required_input_fields.includes(val)){
                this.required_input_fields.push(val);
            }
        },
        removeRequiredInputFields(val){
            if(this.required_input_fields.includes(val)){
                this.required_input_fields = this.required_input_fields.filter(item =>item!=val);
            }
        },
        updateIsConciergeValid(val){
            this.is_concierge_valid = val;
        },
        updateIsCurrentShippingAddressSameWithPatientProfileAddress(val){
            this.is_current_shipping_address_same_with_patient_profile_address = val;
        },
        updateShowContactSupportToSolveDuplicatedPatient(val){
            this.show_contact_support_to_solve_duplicated_patient = val;
        },
        updateMergedPatient(val){
            this.merged_patient = val;
        },
        updateScheduleBloodDrawDialogVisibility(val){
            this.schedule_blood_draw_dialog_visibility = val;
        }
    }
})