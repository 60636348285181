export const beta_launch_customer_ids = [
    // test id
    '999997',
    // PM id
    '29520',
    // PM-Beta id
    '31157',
    // Marketing
    '29818',
    // Lahana, Doug’s wife
    '12005'
]